/*=========================
	All css
==========================*/
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/fontawesome/css/font-awesome.min.css");
@import url("../plugins/line-awesome/css/line-awesome.min.css");
@import url("../plugins/flaticon/flaticon.css");
@import url("../plugins/themify/themify-icons.css");
@import url("../plugins/owl-carousel/owl.carousel.css");
@import url("../plugins/bootstrap-select/bootstrap-select.min.css");
@import url("../plugins/magnific-popup/magnific-popup.css");
@import url("../plugins/animate/animate.css");
@import url("../plugins/scroll/scrollbar.css");
@import url("../plugins/rangeslider/rangeslider.css");
@import url("../plugins/lightgallery/css/lightgallery.min.css");
@import url("../plugins/button-effects/button.css");
/* @import url("../plugins/switcher/switcher.css"); */

/*=========================
	All css
==========================*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Playfair+Display:400,400i,700,700i,900,900i|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
/* 

    font-family: 'Poppins', sans-serif;

    font-family: 'Roboto', sans-serif;

    font-family: 'Roboto Condensed', sans-serif;

    font-family: 'Open Sans', sans-serif;

    font-family: 'Raleway', sans-serif;

    font-family: 'Montserrat', sans-serif;

    font-family: 'Playfair Display', serif;

*/