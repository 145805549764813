	/*
  	Flaticon icon font: Flaticon
  	Creation date: 02/06/2020 06:27
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
	  font-weight: normal;
	  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;
}

.flaticon-two-test-tubes:before { content: "\f100"; }
.flaticon-three-books:before { content: "\f101"; }
.flaticon-ruler-and-set-square:before { content: "\f102"; }
.flaticon-scientific-calculator-machine:before { content: "\f103"; }
.flaticon-school-bus-front:before { content: "\f104"; }
.flaticon-teacher-teaching-maths:before { content: "\f105"; }
.flaticon-graduated-student-avatar:before { content: "\f106"; }
.flaticon-student-reading:before { content: "\f107"; }
.flaticon-closed-notebook-and-pen:before { content: "\f108"; }
.flaticon-children-backpack:before { content: "\f109"; }
.flaticon-science:before { content: "\f10a"; }
.flaticon-atom:before { content: "\f10b"; }
.flaticon-bacteria:before { content: "\f10c"; }
.flaticon-biology:before { content: "\f10d"; }
.flaticon-graph:before { content: "\f10e"; }
.flaticon-calculator:before { content: "\f10f"; }
.flaticon-planet:before { content: "\f110"; }
.flaticon-microscope:before { content: "\f111"; }
.flaticon-book:before { content: "\f112"; }
.flaticon-molecular:before { content: "\f113"; }
.flaticon-compass:before { content: "\f114"; }
.flaticon-engineering:before { content: "\f115"; }
.flaticon-scientist:before { content: "\f116"; }
.flaticon-space:before { content: "\f117"; }
.flaticon-earth:before { content: "\f118"; }
.flaticon-clock:before { content: "\f119"; }
.flaticon-contract:before { content: "\f11a"; }
.flaticon-document:before { content: "\f11b"; }
.flaticon-cut:before { content: "\f11c"; }
.flaticon-test:before { content: "\f11d"; }
.flaticon-girl:before { content: "\f11e"; }
.flaticon-watercolor:before { content: "\f11f"; }
.flaticon-violin:before { content: "\f120"; }
.flaticon-ebook:before { content: "\f121"; }
.flaticon-classroom:before { content: "\f122"; }
.flaticon-school:before { content: "\f123"; }
.flaticon-homework:before { content: "\f124"; }
.flaticon-water:before { content: "\f125"; }
.flaticon-championship:before { content: "\f126"; }
.flaticon-laboratory:before { content: "\f127"; }
.flaticon-ruler:before { content: "\f128"; }
.flaticon-pencil:before { content: "\f129"; }
.flaticon-backpack:before { content: "\f12a"; }
.flaticon-hour:before { content: "\f12b"; }
.flaticon-soccer:before { content: "\f12c"; }
.flaticon-mortarboard:before { content: "\f12d"; }
.flaticon-ebook-1:before { content: "\f12e"; }
.flaticon-rule:before { content: "\f12f"; }
.flaticon-support:before { content: "\f130"; }
.flaticon-marker:before { content: "\f131"; }
.flaticon-crayon:before { content: "\f132"; }
.flaticon-calculator-1:before { content: "\f133"; }
.flaticon-chess:before { content: "\f134"; }
.flaticon-owl:before { content: "\f135"; }
.flaticon-chalk:before { content: "\f136"; }
.flaticon-saw:before { content: "\f137"; }
.flaticon-basketball:before { content: "\f138"; }
.flaticon-notebook:before { content: "\f139"; }
.flaticon-mortarboard-1:before { content: "\f13a"; }