body,
html {
    -moz-osx-font-smoothing: grayscale
}

body {
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6f6f6f;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased
}

a {
    color: #efbb20;
    outline: 0 none;
    text-decoration: none
}

a:focus,
a:hover {
    text-decoration: none;
    outline: 0 none
}

a:active,
a:hover {
    color: #333
}

p a {
    color: #333
}

img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle
}

hr {
    clear: both
}

button,
select {
    outline: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

address {
    font-style: italic
}

table {
    background-color: transparent;
    width: 100%
}

table thead th {
    color: #333
}

table td {
    padding: 15px 10px
}

address,
dd,
dl,
hr,
ol,
p,
pre,
table,
ul {
    margin-bottom: 24px
}

::selection {
    color: #000;
    background: #dbdbdb
}

::-moz-selection {
    color: #000;
    background: #dbdbdb
}

.overflow-hide {
    overflow: hidden
}

.overflow-auto {
    overflow: auto
}

.clear {
    clear: both
}

.pull-none {
    float: none
}

::-moz-selection {
    background: #3396d1;
    color: #fff
}

::selection {
    background: #3396d1;
    color: #fff
}

.op {
    font-family: 'Open Sans', sans-serif
}

.dis-block {
    display: block;
    width: 100%
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #232323;
    font-family: Merriweather, serif
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #232323
}

h1 {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 25px
}

h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 25px
}

h3 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 20px
}

h4 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px
}

h5 {
    font-size: 18px !important;
    line-height: 24px !important;
    margin-bottom: 10p !important
}

h6 {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 10p !important
}

p {
    line-height: 1.5;
    margin-bottom: 24px
}

strong {
    font-weight: 400
}

.bg-primary,
.bg-primary a:hover {
    color: #fff
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-justify {
    text-align: justify
}

.v-align-t {
    vertical-align: top
}

.v-align-m {
    vertical-align: middle
}

.v-align-b {
    vertical-align: bottom
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.font-10 {
    font-size: 10px
}

.font-12 {
    font-size: 12px
}

.font-13 {
    font-size: 13px
}

.font-14 {
    font-size: 14px
}

.font-16 {
    font-size: 16px
}

.font-18 {
    font-size: 18px
}

.font-20 {
    font-size: 20px
}

.font-22 {
    font-size: 22px
}

.font-24 {
    font-size: 24px
}

.font-26 {
    font-size: 26px
}

.font-30 {
    font-size: 30px;
    line-height: 40px
}

.font-35 {
    font-size: 35px;
    line-height: 45px
}

.font-40 {
    font-size: 40px;
    line-height: 50px
}

.font-45 {
    font-size: 45px;
    line-height: 55px
}

.font-50 {
    font-size: 50px;
    line-height: 65px
}

.font-55 {
    font-size: 55px;
    line-height: 65px
}

.font-60 {
    font-size: 60px;
    line-height: 70px
}

.font-70 {
    font-size: 70px;
    line-height: 80px
}

.font-75 {
    font-size: 75px;
    line-height: 85px
}

.font-80 {
    font-size: 80px;
    line-height: 90px
}

.font-90 {
    font-size: 90px;
    line-height: 100px
}

.font-100 {
    font-size: 100px;
    line-height: 110px
}

.font-weight-300 {
    font-weight: 300
}

.font-weight-400 {
    font-weight: 400
}

.font-weight-500 {
    font-weight: 500
}

.font-weight-600 {
    font-weight: 600
}

.font-weight-700 {
    font-weight: 700
}

.font-weight-800 {
    font-weight: 800
}

.font-weight-900 {
    font-weight: 900
}

dl,
ol,
ul {
    list-style-position: outside;
    padding: 0
}

ol,
ul {
    margin-bottom: 24px
}

ol li,
ul li {
    padding: 0
}

dl {
    margin-left: 0;
    margin-bottom: 30px
}

dl dd {
    margin-bottom: 10px
}

.list-inline>li {
    padding-left: 2px;
    padding-right: 2px;
    display: inline-block
}

.p-a0 {
    padding: 0
}

.p-a5 {
    padding: 5px
}

.p-a10 {
    padding: 10px
}

.p-a15 {
    padding: 15px
}

.p-a20 {
    padding: 20px
}

.p-a25 {
    padding: 25px
}

.p-a30 {
    padding: 30px
}

.p-a40 {
    padding: 40px
}

.p-a50 {
    padding: 50px
}

.p-a60 {
    padding: 60px
}

.p-a70 {
    padding: 70px
}

.p-a80 {
    padding: 80px
}

.p-a90 {
    padding: 90px
}

.p-a100 {
    padding: 100px
}

.p-t0 {
    padding-top: 0
}

.p-t5 {
    padding-top: 5px
}

.p-t10 {
    padding-top: 10px
}

.p-t15 {
    padding-top: 15px
}

.p-t20 {
    padding-top: 20px
}

.p-t30 {
    padding-top: 30px
}

.p-t40 {
    padding-top: 40px
}

.p-t50 {
    padding-top: 50px
}

.p-t60 {
    padding-top: 60px
}

.p-t70 {
    padding-top: 70px
}

.p-t80 {
    padding-top: 80px
}

.p-t90 {
    padding-top: 90px
}

.p-t100 {
    padding-top: 100px
}

.p-b0 {
    padding-bottom: 0
}

.p-b5 {
    padding-bottom: 5px
}

.p-b10 {
    padding-bottom: 10px
}

.p-b15 {
    padding-bottom: 15px
}

.p-b20 {
    padding-bottom: 20px
}

.p-b30 {
    padding-bottom: 30px
}

.p-b40 {
    padding-bottom: 40px
}

.p-b50 {
    padding-bottom: 50px
}

.p-b60 {
    padding-bottom: 60px
}

.p-b70 {
    padding-bottom: 70px
}

.p-b80 {
    padding-bottom: 80px
}

.p-b90 {
    padding-bottom: 90px
}

.p-b100 {
    padding-bottom: 100px
}

.p-l0 {
    padding-left: 0
}

.p-l5 {
    padding-left: 5px
}

.p-l10 {
    padding-left: 10px
}

.p-l15 {
    padding-left: 15px
}

.p-l20 {
    padding-left: 20px
}

.p-l30 {
    padding-left: 30px
}

.p-l40 {
    padding-left: 40px
}

.p-l50 {
    padding-left: 50px
}

.p-l60 {
    padding-left: 60px
}

.p-l70 {
    padding-left: 70px
}

.p-l80 {
    padding-left: 80px
}

.p-l90 {
    padding-left: 90px
}

.p-l100 {
    padding-left: 100px
}

.p-r0 {
    padding-right: 0
}

.p-r5 {
    padding-right: 5px
}

.p-r10 {
    padding-right: 10px
}

.p-r15 {
    padding-right: 15px
}

.p-r20 {
    padding-right: 20px
}

.p-r30 {
    padding-right: 30px
}

.p-r40 {
    padding-right: 40px
}

.p-r50 {
    padding-right: 50px
}

.p-r60 {
    padding-right: 60px
}

.p-r70 {
    padding-right: 70px
}

.p-r80 {
    padding-right: 80px
}

.p-r90 {
    padding-right: 90px
}

.p-r100 {
    padding-right: 100px
}

.p-lr0 {
    padding-left: 0;
    padding-right: 0
}

.p-lr5 {
    padding-left: 5px;
    padding-right: 5px
}

.p-lr10 {
    padding-left: 10px;
    padding-right: 10px
}

.p-lr15 {
    padding-left: 15px;
    padding-right: 15px
}

.p-lr20 {
    padding-left: 20px;
    padding-right: 20px
}

.p-lr30 {
    padding-left: 30px;
    padding-right: 30px
}

.p-lr40 {
    padding-left: 40px;
    padding-right: 40px
}

.p-lr50 {
    padding-left: 50px;
    padding-right: 50px
}

.p-lr60 {
    padding-left: 60px;
    padding-right: 60px
}

.p-lr70 {
    padding-left: 70px;
    padding-right: 70px
}

.p-lr80 {
    padding-left: 80px;
    padding-right: 80px
}

.p-lr90 {
    padding-left: 90px;
    padding-right: 90px
}

.p-lr100 {
    padding-left: 100px;
    padding-right: 100px
}

.p-tb0 {
    padding-bottom: 0;
    padding-top: 0
}

.p-tb5 {
    padding-bottom: 5px;
    padding-top: 5px
}

.p-tb10 {
    padding-bottom: 10px;
    padding-top: 10px
}

.p-tb15 {
    padding-bottom: 15px;
    padding-top: 15px
}

.p-tb20 {
    padding-bottom: 20px;
    padding-top: 20px
}

.p-tb30 {
    padding-bottom: 30px;
    padding-top: 30px
}

.p-tb40 {
    padding-bottom: 40px;
    padding-top: 40px
}

.p-tb50 {
    padding-bottom: 50px;
    padding-top: 50px
}

.p-tb60 {
    padding-bottom: 60px;
    padding-top: 60px
}

.p-tb70 {
    padding-bottom: 70px;
    padding-top: 70px
}

.p-tb80 {
    padding-bottom: 80px;
    padding-top: 80px
}

.p-tb90 {
    padding-bottom: 90px;
    padding-top: 90px
}

.p-tb100 {
    padding-bottom: 100px;
    padding-top: 100px
}

.m-auto {
    margin: auto
}

.m-a0 {
    margin: 0
}

.m-a5 {
    margin: 5px
}

.m-a10 {
    margin: 10px
}

.m-a15 {
    margin: 15px
}

.m-a20 {
    margin: 20px
}

.m-a30 {
    margin: 30px
}

.m-a40 {
    margin: 40px
}

.m-a50 {
    margin: 50px
}

.m-a60 {
    margin: 60px
}

.m-a70 {
    margin: 70px
}

.m-a80 {
    margin: 80px
}

.m-a90 {
    margin: 90px
}

.m-a100 {
    margin: 100px
}

.m-t0 {
    margin-top: 0
}

.m-t5 {
    margin-top: 5px
}

.m-t10 {
    margin-top: 10px
}

.m-t15 {
    margin-top: 15px
}

.m-t20 {
    margin-top: 20px
}

.m-t30 {
    margin-top: 30px
}

.m-t40 {
    margin-top: 40px
}

.m-t50 {
    margin-top: 50px
}

.m-t60 {
    margin-top: 60px
}

.m-t70 {
    margin-top: 70px
}

.m-t80 {
    margin-top: 80px
}

.m-t90 {
    margin-top: 90px
}

.m-t100 {
    margin-top: 100px
}

.m-b0 {
    margin-bottom: 0
}

.m-b5 {
    margin-bottom: 5px
}

.m-b10 {
    margin-bottom: 10px
}

.m-b15 {
    margin-bottom: 15px
}

.m-b20 {
    margin-bottom: 20px
}

.m-b30 {
    margin-bottom: 30px
}

.m-b40 {
    margin-bottom: 40px
}

.m-b50 {
    margin-bottom: 50px
}

.m-b60 {
    margin-bottom: 60px
}

.m-b70 {
    margin-bottom: 70px
}

.m-b80 {
    margin-bottom: 80px
}

.m-b90 {
    margin-bottom: 90px
}

.m-b100 {
    margin-bottom: 100px
}

.m-l0 {
    margin-left: 0
}

.m-l5 {
    margin-left: 5px
}

.m-l10 {
    margin-left: 10px
}

.m-l15 {
    margin-left: 15px
}

.m-l20 {
    margin-left: 20px
}

.m-l30 {
    margin-left: 30px
}

.m-l40 {
    margin-left: 40px
}

.m-l50 {
    margin-left: 50px
}

.m-l60 {
    margin-left: 60px
}

.m-l70 {
    margin-left: 70px
}

.m-l80 {
    margin-left: 80px
}

.m-l90 {
    margin-left: 90px
}

.m-l100 {
    margin-left: 100px
}

.m-r0 {
    margin-right: 0
}

.m-r5 {
    margin-right: 5px
}

.m-r10 {
    margin-right: 10px
}

.m-r15 {
    margin-right: 15px
}

.m-r20 {
    margin-right: 20px
}

.m-r30 {
    margin-right: 30px
}

.m-r40 {
    margin-right: 40px
}

.m-r50 {
    margin-right: 50px
}

.m-r60 {
    margin-right: 60px
}

.m-r70 {
    margin-right: 70px
}

.m-r80 {
    margin-right: 80px
}

.m-r90 {
    margin-right: 90px
}

.m-r100 {
    margin-right: 100px
}

.m-lr0 {
    margin-left: 0;
    margin-right: 0
}

.m-lr5 {
    margin-left: 5px;
    margin-right: 5px
}

.m-lr10 {
    margin-left: 10px;
    margin-right: 10px
}

.m-lr15 {
    margin-left: 15px;
    margin-right: 15px
}

.m-lr20 {
    margin-left: 20px;
    margin-right: 20px
}

.m-lr30 {
    margin-left: 30px;
    margin-right: 30px
}

.m-lr40 {
    margin-left: 40px;
    margin-right: 40px
}

.m-lr50 {
    margin-left: 50px;
    margin-right: 50px
}

.m-lr60 {
    margin-left: 60px;
    margin-right: 60px
}

.m-lr70 {
    margin-left: 70px;
    margin-right: 70px
}

.m-lr80 {
    margin-left: 80px;
    margin-right: 80px
}

.m-lr90 {
    margin-left: 90px;
    margin-right: 90px
}

.m-lr100 {
    margin-left: 100px;
    margin-right: 100px
}

.m-tb0 {
    margin-bottom: 0;
    margin-top: 0
}

.m-tb5 {
    margin-bottom: 5px;
    margin-top: 5px
}

.m-tb10 {
    margin-bottom: 10px;
    margin-top: 10px
}

.m-tb15 {
    margin-bottom: 15px;
    margin-top: 15px
}

.m-tb20 {
    margin-bottom: 20px;
    margin-top: 20px
}

.m-tb30 {
    margin-bottom: 30px;
    margin-top: 30px
}

.m-tb40 {
    margin-bottom: 40px;
    margin-top: 40px
}

.m-tb50 {
    margin-bottom: 50px;
    margin-top: 50px
}

.m-tb60 {
    margin-bottom: 60px;
    margin-top: 60px
}

.m-tb70 {
    margin-bottom: 70px;
    margin-top: 70px
}

.m-tb80 {
    margin-bottom: 80px;
    margin-top: 80px
}

.m-tb90 {
    margin-bottom: 90px;
    margin-top: 90px
}

.m-tb100 {
    margin-bottom: 100px;
    margin-top: 100px
}

.no-col-gap [class*=col-lg-],
.no-col-gap [class*=col-md-],
.no-col-gap [class*=col-sm-],
.no-col-gap [class*=col-xs-] {
    padding-left: 0;
    padding-right: 0
}

@media only screen and (max-width:1200px) {
    .m-lg-t0 {
        margin-top: 0
    }

    .m-lg-t5 {
        margin-top: 5px
    }

    .m-lg-t10 {
        margin-top: 10px
    }

    .m-lg-t15 {
        margin-top: 15px
    }

    .m-lg-t20 {
        margin-top: 20px
    }

    .m-lg-t30 {
        margin-top: 30px
    }

    .m-lg-t40 {
        margin-top: 40px
    }

    .m-lg-t50 {
        margin-top: 50px
    }

    .m-lg-t60 {
        margin-top: 60px
    }

    .m-lg-t70 {
        margin-top: 70px
    }

    .m-lg-t80 {
        margin-top: 80px
    }

    .m-lg-t90 {
        margin-top: 90px
    }

    .m-lg-t100 {
        margin-top: 100px
    }

    .m-lg-b0 {
        margin-bottom: 0
    }

    .m-lg-b5 {
        margin-bottom: 5px
    }

    .m-lg-b10 {
        margin-bottom: 10px
    }

    .m-lg-b15 {
        margin-bottom: 15px
    }

    .m-lg-b20 {
        margin-bottom: 20px
    }

    .m-lg-b30 {
        margin-bottom: 30px
    }

    .m-lg-b40 {
        margin-bottom: 40px
    }

    .m-lg-b50 {
        margin-bottom: 50px
    }

    .m-lg-b60 {
        margin-bottom: 60px
    }

    .m-lg-b70 {
        margin-bottom: 70px
    }

    .m-lg-b80 {
        margin-bottom: 80px
    }

    .m-lg-b90 {
        margin-bottom: 90px
    }

    .m-lg-b100 {
        margin-bottom: 100px
    }
}

@media only screen and (max-width:991px) {
    .m-md-t0 {
        margin-top: 0
    }

    .m-md-t5 {
        margin-top: 5px
    }

    .m-md-t10 {
        margin-top: 10px
    }

    .m-md-t15 {
        margin-top: 15px
    }

    .m-md-t20 {
        margin-top: 20px
    }

    .m-md-t30 {
        margin-top: 30px
    }

    .m-md-t40 {
        margin-top: 40px
    }

    .m-md-t50 {
        margin-top: 50px
    }

    .m-md-t60 {
        margin-top: 60px
    }

    .m-md-t70 {
        margin-top: 70px
    }

    .m-md-t80 {
        margin-top: 80px
    }

    .m-md-t90 {
        margin-top: 90px
    }

    .m-md-t100 {
        margin-top: 100px
    }

    .m-md-b0 {
        margin-bottom: 0
    }

    .m-md-b5 {
        margin-bottom: 5px
    }

    .m-md-b10 {
        margin-bottom: 10px
    }

    .m-md-b15 {
        margin-bottom: 15px
    }

    .m-md-b20 {
        margin-bottom: 20px
    }

    .m-md-b30 {
        margin-bottom: 30px
    }

    .m-md-b40 {
        margin-bottom: 40px
    }

    .m-md-b50 {
        margin-bottom: 50px
    }

    .m-md-b60 {
        margin-bottom: 60px
    }

    .m-md-b70 {
        margin-bottom: 70px
    }

    .m-md-b80 {
        margin-bottom: 80px
    }

    .m-md-b90 {
        margin-bottom: 90px
    }

    .m-md-b100 {
        margin-bottom: 100px
    }
}

@media only screen and (max-width:767px) {
    .m-sm-t0 {
        margin-top: 0
    }

    .m-sm-t5 {
        margin-top: 5px
    }

    .m-sm-t10 {
        margin-top: 10px
    }

    .m-sm-t15 {
        margin-top: 15px
    }

    .m-sm-t20 {
        margin-top: 20px
    }

    .m-sm-t30 {
        margin-top: 30px
    }

    .m-sm-t40 {
        margin-top: 40px
    }

    .m-sm-t50 {
        margin-top: 50px
    }

    .m-sm-t60 {
        margin-top: 60px
    }

    .m-sm-t70 {
        margin-top: 70px
    }

    .m-sm-t80 {
        margin-top: 80px
    }

    .m-sm-t90 {
        margin-top: 90px
    }

    .m-sm-t100 {
        margin-top: 100px
    }

    .m-sm-b0 {
        margin-bottom: 0
    }

    .m-sm-b5 {
        margin-bottom: 5px
    }

    .m-sm-b10 {
        margin-bottom: 10px
    }

    .m-sm-b15 {
        margin-bottom: 15px
    }

    .m-sm-b20 {
        margin-bottom: 20px
    }

    .m-sm-b30 {
        margin-bottom: 30px
    }

    .m-sm-b40 {
        margin-bottom: 40px
    }

    .m-sm-b50 {
        margin-bottom: 50px
    }

    .m-sm-b60 {
        margin-bottom: 60px
    }

    .m-sm-b70 {
        margin-bottom: 70px
    }

    .m-sm-b80 {
        margin-bottom: 80px
    }

    .m-sm-b90 {
        margin-bottom: 90px
    }

    .m-sm-b100 {
        margin-bottom: 100px
    }
}

@media only screen and (max-width:576px) {
    .m-xs-t0 {
        margin-top: 0
    }

    .m-xs-t5 {
        margin-top: 5px
    }

    .m-xs-t10 {
        margin-top: 10px
    }

    .m-xs-t15 {
        margin-top: 15px
    }

    .m-xs-t20 {
        margin-top: 20px
    }

    .m-xs-t30 {
        margin-top: 30px
    }

    .m-xs-t40 {
        margin-top: 40px
    }

    .m-xs-t50 {
        margin-top: 50px
    }

    .m-xs-t60 {
        margin-top: 60px
    }

    .m-xs-t70 {
        margin-top: 70px
    }

    .m-xs-t80 {
        margin-top: 80px
    }

    .m-xs-t90 {
        margin-top: 90px
    }

    .m-xs-t100 {
        margin-top: 100px
    }

    .m-xs-b0 {
        margin-bottom: 0
    }

    .m-xs-b5 {
        margin-bottom: 5px
    }

    .m-xs-b10 {
        margin-bottom: 10px
    }

    .m-xs-b15 {
        margin-bottom: 15px
    }

    .m-xs-b20 {
        margin-bottom: 20px
    }

    .m-xs-b30 {
        margin-bottom: 30px
    }

    .m-xs-b40 {
        margin-bottom: 40px
    }

    .m-xs-b50 {
        margin-bottom: 50px
    }

    .m-xs-b60 {
        margin-bottom: 60px
    }

    .m-xs-b70 {
        margin-bottom: 70px
    }

    .m-xs-b80 {
        margin-bottom: 80px
    }

    .m-xs-b90 {
        margin-bottom: 90px
    }

    .m-xs-b100 {
        margin-bottom: 100px
    }
}

.max-w50 {
    max-width: 50px
}

.max-w60 {
    max-width: 60px
}

.max-w80 {
    max-width: 80px
}

.max-w100 {
    max-width: 100px
}

.max-w200 {
    max-width: 200px
}

.max-w300 {
    max-width: 300px
}

.max-w400 {
    max-width: 400px
}

.max-w500 {
    max-width: 500px
}

.max-w600 {
    max-width: 600px
}

.max-w700 {
    max-width: 700px
}

.max-w800 {
    max-width: 800px
}

.max-w900 {
    max-width: 900px
}

.max-w1000 {
    max-width: 1000px
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.static {
    position: static
}

.z-index1 {
    z-index: 1
}

.z-index2 {
    z-index: 2
}

.z-index3 {
    z-index: 3
}

.z-index4 {
    z-index: 4
}

.z-index5 {
    z-index: 5
}

.z-index6 {
    z-index: 6
}

.z-index7 {
    z-index: 7
}

.z-index8 {
    z-index: 8
}

.z-index9 {
    z-index: 9
}

.z-index10 {
    z-index: 10
}

.z-index100 {
    z-index: 100
}

.z-index999 {
    z-index: 999
}

.text-white {
    color: #fff
}

.text-black {
    color: #000
}

.text-gray {
    color: #f5f6f6
}

.text-gray-dark {
    color: #d3d3d3
}

.text-red {
    color: #ff4045
}

.text-yellow {
    color: #ffb822
}

.text-yellow-light {
    color: #fec215
}

.text-pink {
    color: #ff3c85
}

.text-blue {
    color: #007fff
}

.text-green {
    color: #34bfa3
}

.text-orange {
    color: #ff7901
}

.bg-secondry {
    background-color: #191f23
}

.bg-transparent {
    background-color: transparent
}

.bg-white {
    background-color: #fff
}

.bg-black {
    background-color: #000
}

.bg-black-light {
    background-color: #1c1c1c
}

.bg-gray {
    background-color: #f7f9fb
}

.bg-gray-dark {
    background-color: #d3d3d3
}

.bg-red {
    background-color: #ff4045
}

.bg-green {
    background-color: #34bfa3
}

.bg-yellow {
    background-color: #ffb822
}

.bg-pink {
    background-color: #ff3c85
}

.bg-blue {
    background-color: #007fff
}

.bg-orange {
    background-color: #ff7901
}

.bg-tpw1 {
    background-color: rgba(255, 255, 255, .1)
}

.bg-tpw2 {
    background-color: rgba(255, 255, 255, .2)
}

.bg-tpw3 {
    background-color: rgba(255, 255, 255, .3)
}

.bg-tpw4 {
    background-color: rgba(255, 255, 255, .4)
}

.bg-tpw5 {
    background-color: rgba(255, 255, 255, .5)
}

.bg-tpw6 {
    background-color: rgba(255, 255, 255, .6)
}

.bg-tpw7 {
    background-color: rgba(255, 255, 255, .7)
}

.bg-tpw8 {
    background-color: rgba(255, 255, 255, .8)
}

.bg-tpw9 {
    background-color: rgba(255, 255, 255, .9)
}

.bg-tpb1 {
    background-color: rgba(0, 0, 0, .05)
}

.bg-tpb2 {
    background-color: rgba(0, 0, 0, .2)
}

.bg-tpb3 {
    background-color: rgba(0, 0, 0, .3)
}

.bg-tpb4 {
    background-color: rgba(0, 0, 0, .4)
}

.bg-tpb5 {
    background-color: rgba(0, 0, 0, .5)
}

.bg-tpb6 {
    background-color: rgba(0, 0, 0, .6)
}

.bg-tpb7 {
    background-color: rgba(0, 0, 0, .7)
}

.bg-tpb8 {
    background-color: rgba(0, 0, 0, .8)
}

.bg-tpb9 {
    background-color: rgba(0, 0, 0, .9)
}

.bg-img-fix {
    background-attachment: unset;
    background-size: cover
}

.bg-pt,
.dlab-bnr-inr.bg-pt {
    background-position: top;
    background-size: cover
}

.bg-pb,
.dlab-bnr-inr.bg-pb {
    background-position: bottom;
    background-size: cover
}

.dlab-img-overlay1,
.dlab-img-overlay11,
.dlab-img-overlay12,
.dlab-img-overlay2,
.dlab-img-overlay3,
.dlab-img-overlay4,
.dlab-img-overlay5,
.dlab-img-overlay6,
.dlab-img-overlay7,
.dlab-img-overlay8,
.dlab-img-overlay9 {
    position: relative
}

.dlab-img-overlay11:after,
.dlab-img-overlay11:before,
.dlab-img-overlay12:after,
.dlab-img-overlay12:before,
.dlab-img-overlay1:after,
.dlab-img-overlay1:before,
.dlab-img-overlay2:after,
.dlab-img-overlay2:before,
.dlab-img-overlay3:after,
.dlab-img-overlay3:before,
.dlab-img-overlay4:after,
.dlab-img-overlay4:before,
.dlab-img-overlay5:after,
.dlab-img-overlay5:before,
.dlab-img-overlay6:after,
.dlab-img-overlay6:before,
.dlab-img-overlay7:after,
.dlab-img-overlay7:before,
.dlab-img-overlay8:after,
.dlab-img-overlay8:before,
.dlab-img-overlay9:after,
.dlab-img-overlay9:before {
    content: "";
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: .4;
    z-index: 1;
    -webkit-transition: all .8s linear;
    -moz-transition: all .8s linear;
    -ms-transition: all .8s linear;
    -o-transition: all .8s linear;
    transition: all .8s linear
}

.dlab-img-overlay1:before {
    width: 100%;
    height: 100%;
    opacity: 0
}

.dlab-box:hover .dlab-img-overlay1:before,
.dlab-img-overlay1:hover:before {
    opacity: .5
}

.dlab-img-overlay2:before {
    width: 100%;
    height: 100%;
    opacity: 0;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .4) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .4) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .4) 100%)
}

.dlab-img-overlay2.on:before {
    opacity: 1
}

.dlab-box:hover .dlab-img-overlay2:before,
.dlab-img-overlay2:hover:before {
    opacity: .9
}

.dlab-img-overlay3:before {
    left: 50%;
    top: 50%
}

.dlab-box:hover .dlab-img-overlay3:before,
.dlab-img-overlay3:hover:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.dlab-img-overlay4:before {
    left: 50%;
    top: 0;
    height: 100%
}

.dlab-box:hover .dlab-img-overlay4:before,
.dlab-img-overlay4:hover:before {
    width: 100%;
    left: 0
}

.dlab-img-overlay5:before {
    left: 0;
    top: 50%;
    width: 100%
}

.dlab-box:hover .dlab-img-overlay5:before,
.dlab-img-overlay5:hover:before {
    height: 100%;
    top: 0
}

.dlab-img-overlay6:before {
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%
}

.dlab-box:hover .dlab-img-overlay6:before,
.dlab-img-overlay6:hover:before {
    height: 100%
}

.dlab-img-overlay7:after,
.dlab-img-overlay7:before {
    height: 100%
}

.dlab-img-overlay7:after {
    left: auto;
    right: 0
}

.dlab-box:hover .dlab-img-overlay7:after,
.dlab-box:hover .dlab-img-overlay7:before,
.dlab-img-overlay7:hover:after,
.dlab-img-overlay7:hover:before {
    width: 50%
}

.dlab-img-overlay8:after,
.dlab-img-overlay8:before {
    height: 100%
}

.dlab-img-overlay8:after {
    left: auto;
    right: 0
}

.dlab-box:hover .dlab-img-overlay8:after,
.dlab-box:hover .dlab-img-overlay8:before,
.dlab-img-overlay8:hover:after,
.dlab-img-overlay8:hover:before {
    width: 100%;
    opacity: .3
}

.dlab-img-overlay9:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0
}

.dlab-box:hover .dlab-img-overlay9:after,
.dlab-box:hover .dlab-img-overlay9:before,
.dlab-img-overlay9:hover:after,
.dlab-img-overlay9:hover:before {
    width: 100%;
    height: 100%;
    opacity: .3
}

.dlab-img-overlay10:after,
.dlab-img-overlay10:before {
    width: 100%;
    height: 100%;
    opacity: 0
}

.dlab-box:hover .dlab-img-overlay10:before,
.dlab-img-overlay10:hover:before {
    opacity: .5
}

.dlab-box:hover .dlab-img-overlay10:after,
.dlab-img-overlay10:hover:after {
    width: 90%;
    height: 80%;
    left: 5%;
    top: 10%;
    opacity: .5;
    background: 0 0;
    border: 1px dashed #fff
}

.dlab-box:hover .dlab-img-overlay11:after,
.dlab-img-overlay11:hover:after {
    height: 95%;
    opacity: 1;
    right: 7.5%;
    top: 2.5%;
    -moz-transform: skewY(0);
    -webkit-transform: skewY(0);
    -o-transform: skewY(0);
    -ms-transform: skewY(0);
    transform: skewY(0);
    width: 85%
}

.dlab-box:hover .dlab-img-overlay11:before,
.dlab-img-overlay11:hover:before {
    height: 85%;
    left: 2.5%;
    opacity: 1;
    top: 7.5%;
    -moz-transform: skewY(0);
    -webkit-transform: skewY(0);
    -o-transform: skewY(0);
    -ms-transform: skewY(0);
    transform: skewY(0);
    width: 95%
}

.dlab-img-overlay11:after {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    bottom: 5%;
    height: 80%;
    left: auto;
    right: 5%;
    top: auto;
    -moz-transform: skewY(70deg);
    -webkit-transform: skewY(70deg);
    -o-transform: skewY(70deg);
    -ms-transform: skewY(70deg);
    transform: skewY(70deg);
    width: 90%
}

.dlab-img-overlay11:before {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    bottom: auto;
    height: 90%;
    left: 5%;
    right: auto;
    top: 5%;
    -moz-transform: skewX(-70deg);
    -webkit-transform: skewX(-70deg);
    -o-transform: skewX(-70deg);
    -ms-transform: skewX(-70deg);
    transform: skewX(-70deg);
    width: 80%
}

.dlab-img-overlay11 {
    background-color: #000 !important
}

.dlab-img-overlay11:hover img {
    opacity: .7 !important
}

.dlab-box:hover .dlab-img-overlay12:after,
.dlab-img-overlay12:hover:after {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
    -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
    -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
    -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1)
}

.dlab-img-overlay12:after {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -moz-transform-origin: 50% 50% 0;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    -o-transform-origin: 50% 50% 0;
    width: 100%;
    height: 100%
}

.dlab-box:hover .dlab-img-overlay12.left:after,
.dlab-img-overlay12.left:hover:after {
    transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    -moz-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    -webkit-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    -o-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1)
}

.dlab-img-overlay12.left:after {
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -moz-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -o-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -webkit-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -moz-transform-origin: 50% 50% 0;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    -o-transform-origin: 50% 50% 0
}

.dlab-img-overlay12:before {
    width: 100%;
    height: 100%;
    opacity: 0
}

.dlab-box:hover .dlab-img-overlay12:before,
.dlab-img-overlay12:hover:before {
    opacity: .4
}

.dlab-img-effect {
    position: relative;
    overflow: hidden;
    display: block
}

.dlab-img-effect img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 transparent;
    -webkit-box-shadow: 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 transparent;
    transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -o-transition: all .8s
}

.dlab-img-effect.opacity img:hover {
    opacity: .8;
    -moz-opacity: .8;
    -webkit-opacity: .8
}

.dlab-img-effect.zoom-slow img {
    transition: all 10s;
    -moz-transition: all 10s;
    -webkit-transition: all 10s;
    -o-transition: all 10s
}

.dlab-img-effect.zoom-slow:hover img {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.blog-post:hover .dlab-img-effect.zoom img,
.dlab-box:hover .dlab-img-effect.zoom img,
.dlab-img-effect.zoom:hover img,
.our-team:hover .dlab-img-effect.zoom img {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
}

.dlab-img-effect.shrink:hover img {
    transform: scale(.8);
    -ms-transform: scale(.8);
    -webkit-transform: scale(.8);
    -o-transform: scale(.8);
    -moz-transform: scale(.8)
}

.dlab-img-effect.side-pan:hover img {
    margin-left: -11%;
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2)
}

.dlab-img-effect.vertical-pan:hover img {
    margin-top: -10%;
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2)
}

.dlab-img-effect.fade-in {
    background: #000
}

.dlab-img-effect.fade-in img {
    opacity: .65;
    -moz-opacity: .65;
    -webkit-opacity: .65
}

.dlab-img-effect.fade-in:hover img {
    opacity: 1;
    -moz-opacity: 1;
    -webkit-opacity: 1
}

.dlab-img-effect.fade-out {
    background: #000
}

.dlab-img-effect.fade-out:hover img {
    opacity: .7;
    -moz-opacity: .7;
    -webkit-opacity: .7
}

.blog-post:hover .dlab-img-effect.rotate img,
.dlab-box:hover .dlab-img-effect.rotate img,
.dlab-img-effect.rotate:hover img {
    -moz-transform: scale(1.5) rotate(-20deg);
    -webkit-transform: scale(1.5) rotate(-20deg);
    -o-transform: scale(1.5) rotate(-20deg);
    -ms-transform: scale(1.5) rotate(-20deg);
    transform: scale(1.5) rotate(-20deg)
}

.dlab-img-effect.sepia img {
    -webkit-filter: sepia(100%);
    filter: sepia(100%)
}

.algo-image-hover.sepia:hover img {
    -webkit-filter: sepia(0);
    filter: sepia(0)
}

.dlab-img-effect.blurr img {
    transition: all .2s;
    -moz-transition: all .2s;
    -webkit-transition: all .2s;
    -o-transition: all .2s
}

.dlab-img-effect.blurr:hover img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px)
}

.dlab-img-effect.blurr-invert img {
    transition: all .2s;
    -moz-transition: all .2s;
    -webkit-transition: all .2s;
    -o-transition: all .2s
}

.dlab-img-effect.blurr-invert img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px)
}

.dlab-img-effect.blurr-invert:hover img {
    filter: blur(0);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -o-filter: blur(0);
    -ms-filter: blur(0)
}

.dlab-img-effect.off-color img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0)
}

.dlab-img-effect.off-color:hover img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1)
}

.dlab-img-effect.on-color img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1)
}

.dlab-img-effect.on-color:hover img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0)
}

.dlab-info-has {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 30px 15px 40px;
    opacity: 0;
    margin-bottom: -100px;
    z-index: 2
}

.dlab-info-has.p-a15 {
    padding: 15px
}

.dlab-info-has.p-a20 {
    padding: 20px
}

.dlab-info-has.bg-primary {
    background-color: rgba(27, 188, 232, .85)
}

.dlab-info-has.bg-green {
    background-color: rgba(106, 179, 62, .85)
}

.dlab-info-has.bg-black {
    background-color: rgba(0, 0, 0, .6)
}

.dlab-info-has.bg-white {
    background-color: rgba(255, 255, 255, .85) !important
}

.dlab-info-has.bg-orange {
    background-color: rgba(255, 121, 0, .85) !important
}

.dlab-info-has.bg-blue {
    background-color: rgba(0, 128, 255, .85) !important
}

.dlab-info-has.bg-brown {
    background-color: rgba(53, 55, 103, .85) !important
}

.dlab-info-has.bg-white {
    background-color: rgba(255, 255, 255, .85) !important
}

.dlab-info-has.bg-white h1,
.dlab-info-has.bg-white h2,
.dlab-info-has.bg-white h3,
.dlab-info-has.bg-white h4,
.dlab-info-has.bg-white h5,
.dlab-info-has.bg-white h6 {
    color: #3d474a
}

.dlab-info-has.bg-white h1 a,
.dlab-info-has.bg-white h2 a,
.dlab-info-has.bg-white h3 a,
.dlab-info-has.bg-white h4 a,
.dlab-info-has.bg-white h5 a,
.dlab-info-has.bg-white h6 a {
    color: #3d474a
}

.dlab-info-has.bg-white .dlab-info-has-text,
.dlab-info-has.bg-white p {
    color: #767676
}

.dlab-info-has a,
.dlab-info-has h1,
.dlab-info-has h2,
.dlab-info-has h3,
.dlab-info-has h4,
.dlab-info-has h5,
.dlab-info-has h6,
.dlab-info-has p,
.dlab-info-has-text {
    color: #fff
}

.dlab-info-has .dlab-info-has-text {
    margin-bottom: 20px;
    line-height: 24px
}

.dlab-media {
    overflow: hidden;
    position: relative
}

.dlab-box:hover .dlab-info-has,
.dlab-media:hover .dlab-info-has {
    opacity: 1;
    width: 100%;
    margin-bottom: 0
}

.dlab-info-has.no-hover,
.overlay-bx.no-hover {
    opacity: 1;
    width: 100%;
    margin-bottom: 0;
    visibility: visible
}

.dlab-info-has.skew-has {
    border-top: 4px solid #2d3239;
    padding: 30px 40px 60px;
    -moz-transform: skewY(10deg);
    -webkit-transform: skewY(10deg);
    -o-transform: skewY(10deg);
    -ms-transform: skewY(10deg);
    transform: skewY(10deg);
    bottom: -35px
}

.dlab-info-has.skew-has>* {
    -moz-transform: skewY(-10deg);
    -webkit-transform: skewY(-10deg);
    -o-transform: skewY(-10deg);
    -ms-transform: skewY(-10deg);
    transform: skewY(-10deg)
}

label {
    font-weight: 600;
    margin-bottom: 10px
}

.panel-default,
.panel-default>.panel-heading {
    border-color: #e7ecf1;
    color: inherit
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #e7ecf1
}

.form-group {
    margin-bottom: 25px
}

.form-control {
    border-color: #e1e6eb;
    box-shadow: none;
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 12px
}

textarea.form-control {
    height: auto
}

.form-control:focus {
    border-color: #e1e1e1;
    outline: 0
}

.form-control:focus,
.has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control.kv-fileinput-caption {
    height: 39px
}

.form-group.form-inline .checkbox,
.form-group.form-inline .radio {
    margin-right: 15px
}

.input-group {
    width: 100%
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: table-cell
}

.input-group-addon {
    background: #fff;
    border-color: #e1e1e1;
    padding: 6px 14px;
    font-size: 16px
}

.input-group-addon.font-size-20 {
    font-size: 20px
}

.input-group-addon.fixed-w {
    text-align: center;
    padding: 6px 0;
    width: 40px
}

.input-group-addon.v-align-t {
    line-height: 30px
}

.dropdown-menu {
    font-size: 14px
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%
}

.bootstrap-select .dropdown-toggle {
    border: 1px solid #e7ecf1 !important;
    background-color: #fff !important;
    height: 40px;
    font-size: 13px;
    color: #999
}

.bootstrap-select .dropdown-toggle:after {
    display: none
}

.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
    background-color: #fff !important;
    border: 1px solid #e9e9e9 !important;
    box-shadow: none !important;
    outline: 0 !important
}

.bootstrap-select:active,
.bootstrap-select:focus {
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important
}

.bootstrap-select.show {
    box-shadow: none !important;
    outline: 0 !important
}

.bootstrap-select.show .dropdown-toggle {
    box-shadow: none !important;
    outline: 0 !important
}

.bootstrap-select.show.btn-group .dropdown-menu.inner {
    display: block
}

.bootstrap-select div.dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 10px 0;
    font-size: 13px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .2)
}

.bootstrap-select div.dropdown-menu ul li {
    padding: 0
}

.bootstrap-select div.dropdown-menu ul li a {
    padding: 8px 15px
}

.bootstrap-select div.dropdown-menu ul li a img {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    margin-right: 5px
}

.bootstrap-select .bs-searchbox .form-control {
    padding: 5px 10px;
    height: 30px
}

.bootstrap-select .dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap
}

.bootstrap-select .dropdown-menu>li>a:hover {
    background-color: #f2f2f4
}

input[type=checkbox],
input[type=radio] {
    opacity: 0;
    margin-left: 1px !important;
    position: absolute
}

input[type=checkbox]+label,
input[type=radio]+label {
    display: block;
    padding-left: 25px !important;
    position: relative
}

input[type=checkbox]+label:hover:before,
input[type=radio]+label:hover:before {
    border-color: #3396d1
}

input[type=checkbox]+label:before,
input[type=radio]+label:before {
    background-color: #fff;
    border: 2px solid #b6b7b8;
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 16px;
    left: 1px;
    line-height: 1;
    position: absolute;
    top: 50%;
    transition: all .1s linear 0s;
    -moz-transition: all .1s linear 0s;
    -webkit-transition: all .1s linear 0s;
    -o-transition: all .1s linear 0s;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 16px
}

input[type=checkbox]+label:after {
    color: #fff;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 0;
    -webkit-font-smoothing: antialiased;
    left: 6px;
    position: absolute;
    top: 7px;
    transition: all linear .1s;
    -moz-transition: all linear .1s;
    -webkit-transition: all linear .1s;
    -o-transition: all linear .1s;
    width: 14px;
    text-align: center
}

input[type=checkbox]:checked+label:before {
    border-width: 7px
}

input[type=checkbox]:checked+label:after {
    font-size: 10px;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

input[type=radio]+label:before {
    border-radius: 50%;
    content: ""
}

input[type=radio]:checked+label:before {
    border-color: #3396d1;
    border-width: 5px
}

.form-inline .checkbox input[type=checkbox],
.form-inline .radio input[type=radio] {
    position: absolute;
    left: 0;
    bottom: 0
}

.bootstrap-touchspin .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: block
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 10px 10px 9px;
    margin-left: -1px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: #e1e1e1;
    background: #fff
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 0
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 0
}

.bootstrap-touchspin .input-group-btn-vertical i {
    position: absolute;
    top: 4px;
    left: 5px;
    font-size: 9px;
    font-weight: 400;
    color: #9fa0a1
}

.input-rating input[type=radio] {
    display: none
}

.input-rating input[type=radio]+label {
    display: inline-block;
    margin-left: -4px;
    padding-left: 0 !important;
    padding-right: 0;
    width: 24px
}

.input-rating input[type=radio]+label:first-of-type {
    margin-left: 0
}

.input-rating input[type=radio]+label:before {
    background-color: transparent;
    border: none;
    color: #ffd925;
    content: "\f006";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    height: 14px;
    line-height: 1;
    margin-left: 0;
    position: static;
    text-align: center;
    top: 2px;
    transition: none;
    width: 14px
}

.input-rating input[type=radio]+label.filled:before,
.input-rating input[type=radio]+label.hovered.filled:before,
.input-rating input[type=radio]+label.marked:before,
.input-rating input[type=radio]:checked+label:before {
    content: "\f005"
}

.input-rating input[type=radio]+label.hovered:before {
    content: "\f006"
}

.rating-bx {
    color: #ffc300;
    font-size: 14px
}

.rating-bx i {
    margin: 0 2px
}

.rating-container .rating-stars::before {
    text-shadow: none
}

.rating-container {
    color: #c8c8c8
}

.rating-container .rating-stars {
    color: #3396d1
}

.has-feedback label~.form-control-feedback {
    top: 31px
}

.dlab-banner-outer {
    margin-bottom: 0
}

.dlab-error-top,
.dlab-success-top {
    margin-bottom: 0
}

.alert-bx.alert-info {
    padding: 15px;
    border: 1px solid #bce8f1
}

.alignnone {
    margin: 0
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 15px
}

.alignright,
.alignright.is-resized {
    float: right;
    margin: 5px 0 25px 25px
}

.alignleft,
.alignleft.is-resized {
    float: left;
    margin: 5px 25px 25px 0
}

a img.alignright {
    float: right;
    margin: 5px 0 25px 25px
}

a img.alignnone {
    margin: 5px 0 25px 0
}

a img.alignleft {
    float: left;
    margin: 5px 25px 25px 0
}

a img.aligncenter {
    display: block;
    margin: 5px auto 15px
}

.wp-caption {
    max-width: 100%;
    text-align: center
}

.wp-caption img[class*=wp-image-] {
    display: block;
    margin: 0
}

.wp-caption.alignnone {
    margin: 5px 0 25px 0
}

.wp-caption.alignleft {
    margin: 5px 25px 25px 0
}

.wp-caption.alignright {
    margin: 5px 0 25px 25px
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto
}

.wp-caption p.wp-caption-text {
    color: #9d9d9d;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    padding: 10px 0;
    text-align: left
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-quote {
    padding: 30px 30px 25px 30px;
    font-size: 15px;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 40px;
    line-height: 30px;
    position: relative;
    clear: both;
    background: var(--color-primary);
    font-weight: 700;
    overflow: hidden;
    z-index: 1;
    border: 0
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
    background: #5608e0
}

blockquote.wp-block-pullquote.alignleft {
    margin: 0 25px 25px 0
}

blockquote.wp-block-pullquote.alignright {
    margin: 0 0 25px 25px
}

.wp-caption-text {
    font-size: 14px;
    line-height: 1.3
}

.blog-post.blog-single .dlab-post-text blockquote p,
.dlab-page-text blockquote p,
blockquote p {
    font-size: 24px;
    line-height: 1.4;
    color: #fff;
    margin-bottom: 10px;
    font-weight: 500;
    font-style: italic
}

blockquote a,
blockquote a:hover {
    color: #fff
}

blockquote cite {
    font-style: normal;
    position: relative;
    display: block;
    margin-top: -5px;
    font-weight: 600
}

.wp-block-pullquote cite,
.wp-block-pullquote footer,
.wp-block-pullquote__citation,
blockquote.wp-block-quote cite {
    color: #fff;
    text-transform: capitalize
}

blockquote cite:before {
    content: "-";
    margin-right: 10px
}

blockquote:before {
    content: "";
    z-index: -1;
    background-size: cover;
    left: -2%;
    background-image: url(../images/quote-white.png);
    background-position: center right;
    width: 35%;
    padding: 0;
    opacity: .3;
    background-repeat: no-repeat;
    height: 100%;
    top: 0;
    position: absolute
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto
}

@media only screen and (max-width:991px) {
    blockquote {
        padding: 20px 20px 15px 20px;
        font-size: 13px
    }

    .blog-post.blog-single .dlab-post-text blockquote p,
    blockquote p {
        font-size: 18px
    }
}

.site-header {
    position: relative;
    z-index: 99999
}

.site-header ol,
.site-header ul {
    margin-bottom: 0
}

.extra-nav {
    float: right;
    height: 70px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9
}

.extra-nav .site-button-link {
    font-size: 25px;
    line-height: 25px;
    color: #020d26;
    cursor: pointer;
    margin-right: 10px
}

.extra-nav .extra-cell {
    display: inline-block;
    margin-left: 10px;
    display: flex;
    align-items: center
}

.ext-header .main-bar .logo-header {
    display: none
}

@media only screen and (max-width:991px) {
    .ext-header .main-bar .logo-header {
        display: table
    }
}

@media only screen and (max-width:767px) {
    .extra-nav {
        margin-right: 15px
    }
}

@media only screen and (max-width:480px) {
    .extra-nav {
        margin: 0 1px 0 0;
        text-align: right
    }
}

#header-part.fix-map-header {
    height: 90px
}

#header-part.fix-map-header .main-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1
}

@media only screen and (max-width:480px) {
    #header-part.fix-map-header {
        height: 160px
    }
}

.top-bar {
    background-color: #191f23;
    color: #fff;
    padding: 8px 0
}

.top-bar.bg-white {
    background-color: #191f23;
    color: #777;
    border-bottom: 1px solid #eee
}

.dlab-topbar-left {
    float: left
}

.dlab-topbar-right {
    float: right
}

.dlab-topbar-center,
.dlab-topbar-left,
.dlab-topbar-right {
    padding-left: 15px;
    padding-right: 15px
}

.dlab-topbar-left ul,
.dlab-topbar-right ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.dlab-topbar-left ul li,
.dlab-topbar-right ul li {
    display: inline-block;
    font-size: 15px
}

.dlab-topbar-left ul li i,
.dlab-topbar-right ul li i {
    color: var(--color-primary);
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    margin-right: 5px
}

.dlab-topbar-left ul li {
    padding-right: 15px;
    position: relative
}

.dlab-topbar-right ul li {
    padding-left: 15px;
    position: relative
}

.dlab-topbar-left ul li:after {
    content: "";
    height: 15px;
    width: 1px;
    background: #fff;
    position: absolute;
    right: 5px;
    opacity: .2;
    top: 4px
}

.dlab-topbar-left ul li:last-child:after {
    content: none
}

.dlab-topbar-left ul li a,
.dlab-topbar-right ul li a {
    color: #fff
}

.dlab-topbar-left ul li a {
    position: relative
}

.topbar-social li a {
    padding: 0 6px
}

@media only screen and (max-width:991px) {
    .dlab-topbar-right {
        padding-left: 0;
        padding-right: 15px
    }

    .dlab-topbar-left {
        padding-left: 15px;
        padding-right: 0
    }
}

@media only screen and (max-width:767px) {
    .top-bar [class*=col-] {
        width: 100%;
        text-align: right;
        padding: 10px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, .1)
    }

    .top-bar [class*=col-]:last-child {
        border-bottom: none
    }

    .top-bar {
        padding: 5px 0
    }

    .dlab-topbar-center,
    .dlab-topbar-left,
    .dlab-topbar-right {
        display: #000;
        width: 100%;
        text-align: center;
        padding: 3px 15px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px
    }

    .dlab-topbar-left ul li {
        padding: 0 5px
    }
}

.middle-bar {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 20px 0
}

.middle-area {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.middle-bar .logo-header,
.middle-bar .logo-header img {
    width: 200px;
    max-width: 200px
}

.service-list ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.service-list ul li {
    display: inline-block;
    position: relative;
    padding: 0 0 0 55px;
    margin-left: 15px
}

.service-list ul li i {
    position: absolute;
    left: 10px;
    font-size: 38px;
    line-height: 38px;
    color: var(--color-primary);
    font-weight: 100;
    top: 2px;
    transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s
}

.service-list ul li:hover i {
    transform: scale(1.2)
}

.service-list ul li .title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 24px
}

.service-list ul li span {
    font-size: 14px;
    color: #999
}

.e-p-bx li {
    display: inline;
    font-size: 14px;
    padding-right: 10px
}

.e-p-bx li a {
    color: #fff
}

.e-p-bx li i {
    margin-right: 10px;
    vertical-align: middle
}

.login-bx,
.social-bx {
    margin: 0 0 0 15px;
    float: right
}

.login-bx li,
.social-bx li {
    font-size: 12px;
    margin: 0;
    position: relative
}

.social-bx li {
    padding-left: 1px;
    padding-right: 1px
}

.login-bx li a,
.social-bx li a {
    color: #fff
}

.login-bx li i,
.social-bx li i {
    vertical-align: baseline;
    margin: 0 5px 0 0
}

.login-bx li span,
.social-bx li span {
    vertical-align: baseline;
    margin: 0 2px 0;
    font-size: 8px
}

.login-bx li ul {
    top: 25px;
    left: auto;
    right: 0;
    border: none;
    padding: 0
}

.login-bx li ul li {
    border-bottom: 1px solid #f7f7f7
}

.login-bx li ul li a {
    color: #767676;
    padding: 9px 15px
}

.arrow-up-border:before,
.arrow-up:after {
    position: absolute;
    display: inline-block;
    content: ''
}

.arrow-up-border:before {
    top: -7px;
    right: 19px;
    border-right: 7px solid transparent;
    border-bottom: 7px solid red;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .2)
}

.arrow-up:after {
    top: -6px;
    right: 20px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent
}

.arrow-left:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .2);
    content: ''
}

.arrow-left:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: ''
}

.language-bx li {
    display: inline;
    margin: 0 10px
}

.logo-header {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #efbb20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 180px;
    height: 70px;
    position: relative;
    z-index: 9;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s
}

.logo-footer {
    display: table;
    vertical-align: middle;
    padding: 0;
    color: #efbb20;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: 60px
}

.logo-footer>a,
.logo-header>a {
    display: table-cell;
    vertical-align: middle
}

.logo-header.center-block {
    margin-left: auto;
    margin-right: auto;
    float: none
}

.logo-footer img,
.logo-header img {
    height: auto;
    width: 100%;
    max-width: 180px
}

.logo-footer span,
.logo-header span {
    font-size: 20px;
    color: #efbb20;
    letter-spacing: 20px
}

.is-fixed .logo-header,
.is-fixed.header-curve .logo-header {
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

@media only screen and (max-width:767px) {

    .is-fixed .logo-header,
    .is-fixed.header-curve .logo-header,
    .logo-header {
        width: 135px;
        max-width: 135px;
        height: 70px
    }

    .logo-footer img,
    .logo-header img {
        max-width: 135px
    }

    .is-fixed .logo-header a img,
    .is-fixed.header-curve .logo-header a img {
        vertical-align: sub
    }

    .is-fixed .header-nav .logo-header a img,
    .is-fixed.header-curve .header-nav .logo-header a img {
        vertical-align: middle
    }

    .site-header .is-fixed .navbar-toggler,
    .site-header .navbar-toggler {
        margin: 27px 0 16px 10px
    }

    .site-header .extra-nav,
    .site-header .is-fixed .extra-nav {
        padding: 10px 0
    }
}

.main-bar {
    background: #fff;
    width: 100%;
    position: relative
}

.header-curve .logo-header:after,
.header-curve .logo-header:before {
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    z-index: -1
}

.header-curve .logo-header:before {
    right: -50px;
    width: 80%;
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
    -ms-transform: skew(30deg);
    transform: skew(30deg)
}

.header-curve .logo-header:after {
    right: 10%;
    width: 2000px
}

.is-fixed.header-curve .logo-header img {
    margin-top: 0
}

.header-curve .site-button-link,
.header-curve .site-button-link:hover {
    color: #fff
}

@media only screen and (max-width:991px) {

    .header-curve .header-nav .logo-header:after,
    .header-curve .header-nav .logo-header:before {
        content: none
    }
}

@media only screen and (max-width:767px) {
    .header-curve .logo-header:after {
        right: 0;
        left: auto
    }
}

.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 26px 0 18px 15px;
    padding: 0;
    float: right
}

.navbar-toggler span {
    background: #666
}

.site-header .main-bar .container {
    position: relative
}

.header-nav {
    padding: 0
}

.header-nav .nav {
    float: right
}

.header-nav .nav i {
    font-size: 9px;
    margin-left: 3px;
    margin-top: -3px;
    vertical-align: middle
}

.header-nav .nav>li {
    margin: 0;
    font-weight: 400;
    position: relative
}

.header-nav .nav>li>a {
    border-radius: 0;
    color: #020d26;
    font-size: 15px;
    padding: 24px 10px 24px 10px;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    position: relative;
    border-width: 0 0 0 1px;
    text-transform: uppercase
}

.header-nav .nav>li>a.nav-link {
    padding-left: 12px;
    padding-right: 12px
}

.header-nav .nav>li>a:hover {
    background-color: transparent;
    color: #efbb20
}

.header-nav .nav>li>a:active,
.header-nav .nav>li>a:focus {
    background-color: transparent
}

.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a {
    background-color: transparent;
    color: #efbb20
}

.header-nav .nav>li.active>a:after {
    content: "";
    height: 5px;
    background: var(--color-primary);
    width: calc(100% + 2px);
    position: absolute;
    bottom: 0;
    left: -1px
}

.main-bar.bg-primary .header-nav .nav>li.active>a:after,
.main-bar.bg-primary .header-nav .nav>li>a:after {
    background: #fff
}

.header-nav .nav>li>a:after {
    content: "";
    height: 5px;
    background: var(--color-primary);
    width: 0;
    position: absolute;
    bottom: 0;
    right: -1px;
    transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s
}

.header-nav .nav>li:hover>a:after {
    width: 100%;
    transform-origin: right;
    -moz-transform-origin: right;
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    right: auto;
    left: 0
}

.header-nav .nav>li:hover>.mega-menu,
.header-nav .nav>li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: all .8s ease;
    -moz-transition: all .8s ease;
    -ms-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease
}

.header-nav .nav>li>.mega-menu,
.header-nav .nav>li>.sub-menu {
    box-shadow: 0 1px 40px 0 rgba(0, 0, 0, .1)
}

.header-nav .nav>li .mega-menu,
.header-nav .nav>li .sub-menu {
    border-radius: 2px
}

.header-nav .nav>li .sub-menu {
    background-color: #fff;
    display: block;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 5px 0;
    position: absolute;
    visibility: hidden;
    width: 220px;
    z-index: 10
}

.header-nav .nav>li .sub-menu li {
    border-bottom: 0 solid #f4f4f4;
    position: relative
}

.header-nav .nav>li .sub-menu li a {
    color: #000;
    display: block;
    font-size: 15px;
    padding: 10px 25px;
    text-transform: capitalize;
    transition: all .15s linear;
    -webkit-transition: all .15s linear;
    -moz-transition: all .15s linear;
    -o-transition: all .15s linear;
    font-weight: 400
}

.header-nav .nav>li .mega-menu>li ul a:hover,
.header-nav .nav>li .sub-menu li:hover>a {
    background-color: #f2f2f2;
    text-decoration: none
}

.header-nav .nav>li .sub-menu li:hover>a {
    color: #efbb20
}

.header-nav .nav>li .sub-menu li:last-child {
    border-bottom: 0
}

.header-nav .nav>li .sub-menu li:hover .sub-menu.left,
.header-nav .nav>li .sub-menu li>.sub-menu.left {
    left: auto;
    right: 220px
}

.header-nav .nav>li .sub-menu li .fa {
    color: inherit;
    display: block;
    float: right;
    font-size: 16px;
    opacity: 1
}

.header-nav .nav>li .sub-menu li .fa.fa-nav {
    color: inherit;
    display: inline-block;
    float: none;
    font-size: 13px;
    margin-right: 5px;
    opacity: 1;
    position: unset;
    right: 10px;
    top: 12px
}

.header-nav .nav>li .sub-menu li>.sub-menu {
    left: 220px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu {
    left: 220px;
    margin: 0;
    opacity: 1;
    top: -1px;
    visibility: visible
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
    background-color: transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0;
    width: 6px
}

.header-nav .nav>li.has-mega-menu {
    position: inherit
}

.header-nav .nav>li .mega-menu {
    background-color: #fff;
    border: 1px solid #f8f8f8;
    display: flex;
    left: 0;
    list-style: none;
    opacity: 0;
    position: absolute;
    right: 0;
    visibility: hidden;
    width: 100%;
    margin-top: 20px;
    z-index: 9
}

.header-nav .nav>li .mega-menu>li {
    display: block;
    padding: 30px 0 25px;
    position: relative;
    vertical-align: top;
    width: 25%
}

.header-nav .nav>li .mega-menu>li:after {
    content: "";
    background-color: rgba(0, 0, 0, .1);
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 1px;
    height: 100%
}

.header-nav .nav>li .mega-menu>li:last-child:after {
    display: none
}

.header-nav .nav>li .mega-menu>li>a {
    color: #000;
    display: block;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 15px
}

.header-nav .nav>li .mega-menu>li ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0;
    width: 100%
}

.header-nav .nav>li .mega-menu>li ul a {
    color: #000;
    display: block;
    font-size: 15px;
    text-transform: capitalize;
    padding: 10px 25px;
    font-weight: 400
}

.header-nav .nav>li .mega-menu>li ul a:hover {
    color: #efbb20
}

.header-nav .nav .mega-menu a i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px
}

.header-nav .nav>li.menu-item-has-children:before {
    content: "\f078";
    display: block;
    font-family: FontAwesome;
    right: 4px;
    position: absolute;
    top: 50%;
    color: #999;
    margin-top: -8px;
    font-size: 8px
}

.site-header.center .header-nav .nav {
    display: inline-block;
    float: left;
    width: 40%
}

.site-header.center .navbar-nav>li {
    display: inline-block;
    float: none
}

.site-header.center .header-nav .nav:first-child {
    text-align: right
}

.site-header.center .header-nav .nav:first-child li {
    text-align: left
}

.site-header.center .header-nav .nav:last-child {
    float: right
}

.site-header.center .logo-header {
    position: absolute;
    transform: translate(-50%);
    -moz-transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -o-transform: translate(-50%);
    position: absolute;
    left: 50%;
    text-align: center
}

.site-header.center .logo-header img {
    height: 100px;
    margin-bottom: -50px;
    position: relative;
    width: 100px;
    z-index: 2;
    object-fit: contain
}

.site-header.center .logo-header:after {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    content: "";
    height: 100px;
    position: absolute;
    width: 140%;
    bottom: -99px;
    left: -20%
}

.header-nav .nav>li.has-mega-menu.demos .mega-menu {
    left: auto;
    right: auto;
    max-width: 600px;
    z-index: 9
}

@media only screen and (max-width:1200px) {
    .header-nav .nav>li>a {
        padding: 25px 10px 24px 10px;
        font-size: 14px;
        font-weight: 500
    }

    .extra-nav .site-button-link {
        font-size: 22px;
        line-height: 22px
    }

    .logo-footer img,
    .logo-header img {
        width: 140px
    }

    .logo-header {
        width: 140px;
        height: 70px
    }
}

@media only screen and (max-width:991px) {
    .middle-bar {
        display: none
    }

    .site-header.center .header-nav .nav {
        width: 100%;
        float: none
    }

    .site-header.center .navbar-nav>li {
        display: block;
        float: left;
        width: 100%
    }

    .header-nav .nav>li.has-mega-menu.demos .mega-menu {
        max-width: 100%
    }

    .header-nav .nav>li .mega-menu {
        border: 0
    }

    .site-header.center .is-fixed .header-nav .nav {
        height: auto
    }

    .header-nav .nav>li .mega-menu>li {
        padding: 10px 0
    }

    .header-nav .nav>li .mega-menu,
    .header-nav .nav>li .sub-menu {
        border-radius: 0
    }

    .header-nav .nav>li.active>a:after,
    .header-nav .nav>li:hover>a:after {
        content: none
    }

    .header-nav .nav>li .mega-menu>li ul a,
    .header-nav .nav>li .sub-menu li a {
        padding: 2px 0 1px 15px;
        display: block;
        font-size: 16px;
        line-height: 30px;
        position: relative
    }

    .header-nav .nav>li .mega-menu>li ul a:after,
    .header-nav .nav>li .sub-menu li a:after {
        content: "\f105";
        font-family: FontAwesome;
        width: auto;
        height: auto;
        background: 0 0 !important;
        top: 1px;
        color: #000;
        font-size: 16px;
        position: absolute;
        left: 0;
        font-weight: 700
    }

    .header-nav .nav>li .mega-menu:after,
    .header-nav .nav>li .sub-menu:after {
        content: ""
    }
}

@media only screen and (max-width:767px) {
    .header-nav .logo-header>a {
        width: 100%;
        display: block;
        text-align: center
    }
}

@media only screen and (max-width:767px) {
    .site-header.center .logo-header img {
        height: 60px;
        margin-bottom: -30px
    }

    .logo-header {
        height: 70px
    }
}

.shop-cart.navbar-right {
    margin-right: 0;
    float: none;
    display: inline-block
}

.shop-cart .dropdown-menu.cart-list {
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .3);
    top: 35px;
    border: 0;
    right: 0;
    left: auto;
    width: 300px;
    border-top: 3px solid var(--color-primary)
}

.shop-cart .badge {
    font-size: 9px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    padding: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;
    border-radius: 10px;
    color: #fff
}

.shop-cart li {
    padding: 10px 15px;
    border-bottom: 1px dashed rgba(0, 0, 0, .1)
}

.shop-cart li:last-child {
    border-bottom: 0
}

.shop-cart .media-left a {
    display: block;
    width: 60px
}

.shop-cart .site-button-link {
    position: relative
}

.extra-nav .site-button-link i,
.shop-cart .site-button-link i {
    margin-left: 0
}

.cart-list.dropdown-menu {
    width: 260px;
    margin-top: 30px
}

.is-fixed .cart-list.dropdown-menu {
    margin-top: 18px;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s
}

.cart-list .media-object {
    width: 60px
}

.cart-item .media-body {
    position: relative;
    padding-left: 10px
}

.item-close {
    background-color: #303030;
    border-radius: 20px;
    color: #fff;
    display: block;
    font-size: 9px;
    height: 18px;
    line-height: 18px;
    margin-top: -9px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    width: 18px;
    cursor: pointer
}

.item-close:hover {
    background-color: #000
}

.header-nav .nav>li.demos {
    position: inherit
}

.header-nav .nav>li.demos .mega-menu {
    max-width: 700px;
    width: 700px;
    left: auto;
    right: 0
}

.header-nav .nav>li .mega-menu>li.header-add {
    padding: 18px 14px
}

@media only screen and (max-width:991px) {
    header .logo {
        display: block;
        padding: 4px 0
    }

    .header-nav .nav>li.demos .mega-menu.left {
        width: 100%;
        max-width: 100%
    }
}

@media only screen and (max-width:767px) {
    .shop-cart .dropdown-menu.cart-list {
        left: auto;
        right: 0
    }

    .cart-list.dropdown-menu,
    .is-fixed .cart-list.dropdown-menu {
        margin-top: 0
    }
}

@media only screen and (max-width:991px) {
    .header-nav {
        clear: both;
        margin: 0 -15px;
        border-bottom: 1px solid #e9e9e9
    }

    .header-nav .nav {
        float: none;
        margin: 0;
        background: #fff;
        width: 100%;
        display: block
    }

    .header-nav .nav>li {
        float: none;
        display: block;
        width: 100%;
        font-family: Roboto;
        padding: 0 25px 0 25px
    }

    .header-nav .nav>li .mega-menu>li,
    .header-nav .nav>li .sub-menu>li {
        float: none;
        display: block;
        width: auto
    }

    .header-nav .nav>li>a {
        display: block;
        position: relative;
        border: 0;
        padding: 8px 0;
        font-size: 18px;
        line-height: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, .1)
    }

    .header-nav .nav>li .mega-menu>li:after {
        display: none
    }

    .header-nav .nav>li .mega-menu,
    .header-nav .nav>li .sub-menu,
    .header-nav .nav>li ul {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: #f6f6f6;
        box-shadow: none
    }

    .header-nav .nav>li ul.mega-menu ul {
        display: none
    }

    .header-nav .nav>li .sub-menu li>.sub-menu,
    .header-nav .nav>li:hover .mega-menu,
    .header-nav .nav>li:hover .sub-menu,
    .header-nav .nav>li:hover>ul {
        opacity: 1;
        visibility: visible;
        display: block;
        margin: 0
    }

    .header-nav .nav>li ul.mega-menu li:hover ul {
        display: block
    }

    .nav.navbar-nav li a i.fa-chevron-down:before,
    .side-nav .nav.navbar-nav li a i.fa-chevron-down:before {
        content: "\f105"
    }

    .nav.navbar-nav li.open a i.fa-chevron-down:before,
    .side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before {
        content: "\f107"
    }

    .header-nav .nav>li .mega-menu,
    .header-nav .nav>li .sub-menu,
    .header-nav .nav>li ul {
        background: 0 0
    }

    .header-nav .nav>li .mega-menu {
        padding: 10px 0;
        border-bottom: 1px solid rgba(0, 0, 0, .1)
    }

    .header-nav .nav>li>.sub-menu {
        border: 1px solid rgba(0, 0, 0, .1);
        padding: 10px 0;
        border-width: 0 0 1px 0
    }

    .header-nav .nav>li>.sub-menu .sub-menu li a {
        padding: 2px 0 1px 30px
    }

    .header-nav .nav>li>.sub-menu .sub-menu li a:hover {
        background-color: rgba(0, 0, 0, .1)
    }

    .header-nav .nav>li>.sub-menu .sub-menu li a:after {
        content: "\f111";
        font-family: FontAwesome;
        width: auto;
        height: auto;
        background: 0 0;
        top: 3px;
        color: #000;
        font-size: 5px;
        left: 15px;
        font-weight: 700
    }

    .nav.navbar-nav li a i.fa-angle-right,
    .nav.navbar-nav li a i.fa-chevron-down,
    .side-nav .nav.navbar-nav li a i.fa-chevron-down {
        background-color: var(--color-primary);
        color: #fff;
        height: 30px;
        line-height: 30px;
        right: 0;
        text-align: center;
        width: 30px;
        z-index: 3;
        float: right;
        font-size: 20px;
        margin: 0
    }

    .header-nav .nav>li .sub-menu li i.fa-angle-right:before {
        content: "\f105"
    }

    .header-nav .nav>li .sub-menu li.open i.fa-angle-right:before {
        content: "\f107"
    }

    .header-nav .nav>li .sub-menu .sub-menu,
    .header-nav .nav>li:hover .mega-menu,
    .header-nav .nav>li:hover .sub-menu,
    .header-nav .nav>li:hover .sub-menu .sub-menu {
        display: none;
        opacity: 1;
        margin-top: 0
    }

    .header-nav .nav li .sub-menu .sub-menu {
        display: none;
        opacity: 1;
        margin-top: 0
    }

    .header-nav .nav>li.open>.sub-menu .sub-menu {
        display: none
    }

    .header-nav .nav>li ul.mega-menu ul,
    .header-nav .nav>li.open>.mega-menu,
    .header-nav .nav>li.open>.sub-menu,
    .header-nav .nav>li.open>.sub-menu li.open .sub-menu {
        display: block;
        opacity: 1;
        margin-top: 0
    }

    .header-nav .nav>li.open>.sub-menu li.open>a>i,
    .header-nav .nav>li.open>a>i {
        background: #313444
    }

    .header-nav .nav>li.open .sub-menu li.open>a>i {
        background: #fff
    }

    .header-nav .nav>li .mega-menu>li {
        padding: 0
    }

    .header-nav .nav>li .mega-menu>li>a {
        display: none
    }

    .header-nav .nav .mega-menu a i {
        display: inline-block;
        float: none;
        margin-top: 0
    }

    .header-nav .nav .open>a,
    .header-nav .nav .open>a:focus,
    .header-nav .nav .open>a:hover {
        background-color: inherit;
        border-color: #e9e9e9
    }

    .header-nav .nav>li .mega-menu>li ul a:hover,
    .header-nav .nav>li .sub-menu li:hover>a {
        background-color: transparent;
        text-decoration: none
    }

    .header-nav.nav-dark .nav .open>a:focus,
    .header-nav.nav-dark .nav .open>a:hover,
    .header-nav.nav-dark.nav .open>a {
        border-color: rgba(0, 0, 0, .2)
    }

    .header-nav .nav>li .sub-menu li .fa {
        color: #000;
        display: block;
        float: right;
        font-size: 20px;
        opacity: 1;
        background: #fff
    }
}

.nav-dark.header-nav .nav>li .mega-menu,
.nav-dark.header-nav .nav>li .sub-menu {
    background-color: #232323;
    border: 1px solid rgba(0, 0, 0, .2)
}

.nav-dark.header-nav .nav>li .sub-menu li {
    border-bottom: 0 solid rgba(0, 0, 0, .2)
}

.nav-dark.header-nav .nav>li .mega-menu>li ul a,
.nav-dark.header-nav .nav>li .sub-menu li a {
    color: rgba(255, 255, 255, .6)
}

.header-nav.nav-dark .nav>li .mega-menu>li>a {
    color: #fff
}

.nav-dark.header-nav .nav>li .sub-menu li a:hover {
    background-color: rgba(255, 255, 255, 0)
}

.nav-dark.header-nav .nav>li .mega-menu>li ul a:hover,
.nav-dark.header-nav .nav>li .sub-menu li:hover>a,
.nav-dark.header-nav .nav>li ul li a:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #fff !important
}

.nav-dark.header-nav .nav>li .mega-menu>li:after {
    background-color: rgba(0, 0, 0, .2)
}

@media only screen and (max-width:991px) {
    .nav-dark.header-nav .nav>li>a {
        color: #9d9d9d
    }

    .nav-dark.header-nav {
        border-bottom: 1px solid rgba(0, 0, 0, .8)
    }

    .nav-dark.header-nav .nav {
        background-color: #202020
    }

    .nav-dark.header-nav .nav>li>a {
        border-top: 0 solid rgba(0, 0, 0, .2)
    }

    [class*=bg-] .nav-dark.header-nav .nav>li>a {
        color: #999
    }

    [class*=bg-] .nav-dark.header-nav .nav>li.active>a,
    [class*=bg-] .nav-dark.header-nav .nav>li:hover>a {
        color: #efbb20
    }

    .nav-dark.header-nav .nav>li>a:active,
    .nav-dark.header-nav .nav>li>a:focus,
    .nav-dark.header-nav .nav>li>a:hover {
        background-color: #282828;
        color: #efbb20
    }

    .nav-dark.header-nav .nav>li .mega-menu,
    .nav-dark.header-nav .nav>li .sub-menu,
    .nav-dark.header-nav .nav>li ul {
        background-color: #282828
    }
}

.sticky-no .main-bar {
    position: static !important
}

.is-fixed .main-bar {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, .1)
}

[class*=bg-] .header-nav .nav>li>a {
    color: #fff
}

[class*=bg-] .header-nav .nav>li.active>a,
[class*=bg-] .header-nav .nav>li.current-menu-item>a {
    color: #fff
}

[class*=bg-] .header-nav .nav>li:hover>a,
[class*=bg-] .header-nav .nav>li>a:hover {
    color: #fff
}

[class*=bg-] .navbar-toggler {
    border-color: #fff
}

[class*=bg-] .navbar-toggler span {
    background-color: #fff
}

@media only screen and (max-width:991px) {
    [class*=bg-] .header-nav .nav>li>a {
        color: #555
    }
}

.header.dark,
.header.dark .main-bar {
    background: #252525
}

.header.dark .dlab-topbar-left ul li a,
.header.dark .dlab-topbar-right ul li a,
.header.dark .extra-nav .site-button-link,
.header.dark .header-nav .nav>li>a,
.header.dark .navbar-toggler {
    color: #dfdfdf
}

.header.dark .navbar-toggler span {
    background-color: #dfdfdf
}

.header.dark .top-bar {
    background-color: #252525;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #dfdfdf
}

.header-transparent+.page-content .dlab-bnr-inr-md,
.header-transparent+.page-content .dlab-bnr-inr-sm {
    padding-top: 60px
}

.header-transparent {
    position: absolute;
    width: 100%
}

.header-transparent .main-bar {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, .05)
}

.header-transparent .header-nav .nav>li>a {
    color: #fff
}

.header-transparent .dlab-topbar-left ul li a,
.header-transparent .extra-nav .site-button-link,
.header-transparent .header-nav .nav>li>a,
.header-transparent .navbar-toggler {
    color: #fff
}

.header-transparent .navbar-toggler span {
    background-color: #fff
}

.header-transparent .is-fixed .main-bar {
    position: fixed;
    background-color: rgba(0, 0, 0, .9)
}

.header-transparent .top-bar {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #dfdfdf
}

.header-transparent.overlay .main-bar,
.header-transparent.overlay .top-bar {
    background-color: rgba(0, 0, 0, .5)
}

.fullwidth .container-fluid {
    padding-left: 30px;
    padding-right: 30px
}

@media only screen and (max-width:767px) {
    .fullwidth .container-fluid {
        padding-left: 15px;
        padding-right: 15px
    }
}

.header.primary,
.header.primary .main-bar {
    background: var(--color-primary)
}

.header.primary .extra-nav .site-button-link,
.header.primary .header-nav .nav>li>a,
.header.primary .navbar-toggler {
    color: #fff
}

.header.primary .navbar-toggler span {
    background-color: #fff
}

.header.primary .top-bar {
    background-color: var(--color-primary);
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #fff
}

.header.primary .header-nav .nav>li.active>a:after,
.header.primary .header-nav .nav>li:hover>a:after {
    background-color: #fff
}

.header.red,
.header.red .main-bar {
    background: #f22d4e
}

.header.red .extra-nav .site-button-link,
.header.red .header-nav .nav>li>a,
.header.red .navbar-toggler {
    color: #dfdfdf
}

.header.red .navbar-toggler span {
    background-color: #dfdfdf
}

.header.red .top-bar {
    background-color: #f22d4e;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #dfdfdf
}

.header.blue,
.header.blue .main-bar {
    background: #007fff
}

.header.blue .extra-nav .site-button-link,
.header.blue .header-nav .nav>li>a,
.header.blue .navbar-toggler {
    color: #fff
}

.header.blue .navbar-toggler span {
    background-color: #fff
}

.header.blue .top-bar {
    background-color: #007fff;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #fff
}

.header.purpal,
.header.purpal .main-bar {
    background: #8862b9
}

.header.purpal .extra-nav .site-button-link,
.header.purpal .header-nav .nav>li>a,
.header.purpal .navbar-toggler {
    color: #fff
}

.header.purpal .navbar-toggler span {
    background-color: #fff
}

.header.purpal .top-bar {
    background-color: #8862b9;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #fff
}

.header.yellow,
.header.yellow .main-bar {
    background: #ffb822
}

.header.yellow .extra-nav .site-button-link,
.header.yellow .header-nav .nav>li>a,
.header.yellow .navbar-toggler {
    color: #fff
}

.header.yellow .navbar-toggler span {
    background-color: #fff
}

.header.yellow .top-bar {
    background-color: #ffb822;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    color: #fff
}

.top-bar.bg-dark {
    background-color: #282a3c;
    color: #fff
}

.top-bar.bg-black {
    background-color: #000;
    color: #fff
}

.top-bar.bg-light {
    background-color: #a5a5a5;
    color: #fff;
    border-bottom: 0
}

.header.box {
    position: absolute;
    width: 100%
}

.header.box .main-bar {
    background-color: rgba(0, 0, 0, 0);
    margin-top: 20px;
    transition: all .2s;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s
}

.header.box .extra-nav .site-button-link,
.header.box .header-nav .nav>li>a,
.header.box .navbar-toggler {
    color: #000
}

.header.box .navbar-toggler span {
    background-color: #000
}

.header.box .is-fixed .main-bar {
    position: fixed;
    background-color: #fff;
    margin-top: 0;
    transition: all .2s;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s
}

.header.box .container {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 0 4px -2px #000
}

.header.box .is-fixed .container {
    box-shadow: none
}

.header.box.overlay .extra-nav .site-button-link,
.header.box.overlay .header-nav .nav>li>a,
.header.box.overlay .navbar-toggler {
    color: #fff
}

.header.box.overlay .navbar-toggler span {
    background-color: #fff
}

.header.box.overlay .container {
    background-color: rgba(0, 0, 0, .5)
}

.header.box.overlay .is-fixed .container {
    background-color: rgba(0, 0, 0, 0)
}

.header.box.overlay .is-fixed .main-bar {
    background-color: rgba(0, 0, 0, .7)
}

.header.box.dark .extra-nav .site-button-link,
.header.box.dark .header-nav .nav>li>a,
.header.box.dark .navbar-toggler {
    color: #fff
}

.header.box.dark .navbar-toggler span {
    background-color: #fff
}

.header.box.dark .container {
    background-color: #252525
}

.header.box.dark,
.header.box.dark .is-fixed .container {
    background-color: rgba(0, 0, 0, 0)
}

.header.box.dark .is-fixed .main-bar {
    background-color: #252525
}

.onepage .navbar {
    margin-bottom: 0;
    border: 0
}

@media only screen and (max-width:991px) {

    .header-curve [class*=bg-] .header-nav .nav>li.active>a,
    .header-transparent .header-nav .nav>li>a,
    .header.blue .header-nav .nav>li>a,
    .header.box.dark .header-nav .nav>li>a,
    .header.box.overlay .header-nav .nav>li>a,
    .header.dark .header-nav .nav>li>a,
    .header.primary .header-nav .nav>li>a,
    .header.purpal .header-nav .nav>li>a,
    .header.red .header-nav .nav>li>a,
    .header.yellow .header-nav .nav>li>a {
        color: #000
    }

    .header-curve [class*=bg-] .header-nav.nav-dark .nav>li.active>a,
    .header-transparent .header-nav.nav-dark .nav>li>a,
    .header.blue .header-nav.nav-dark .nav>li>a,
    .header.box.dark .header-nav.nav-dark .nav>li>a,
    .header.box.overlay .header-nav.nav-dark .nav>li>a,
    .header.dark .header-nav.nav-dark .nav>li>a,
    .header.primary .header-nav.nav-dark .nav>li>a,
    .header.purpal .header-nav.nav-dark .nav>li>a,
    .header.red .header-nav.nav-dark .nav>li>a,
    .header.yellow .header-nav.nav-dark .nav>li>a {
        color: #fff
    }
}

.dlab-banner-outer,
.dlab-banner-row {
    position: relative
}

.dlab-bnr-pic {
    width: 100%;
    overflow: hidden
}

.dlab-bnr-pic img {
    width: 100%;
    height: auto;
    display: block
}

.dlab-bnr-text {
    position: absolute;
    left: 0;
    top: 35%;
    width: 100%;
    margin: 0;
    color: #fff
}

.dlab-bnr-text-has {
    max-width: 600px
}

.dlab-bnr-text span,
.dlab-bnr-text strong {
    text-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    -webkit-text-shadow: 0 1px 2px rgba(0, 0, 0, .3)
}

.dlab-bnr-text strong {
    display: block;
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;
    margin: 0 0 25px;
    color: #fff
}

.dlab-bnr-text span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    display: block;
    margin-bottom: 30px
}

.dlab-bnr-text .btn {
    margin-right: 30px
}

@media only screen and (max-width:1200px) {
    .dlab-bnr-text strong {
        font-size: 60px;
        line-height: 60px;
        margin: 0 0 15px
    }

    .dlab-bnr-text span {
        font-size: 20px;
        line-height: 24px
    }
}

@media only screen and (max-width:991px) {
    .dlab-bnr-text strong {
        font-size: 50px;
        line-height: 50px;
        margin: 0 0 10px
    }
}

@media only screen and (max-width:767px) {
    .dlab-bnr-text strong {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 0
    }
}

@media only screen and (max-width:680px) {
    .dlab-bnr-text strong {
        font-size: 30px;
        line-height: 30px;
        margin: 0 0 0
    }

    .dlab-bnr-text span {
        font-size: 14px;
        line-height: 18px
    }
}

@media only screen and (max-width:480px) {
    .dlab-bnr-text strong {
        font-size: 20px;
        line-height: 20px
    }

    .dlab-bnr-text span {
        font-size: 12px;
        line-height: 16px
    }
}

.dlab-bnr-inr.dlab-bnr-inr-lg,
.dlab-bnr-inr.dlab-bnr-inr-lg .dlab-bnr-inr-entry {
    height: 800px
}

.dlab-bnr-inr.dlab-bnr-inr-md,
.dlab-bnr-inr.dlab-bnr-inr-md .dlab-bnr-inr-entry {
    height: 600px
}

.dlab-bnr-inr.dlab-bnr-inr-sm,
.dlab-bnr-inr.dlab-bnr-inr-sm .dlab-bnr-inr-entry {
    height: 450px
}

.dlab-bnr-inr,
.dlab-bnr-inr .dlab-bnr-inr-entry {
    height: 220px
}

.dlab-bnr-inr {
    height: 220px;
    background-size: cover;
    background-position: center center;
    display: table;
    width: 100%;
    text-align: center
}

.dlab-bnr-inr .container {
    display: table;
    height: 100%
}

.dlab-bnr-inr-entry {
    display: table-cell;
    vertical-align: middle
}

.dlab-bnr-inr-entry.align-m {
    vertical-align: middle
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
    color: #fff
}

.dlab-bnr-inr.banner-content h1 {
    font-weight: 500
}

.dlab-bnr-inr.banner-content p {
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 36px;
    max-width: 750px;
    display: inline-block
}

.dlab-bnr-inr.banner-content .site-button {
    padding: 13px 35px;
    font-size: 16px
}

.dlab-bnr-inr .breadcrumb-row {
    padding: 0;
    background-color: rgba(0, 0, 0, 0)
}

.dlab-bnr-inr h1 {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px
}

.next-element {
    color: #fff;
    float: right;
    opacity: .1
}

.next-element:hover {
    color: #fff;
    opacity: .8
}

.dlab-bnr-inr .breadcrumb-row ul li:after,
.dlab-bnr-inr .breadcrumb-row ul li:last-child {
    color: #fff
}

.dlab-bnr-inr .breadcrumb-row ul li a {
    color: #fff
}

@media only screen and (max-width:767px) {

    .ele-bnr .bnr-title,
    .ele-bnr .breadcrumb-row h5,
    .ele-bnr h1 a {
        display: none
    }

    .ele-bnr .dlab-bnr-inr-entry {
        text-align: center
    }

    .dlab-bnr-inr-entry h1 {
        margin-bottom: 10px
    }

    .dlab-bnr-inr {
        padding-bottom: 0
    }

    .dlab-bnr-inr .breadcrumb-row ul {
        display: block;
        float: none;
        width: 100%;
        text-align: center
    }

    .dlab-bnr-inr .breadcrumb-row ul li {
        font-size: 14px
    }

    .dlab-bnr-inr .dlab-bnr-inr {
        height: 200px
    }

    .dlab-bnr-inr h1 {
        line-height: 32px;
        font-size: 24px;
        text-align: center
    }

    .dlab-bnr-inr.dlab-bnr-inr-lg,
    .dlab-bnr-inr.dlab-bnr-inr-lg .dlab-bnr-inr-entry {
        height: 400px
    }

    .dlab-bnr-inr.dlab-bnr-inr-md,
    .dlab-bnr-inr.dlab-bnr-inr-md .dlab-bnr-inr-entry {
        height: 300px
    }

    .dlab-bnr-inr.dlab-bnr-inr-sm,
    .dlab-bnr-inr.dlab-bnr-inr-sm .dlab-bnr-inr-entry {
        height: 220px
    }

    .dlab-bnr-inr,
    .dlab-bnr-inr .dlab-bnr-inr-entry {
        height: 180px
    }

    .dlab-bnr-inr-entry {
        display: table-cell;
        padding-top: 15px;
        padding-bottom: 15px;
        vertical-align: middle
    }
}

.navicon {
    width: 20px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer
}

.navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out
}

.navicon span:nth-child(1) {
    top: 0
}

.navicon span:nth-child(2) {
    top: 7px
}

.navicon span:nth-child(3) {
    top: 14px
}

.navicon.open span:nth-child(1) {
    top: 7px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg)
}

.navicon.open span:nth-child(2) {
    opacity: 0;
    left: -60px
}

.navicon.open span:nth-child(3) {
    top: 7px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg)
}

.tp-caption .font-weight-300 {
    font-weight: 300
}

.tp-caption .font-weight-400 {
    font-weight: 400
}

.tp-caption .font-weight-500 {
    font-weight: 500
}

.tp-caption .font-weight-600 {
    font-weight: 600
}

.tp-caption .font-weight-700 {
    font-weight: 700
}

.tp-caption .font-weight-800 {
    font-weight: 800
}

.tp-caption .font-weight-900 {
    font-weight: 900
}

.tp-caption h1,
.tp-caption h2,
.tp-caption h3,
.tp-caption h4,
.tp-caption h5,
.tp-caption h6 {
    font-weight: 800;
    margin-bottom: 24px;
    color: #fff
}

.tp-caption h1 {
    font-size: 70px;
    line-height: 60px
}

.tp-caption h2 {
    font-size: 60px;
    line-height: 50px
}

.tp-caption h3 {
    font-size: 50px;
    line-height: 40px;
    margin-bottom: 20px
}

.tp-caption h4 {
    font-size: 40px;
    line-height: 30px;
    margin-bottom: 15px
}

.tp-caption h5 {
    font-size: 30px;
    line-height: 4px;
    margin-bottom: 10px
}

.tp-caption h6 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px
}

.tp-caption h4,
.tp-caption h5,
.tp-caption h6 {
    font-weight: 600
}

.tp-caption .text {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    width: 500px
}

.overlay-row {
    background: #000 none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: .3;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
}

.owl-none .owl-nav {
    display: none
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
    margin-top: 20px;
    text-align: center
}

.owl-theme .owl-nav .disabled {
    opacity: .5;
    cursor: default
}

.owl-theme .owl-dots {
    text-align: center
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity .2s ease;
    -moz-transition: opacity .2s ease;
    -ms-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791
}

.owl-theme.dots-style-1 .owl-dots .owl-dot span,
.owl-theme.dots-style-2 .owl-dots .owl-dot span {
    background: rgba(0, 0, 0, .2);
    width: 10px;
    height: 10px;
    margin: 5px
}

.owl-theme.dots-style-1 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-1 .owl-dots .owl-dot:hover span {
    background: #000
}

.owl-theme.dots-style-3 .owl-dots {
    margin-top: 30px;
    position: unset;
    right: auto;
    text-align: center;
    top: auto;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: auto
}

.owl-theme.dots-style-3 .owl-dots .owl-dot span {
    height: 8px;
    width: 8px;
    margin: 0
}

.owl-theme.dots-style-3 .owl-dots .owl-dot {
    border: 1px solid;
    border-radius: 20px;
    display: inline-block;
    margin: 4px;
    padding: 3px
}

.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover {
    border: 1px solid #ff3b68
}

.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span {
    background-color: #ff3b68
}

.owl-item.dots-style-3 .slide-content-box {
    opacity: 0
}

.owl-item.active.dots-style-3 .slide-content-box {
    opacity: 1;
    transition: all 2s ease 2s;
    -moz-transition: all 2s ease 2s;
    -webkit-transition: all 2s ease 2s;
    -o-transition: all 2s ease 2s
}

.owl-btn-top-c .owl-nav {
    text-align: center
}

.owl-btn-top-l .owl-nav {
    text-align: left
}

.owl-btn-top-l .owl-nav .owl-prev {
    margin-left: 0
}

.owl-btn-top-r .owl-nav {
    text-align: right
}

.owl-btn-top-r .owl-nav .owl-next {
    margin-right: 0
}

.owl-btn-top-lr .owl-nav {
    text-align: left
}

.owl-btn-top-lr .owl-nav .owl-next {
    margin-right: 0;
    float: right
}

.owl-btn-full .owl-next,
.owl-btn-full .owl-prev {
    padding: 40px 8px
}

.owl-btn-center-lr .owl-nav {
    margin: 0
}

.owl-btn-center-lr+.owl-theme .owl-nav {
    margin: 0
}

.owl-btn-center-lr .owl-nav .owl-next,
.owl-btn-center-lr .owl-nav .owl-prev {
    position: absolute;
    left: 0;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
}

.owl-btn-center-lr .owl-nav .owl-next {
    left: auto;
    right: 0
}

.owl-carousel .owl-item img {
    transform-style: inherit
}

.owl-btn-1 .owl-next,
.owl-btn-1 .owl-prev,
.owl-btn-2 .owl-next,
.owl-btn-2 .owl-prev,
.owl-btn-3 .owl-next,
.owl-btn-3 .owl-prev {
    border-radius: 3px;
    height: 40px;
    line-height: 40px;
    margin: 0 10px !important;
    padding: 0;
    text-align: center;
    width: 40px
}

.owl-btn-2 .owl-next,
.owl-btn-2 .owl-prev,
.owl-btn-3 .owl-next,
.owl-btn-3 .owl-prev {
    margin: 0 -70px !important
}

.owl-btn-3 .owl-next,
.owl-btn-3 .owl-prev {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    font-size: 35px;
    height: auto;
    width: auto;
    opacity: .2
}

.owl-btn-3 .owl-next:hover,
.owl-btn-3 .owl-prev:hover {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    opacity: 1
}

.owl-btn-3.btn-white .owl-next,
.owl-btn-3.btn-white .owl-prev {
    color: #fff
}

.owl-btn-3.btn-white .owl-next:hover,
.owl-btn-3.btn-white .owl-prev:hover {
    color: #fff
}

.owl-btn-4 {
    overflow: hidden
}

.owl-btn-4 .owl-next,
.owl-btn-4 .owl-prev {
    background: #fff none repeat scroll 0 0;
    border-radius: 30px;
    box-shadow: 0 0 20px 7px rgba(0, 0, 0, .5);
    color: #000;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    margin: 0 -10px !important;
    overflow: hidden;
    padding: 0;
    width: 50px;
    opacity: .7
}

.owl-btn-4 .owl-next:hover,
.owl-btn-4 .owl-prev:hover {
    background: #fff none repeat scroll 0 0;
    color: #000;
    opacity: 1
}

#sync2 .owl-item.active.current .item img {
    outline: 5px solid rgba(0, 0, 0, .2);
    outline-offset: -9px
}

.owl-dots-none .owl-dots {
    display: none
}

.owl-dots-black-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot span {
    opacity: .5;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    margin: 4px
}

.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span {
    background-color: var(--color-primary)
}

.owl-dots-black-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-full.owl-theme .owl-dots .owl-dot span {
    background-color: #333
}

.owl-dots-white-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot span {
    background-color: #f5f5f5
}

.owl-dots-black-full.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot.active span {
    opacity: 1;
    width: 25px
}

.owl-dots-black-big.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot.active span {
    width: 14px;
    height: 14px;
    opacity: 1;
    margin: 0 4px 2px 4px
}

@media only screen and (max-width:1200px) {

    .owl-btn-2 .owl-next,
    .owl-btn-2 .owl-prev,
    .owl-btn-3 .owl-next,
    .owl-btn-3 .owl-prev {
        margin: 0 10px !important
    }
}

.blog-post {
    position: relative;
    margin-bottom: 30px
}

.dlab-post-title {
    margin-bottom: 10px
}

.dlab-post-title .post-title {
    margin-top: 10px;
    margin-bottom: 10px
}

.dlab-post-meta {
    margin-bottom: 15px
}

.dlab-post-meta ul {
    margin: 0;
    list-style: none;
    text-transform: capitalize;
    line-height: 1
}

.dlab-post-meta ul li {
    padding: 0;
    display: inline-block;
    color: #9a9999;
    font-size: 14px
}

.dlab-post-meta ul li strong {
    font-weight: inherit
}

.dlab-post-meta li:after {
    content: "|";
    display: inline-block;
    font-weight: 400;
    margin-left: 5px;
    margin-right: 5px;
    opacity: .5
}

.dlab-post-meta li:last-child:after {
    display: none
}

.dlab-post-meta a {
    color: inherit
}

.dlab-post-meta i {
    color: inherit;
    margin-right: 4px;
    vertical-align: middle;
    font-weight: 500;
    transform: scale(1.3);
    line-height: .8
}

.text-white .dlab-post-meta a,
.text-white .dlab-post-meta i,
.text-white .dlab-post-meta ul li {
    color: #fff
}

.dlab-post-meta.text-white i,
.dlab-post-meta.text-white ul li,
.dlab-post-meta.text-white ul li a {
    color: #fff
}

.dlab-post-meta.text-black i,
.dlab-post-meta.text-black ul li,
.dlab-post-meta.text-black ul li a {
    color: #000
}

.dlab-post-meta.text-gray i,
.dlab-post-meta.text-gray ul li,
.dlab-post-meta.text-gray ul li a {
    color: grey
}

.dlab-post-text {
    margin-bottom: 15px
}

.dlab-post-text p {
    line-height: 1.5;
    font-weight: 400
}

.dlab-post-text p:last-child {
    margin: 0
}

.dlab-post-readmore {
    margin-bottom: 15px
}

.dlab-post-tags {
    border-top: 1px solid #e9e9e9;
    padding-top: 10px
}

.dlab-post-tags .post-tags {
    margin: 0 -3px;
    list-style: none
}

.dlab-post-tags .post-tags a {
    border: 1px solid #ebedf2;
    padding: 2px 8px;
    color: #a9a9a9;
    margin: 0 3px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600
}

.dlab-info[class*=border-] .post-title {
    margin-top: 0
}

.text-white .post-title a {
    color: #fff
}

.dlab-info>div:last-child,
.dlab-info>p:last-child {
    margin-bottom: 0
}

@media only screen and (max-width:1200px) {
    .dlab-post-meta .d-flex {
        display: block !important
    }
}

.date-style-1 .post-date {
    color: #fff;
    background-color: #efbb20;
    padding: 3px 5px 3px 5px
}

.date-style-1 .post-date i {
    color: #fff;
    left: 5px;
    top: 5px
}

.date-style-1 .post-date i,
.date-style-2 .post-date i,
.date-style-3 .post-date i,
.date-style-4 .post-date i {
    display: none
}

.date-style-2 .post-date,
.date-style-4 .post-date {
    color: #fff;
    position: absolute;
    left: 20px;
    top: 0;
    width: 50px;
    text-align: center;
    text-transform: uppercase
}

.date-style-2 .post-date span,
.date-style-2 .post-date strong,
.date-style-4 .post-date span,
.date-style-4 .post-date strong {
    display: block;
    padding: 10px 5px
}

.date-style-2 .post-date strong,
.date-style-4 .post-date strong {
    font-size: 10px;
    padding: 10px 5px 5px;
    position: relative
}

.date-style-2 .post-date>strong:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    background-color: #fff;
    margin: 0 -10px;
    width: 20px
}

.date-style-2 .post-date span {
    font-size: 16px;
    padding: 5px 5px 10px
}

.date-style-2 .post-date .fa,
.date-style-2 .post-date:after,
.date-style-3 .post-date .fa,
.date-style-3 .post-date:after,
.date-style-4 .post-date .fa {
    display: none
}

.date-style-3 .dlab-post-info {
    position: relative
}

.date-style-3 .post-date {
    font-family: oswald;
    text-transform: uppercase;
    position: absolute;
    padding: 8px 15px;
    color: #fff;
    top: -36px;
    left: 0;
    height: 36px
}

.date-style-3 .post-date strong {
    color: #30353c;
    margin-right: 2px
}

.date-style-3.skew-date .post-date {
    padding-right: 8px
}

.date-style-3.skew-date .post-date:before {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    width: 22px;
    height: 100%;
    border-right: 3px solid #2d3239;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg)
}

.date-style-4 .post-date span {
    background-color: #2e1e13
}

.date-style-4 .post-date {
    top: 10px;
    left: 10px
}

.date-style-4 .dlab-post-info {
    box-shadow: 0 3px 0 0 #dbdbdb
}

.date-style-4 .dlab-post-meta ul li {
    font-weight: 400
}

.no-image-blog.date-style-2 .algo-post-info {
    padding-top: 70px
}

.no-image-blog.date-style-2 .post-date {
    top: 0;
    left: 20px
}

.blog-post.no-img-post .post-date {
    position: static;
    float: left
}

.blog-post.blog-md {
    display: flex
}

.blog-post.blog-md .dlab-post-media img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.blog-md .dlab-post-media {
    width: 350px;
    max-width: 350px;
    min-width: 350px;
    border-radius: 4px
}

.blog-md .dlab-post-info {
    border: none;
    padding: 30px
}

.blog-md .dlab-post-tags {
    border: none;
    display: inline-block;
    padding: 0
}

.blog-md .dlab-post-info .post-title {
    margin-top: 0
}

.blog-md,
.blog-md .dlab-post-info {
    overflow: hidden
}

.blog-md .dlab-post-info,
.blog-md .dlab-post-media {
    display: table-cell;
    vertical-align: middle
}

.blog-md .dlab-post-media {
    vertical-align: top
}

.blog-md .dlab-post-info>div:last-child {
    margin-bottom: 0
}

.blog-lg .dlab-post-media {
    border-radius: 4px
}

.blog-rounded [class*=border-] {
    border-radius: 0 0 4px 4px
}

.blog-effect1 {
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s
}

.blog-effect1 .dlab-info {
    background: #fff
}

.blog-effect1:hover {
    border-radius: 4px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 5px 40px rgba(0, 0, 0, .05);
    -webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, .05);
    -o-box-shadow: 0 5px 40px rgba(0, 0, 0, .05);
    -ms-box-shadow: 0 5px 40px rgba(0, 0, 0, .05)
}

.blog-share {
    position: relative
}

.share-btn {
    position: absolute;
    right: 0;
    bottom: 0
}

.share-btn ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.share-btn ul li {
    display: inline-block;
    margin-left: -40px;
    float: left;
    transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s
}

.share-btn ul li a.site-button {
    border-radius: 3px;
    width: 40px;
    line-height: 44px;
    height: 44px;
    display: block;
    color: #000;
    background: #e6e6e6
}

.share-btn ul li a.site-button:hover {
    background: #d6d6d6
}

.share-btn ul:hover li.share-button a.site-button {
    background: #a0a0a0
}

.share-btn ul:hover li {
    margin-left: 4px
}

@media only screen and (max-width:767px) {

    .blog-md.blog-post .dlab-pfost-media,
    .blog-md.blog-post .dlab-post-info {
        float: none;
        margin: 0 0 20px;
        width: 100%;
        display: block;
        padding-left: 0
    }

    .blog-md.blog-post .dlab-post-info {
        margin-top: 15px
    }

    .blog-md .dlab-post-media {
        width: 100%;
        max-width: 100%;
        min-width: 100%
    }
}

.nav-links:after {
    clear: both;
    content: "";
    display: table
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px
}

.pagination .page-numbers {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    color: #767676;
    padding: 9px 15px;
    font-weight: 600;
    font-size: 12px;
    display: block;
    float: left;
    margin-left: -1px
}

.pagination .page-numbers.current,
.pagination a.page-numbers:hover {
    background: #3396d1;
    color: #fff
}

.pagination i {
    font-size: 12px
}

.pagination li.active a {
    background-color: var(--color-primary);
    color: #fff
}

.nav-links .nav-next a,
.nav-links .nav-previous a {
    display: inline-block;
    color: #3396d1;
    font-weight: 600;
    margin-bottom: 20px;
    outline: 0 !important
}

.nav-links .nav-next a:hover,
.nav-links .nav-previous a:hover {
    color: #09c
}

.nav-links .nav-next a:after,
.nav-links .nav-previous a:after {
    content: "\f105";
    font-family: FontAwesome;
    vertical-align: middle;
    margin-left: 2px;
    color: #333
}

.post-navigation {
    background-color: #fff;
    padding: 30px;
    border-bottom: 2px solid #e3e3e3
}

.post-navigation .screen-reader-text {
    margin-top: 0
}

.post-navigation .nav-next,
.post-navigation .nav-previous {
    display: inline-block;
    width: 50%
}

.post-navigation .nav-next {
    text-align: right
}

.post-navigation .nav-next a:after,
.post-navigation .nav-previous a:after {
    display: none
}

.post-navigation .nav-next .post-title,
.post-navigation .nav-previous .post-title {
    display: block;
    font-size: 15px;
    color: #777;
    margin-top: 10px
}

.dlab-link-pages {
    padding: 20px 0 30px
}

.dlab-link-pages a {
    color: #fff;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 600;
    background-color: #ccc;
    margin: 0 2px
}

.sticky-post {
    position: absolute;
    right: 0;
    top: 0;
    background: #77c04b;
    color: #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 600
}

.post-password-form {
    position: relative;
    clear: both
}

.post-password-form input[type=password] {
    width: 100%;
    border: 1px solid #ebedf2;
    padding: 10px 100px 10px 15px;
    height: 40px
}

.post-password-form label {
    display: block
}

.post-password-form input[type=submit] {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    height: 40px;
    text-transform: uppercase;
    font-weight: 600
}

.blog [class*=galleryid-] {
    clear: both;
    margin: 0 auto;
    overflow: hidden
}

.gallery .gallery-item {
    float: left;
    list-style: none;
    margin: 0 0 0;
    overflow: hidden;
    padding: 0 0 5px 15px;
    text-align: center
}

.gallery .gallery-caption {
    padding-top: 10px;
    font-size: 13px
}

.gallery .gallery-item img {
    float: left;
    padding: 0 0;
    width: 100%;
    border: none !important
}

.gallery-columns-2 .gallery-item {
    max-width: 50%
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%
}

.gallery-columns-4 .gallery-item {
    max-width: 25%
}

.gallery-columns-5 .gallery-item {
    max-width: 20%
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%
}

.gallery-icon img {
    margin: 0 auto
}

.gallery-caption {
    color: #707070;
    color: rgba(51, 51, 51, .7);
    display: block;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.5;
    padding: .5em 0
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
    display: none
}

.side-bar .widget {
    margin-bottom: 30px
}

@media only screen and (max-width:991px) {
    .side-bar {
        margin-bottom: 40px
    }
}

.blog-single .dlab-post-meta {
    margin-bottom: 20px
}

.blog-single .dlab-post-text {
    margin-top: 20px
}

.blog-single .dlab-post-tags {
    margin-top: 20px
}

.blog-single .dlab-post-media {
    border-radius: 4px
}

.comments-area {
    padding: 0;
    margin-bottom: 30px
}

.comments-area .comments-title {
    text-transform: uppercase;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    line-height: 26px
}

ol.comment-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0
}

ol.comment-list li.comment {
    position: relative;
    padding: 0
}

ol.comment-list li.comment .comment-body {
    position: relative;
    padding: 0;
    padding-bottom: 25px;
    position: relative;
    margin-bottom: 25px;
    border-bottom: 1px solid #e1e1e1
}

ol.comment-list li.comment .comment-body:after {
    border-color: transparent #e9e9e9 transparent #e9e9e9;
    border-width: 12px 14px 12px 0;
    left: -14px
}

ol.comment-list li.comment .comment-author {
    display: block;
    margin-bottom: 20px;
    position: relative;
    padding-left: 75px;
    padding-top: 5px
}

ol.comment-list li.comment .comment-author .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    -webkit-border-radius: 55px
}

ol.comment-list li.comment .comment-author .fn {
    display: block;
    color: #000;
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 2px
}

ol.comment-list li.comment .comment-author .says {
    display: none;
    color: #999;
    font-weight: 600
}

ol.comment-list li.comment .comment-meta {
    text-transform: capitalize;
    display: block;
    font-size: 13px;
    margin-bottom: 5px
}

ol.comment-list li.comment .comment-meta a {
    color: #9fa1a4;
    font-size: 13px;
    font-weight: 400
}

ol.comment-list li.comment .reply {
    position: absolute;
    right: 0;
    top: 5px
}

ol.comment-list li.comment p {
    line-height: 24px;
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 400
}

ol.comment-list li.comment .reply a {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 14px;
    font-style: italic;
    box-shadow: inset 0 -1px 0 var(--bg-color)
}

ol.comment-list li .children {
    list-style: none;
    margin-left: 55px;
    padding-left: 0
}

ol.comment-list li .children li {
    padding: 0
}

@media only screen and (max-width:767px) {
    .comments-area .padding-30 {
        padding: 15px
    }

    ol.comment-list li .children {
        margin-left: 20px
    }

    ol.comment-list li.comment .reply a {
        position: static
    }
}

@media only screen and (max-width:480px) {
    ol.comment-list li.comment .comment-author {
        padding-left: 70px
    }
}

.comment-respond .comment-reply-title {
    text-transform: uppercase;
    font-size: 20px
}

.comment-respond .comment-reply-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 10px
}

.comment-respond .comment-reply-title small {
    font-size: inherit;
    font-weight: inherit
}

.comment-respond .comment-reply-title a {
    color: red
}

.comment-respond .comment-reply-title a:hover {
    text-decoration: underline
}

.comments-area .comment-form {
    background: #f6f6f6;
    padding: 30px 20px;
    border: 1px solid rgba(0, 0, 0, .1)
}

.comments-area .comment-list .comment-form {
    margin-bottom: 40px
}

.comments-area .comment-form .comment-notes {
    display: none
}

.comments-area .comment-form p {
    width: 50%;
    float: left;
    padding: 0 10px;
    margin-bottom: 20px;
    position: relative
}

.comments-area .comment-form p.form-allowed-tags {
    width: 100%
}

ol.comment-list li.comment .comment-respond .comment-form p {
    padding: 0 15px !important
}

.comments-area .comment-form p label {
    display: none;
    line-height: 18px;
    margin-bottom: 10px
}

.comments-area .comment-form p input[type=email],
.comments-area .comment-form p input[type=text],
.comments-area .comment-form p textarea {
    width: 100%;
    height: 45px;
    padding: 10px 20px 10px 20px;
    border: 0;
    text-transform: capitalize;
    font-size: 15px
}

.comments-area .comment-form p input[type=text]:focus,
.comments-area .comment-form p textarea:focus {
    outline: 0;
    border-color: var(--bg-color)
}

.comments-area .comment-form p.comment-form-comment {
    width: 100%;
    display: block;
    clear: both
}

.comments-area .comment-form p textarea {
    height: 120px
}

.comments-area .comment-form p.form-submit {
    clear: both;
    float: none;
    width: 100%;
    margin: 0
}

.comments-area .comment-form p input[type=submit]:active,
.comments-area .comment-form p input[type=submit]:focus,
.comments-area .comment-form p input[type=submit]:hover {
    background-color: #6ab33e;
    color: #fff
}

@media only screen and (max-width:767px) {
    .comments-area .comment-form p {
        width: 100%;
        float: none;
        margin-bottom: 20px
    }

    .comment-respond {
        padding: 20px
    }
}

.list-2 {
    display: table
}

.list-2 li {
    float: left;
    padding: 5px 5px 5px 20px;
    width: 50%;
    position: relative
}

.list-2 li:before {
    content: "\e60a";
    font-family: themify;
    font-size: 11px;
    left: 0;
    position: absolute
}

.list-2 li:hover:before {
    color: #1abc9c
}

.widget {
    margin-bottom: 60px
}

.widget.bg-white {
    border: 1px solid #ebedf2
}

.widget.border-0 ul li {
    border: 0 none
}

.widget-title a,
.widget-title a:hover {
    color: #fff
}

.widget-title.style-1 {
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-weight: 600;
    line-height: 12px;
    position: relative
}

.widget-title.style-1:after {
    border-radius: 0;
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
    vertical-align: bottom;
    width: 50px
}

.category-entry ul,
.recent-posts-entry ul {
    margin: 0;
    list-style: none;
    padding: 0;
    line-height: 1
}

.recent-overlay-post .widget-post {
    position: relative;
    z-index: 1
}

.recent-overlay-post .widget-post:after {
    content: "";
    position: absolute;
    width: 100%;
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    background: #0f1012;
    opacity: .6;
    z-index: 1
}

.recent-overlay-post.recent-posts-entry .dlab-media {
    position: absolute;
    width: 100%;
    height: 100%
}

.recent-overlay-post .widget-post-bx .dlab-info {
    padding: 20px;
    z-index: 2;
    position: relative
}

.recent-overlay-post .dlab-info .dlab-title a {
    color: #fff
}

.recent-overlay-post .dlab-info .dlab-meta ul li,
.recent-overlay-post.recent-posts-entry .widget-post .dlab-meta ul li {
    color: #fff
}

.widget-link ul {
    margin: 0;
    padding: 0
}

.widget-link ul li {
    list-style: none;
    display: inline-block;
    padding-left: 3px;
    padding-right: 10px;
    position: relative
}

.widget-link ul li:last-child {
    padding-right: 3px
}

.widget-link ul li:last-child:after {
    content: none
}

.widget-link li:after {
    content: "/";
    position: absolute;
    right: 0
}

.widget_archive ul,
.widget_categories ul,
.widget_getintuch ul,
.widget_meta ul,
.widget_nav_menu ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_recent_entries ul,
.widget_rss ul,
.widget_services ul,
.wp-block-archives-list,
.wp-block-categories-list,
.wp-block-latest-posts {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.widget_archive ul li,
.widget_categories ul li,
.widget_meta ul li,
.widget_nav_menu li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_recent_entries ul li,
.widget_services ul li,
.wp-block-archives-list li,
.wp-block-categories-list li,
.wp-block-latest-posts li {
    padding-bottom: 18px;
    margin-bottom: 13px;
    position: relative;
    padding: 10px 0 10px 15px;
    margin-bottom: 0;
    line-height: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.widget_archive ul li a,
.widget_categories ul li a,
.widget_meta ul li a,
.widget_nav_menu li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a,
.wp-block-archives-list li a,
.wp-block-categories-list li a,
.wp-block-latest-posts li a {
    color: #6f6f6f;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500
}

.widget_archive ul li a:hover,
.widget_categories ul li a:hover,
.widget_meta ul li a:hover,
.widget_nav_menu li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover,
.wp-block-archives-list li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-latest-posts li a:hover {
    color: var(--bg-color)
}

.widget_archive ul li:before,
.widget_categories ul li:after,
.widget_meta ul li:before,
.widget_nav_menu ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_recent_entries ul li:before,
.widget_services ul li:before,
.wp-block-archives-list li:after,
.wp-block-categories-list li:after,
.wp-block-latest-posts li:after {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    display: block;
    width: 5px;
    height: 5px;
    background: var(--color-primary);
    top: 17px
}

.widget_archive ul li li,
.widget_categories ul li li,
.widget_meta ul li li,
.widget_nav_menu li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_services li li,
.wp-block-archives-list li li,
.wp-block-categories-list li li,
.wp-block-latest-posts li li {
    border-bottom: none;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 22px;
    border-bottom: 1px solid rgba(0, 0, 0, .1)
}

.widget_archive ul li li:last-child,
.widget_categories ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_nav_menu li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_services li li:last-child,
.wp-block-archives-list li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-latest-posts li li:last-child {
    border-bottom: 0;
    padding-bottom: 0
}

.widget_archive ul ul,
.widget_categories ul ul,
.widget_meta ul ul,
.widget_nav_menu li ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_services li ul,
.wp-block-archives-list ul,
.wp-block-categories-list ul,
.wp-block-latest-posts ul {
    padding-left: 20px;
    margin-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin-left: -15px
}

.widget_archive ul li li:before,
.widget_categories ul li li:before,
.widget_meta ul li li:before,
.widget_nav_menu li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_services li li:before,
.wp-block-archives-list li li:before,
.wp-block-categories-list li li:before,
.wp-block-latest-posts li li:before {
    top: 16px;
    left: 0
}

.search-bx .btn {
    padding: 9px 12px;
    border: 1px solid transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.widget_search .screen-reader-text {
    display: block
}

.searchform {
    position: relative
}

.searchform input[type=text] {
    width: 100%;
    height: 40px;
    padding: 10px 90px 10px 15px;
    border: 1px solid #ccc
}

.searchform input[type=submit] {
    height: 40px;
    padding: 10px 15px;
    background-color: #77c04b;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none
}

.searchform input[type=submit]:active,
.searchform input[type=submit]:focus,
.searchform input[type=submit]:hover {
    background-color: #6ab33e;
    color: #fff !important;
    border-bottom-color: #5a9e2f
}

.search-bx.style-1 .input-group-btn button {
    height: 50px;
    width: 50px
}

.search-bx.style-1 .form-control {
    border-width: 0;
    font-size: 15px;
    height: 50px;
    background: #f5f5f5;
    padding: 5px 20px
}

.widget .dlab-title {
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
    font-size: 19px;
    line-height: 1.3;
    font-weight: 600
}

.recent-posts-entry .widget-post .dlab-meta ul li {
    font-size: 13px;
    color: #989898;
    font-weight: 400
}

.recent-posts-entry .widget-post-bx:last-child {
    border-bottom: none
}

.recent-posts-entry .dlab-media {
    width: 90px;
    min-width: 90px;
    margin: 0;
    border-radius: 0
}

.recent-posts-entry .dlab-info {
    background: 0 0;
    padding: 0;
    padding-left: 15px;
    border: none;
    padding-right: 0
}

.recent-posts-entry .post-meta span {
    margin-right: 10px
}

.recent-posts-entry .dlab-meta {
    margin-bottom: 5px
}

.recent-posts-entry .dlab-info,
.recent-posts-entry .dlab-media {
    display: table-cell;
    vertical-align: middle
}

.search-bx.style-1 .form-control {
    border-width: 1px;
    font-size: 15px;
    height: 50px;
    background: #fff;
    padding: 5px 20px
}

.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
    font-style: normal
}

.widget .post-title {
    margin-bottom: 0;
    margin-top: 0;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 22px
}

.recent-posts-entry .widget-post {
    margin-bottom: 25px;
    align-items: center;
    display: flex;
    overflow: hidden
}

.recent-posts-entry .widget-post:last-child {
    margin-bottom: 0
}

.recent-posts-entry .widget-post-bx:last-child {
    border-bottom: none
}

.recent-posts-entry .dlab-post-media {
    width: 90px;
    height: 90px;
    margin: 0;
    border-radius: 90px;
    overflow: hidden;
    min-width: 90px
}

.recent-posts-entry .dlab-post-media img {
    height: 100%;
    object-fit: cover
}

.recent-posts-entry .dlab-post-info {
    background: 0 0;
    padding: 0;
    padding-left: 15px;
    border: none
}

.recent-posts-entry .post-meta span {
    margin-right: 10px
}

.recent-posts-entry .dlab-post-meta {
    margin-bottom: 10px
}

.recent-posts-entry .dlab-post-info,
.recent-posts-entry .dlab-post-media {
    display: table-cell;
    vertical-align: middle
}

.widget_recent_comments ul li:before {
    content: "\f0e6";
    font-family: FontAwesome;
    background-color: transparent !important;
    width: auto;
    height: auto;
    left: 0;
    top: 10px;
    font-size: 18px;
    color: #11171a
}

.widget_recent_comments ul {
    margin-top: -10px
}

.widget_recent_comments ul li {
    padding-left: 30px;
    color: #11171a;
    line-height: 26px
}

.widget_recent_comments .comment-author-link a {
    color: #000;
    font-weight: 700
}

.widget_meta ul li a abbr[title] {
    color: #333;
    border-bottom: none
}

.widget_calendar td,
.widget_calendar th {
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .05)
}

.widget_calendar tr {
    border-bottom: 1px solid #eee
}

.widget_calendar table {
    border-collapse: collapse;
    margin: 0;
    width: 100%
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
    color: #fff
}

.widget_calendar .calendar_wrap td a {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px
}

.widget_calendar td,
.widget_calendar th {
    padding: .4em
}

.widget_calendar caption {
    text-align: center;
    font-weight: 600;
    caption-side: top;
    background: var(--color-primary);
    color: #fff;
    text-transform: uppercase;
    font-size: 15px
}

.calendar_wrap tfoot td,
.calendar_wrap th {
    background: var(--color-hover);
    border-color: rgba(0, 0, 0, .05)
}

.calendar_wrap tfoot td:last-child,
.calendar_wrap th:last-child {
    border: 0
}

.widget_calendar tr:nth-child(2n+2) {
    background-color: rgba(0, 0, 0, .05)
}

.wp-calendar-nav {
    background: var(--bg-color-ho);
    color: #fff;
    display: flex;
    justify-content: space-between;
    text-align: center
}

.wp-calendar-nav span {
    padding: 5px 20px;
    width: 44%;
    border-right: 1px solid rgba(255, 255, 255, .2)
}

.wp-calendar-nav span a {
    color: #fff
}

.wp-calendar-nav span.pad {
    width: 15%
}

.widget_tag_cloud a {
    padding: 8px 10px;
    background-color: var(--color-primary);
    font-size: 12px;
    display: inline-block;
    margin: 0 2px 5px;
    color: #fff;
    font-weight: 600
}

.side-bar .widget_tag_cloud a:hover {
    background-color: #6d9ec4;
    color: #fff
}

.widget_tag_cloud.radius a {
    border-radius: 4px;
    padding: 7px 10px
}

.widget_archive select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc
}

.widget_text select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc
}

.widget_text select option {
    width: 100%
}

.widget_text p {
    font-size: 15px;
    line-height: 28px
}

.widget_text b,
.widget_text strong {
    color: #11171a
}

.widget_archive li ul,
.widget_categories li ul,
.wp-block-archives-list li ul,
.wp-block-categories-list li ul,
.wp-block-latest-posts li ul {
    margin-bottom: -10px
}

.widget_archive ul li,
.widget_categories ul li,
.wp-block-archives-list li,
.wp-block-categories-list li,
.wp-block-latest-posts li {
    text-align: right;
    display: table;
    width: 100%;
    font-size: 14px !important;
    font-family: Poppins, sans-serif !important;
    padding: 10px 0 10px 15px !important;
    line-height: 1.3 !important
}

.widget_categories ul li:before,
.wp-block-archives-list li:before,
.wp-block-categories-list li:before,
.wp-block-latest-posts li:before {
    content: none !important
}

.widget_archive li a,
.widget_categories li a,
.wp-block-archives-list li a,
.wp-block-categories-list li a,
.wp-block-latest-posts li a {
    float: left;
    text-transform: capitalize;
    font-family: Poppins, sans-serif;
    box-shadow: none !important;
    text-align: left
}

.wp-block-archives-list,
.wp-block-categories-list,
.wp-block-latest-posts {
    margin-bottom: 40px;
    padding: 0 !important
}

.rsswidget img {
    display: inherit
}

.widget-title .rsswidget {
    display: inline-block
}

ul a.rsswidget,
ul cite {
    font-weight: 600;
    color: #060429;
    font-size: 14px
}

ul a.rsswidget {
    font-size: 18px
}

ul a.rsswidget:hover {
    color: #5608e0
}

.rssSummary {
    margin: 20px 0;
    font-size: 14px;
    line-height: 1.7
}

.rss-date {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #9fa1a4
}

.widget_rss ul li {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-top: 0
}

.widget_rss ul li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0
}

.widget_getintuch {
    padding-top: 10px
}

.widget_getintuch li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px
}

.widget_getintuch b,
.widget_getintuch li,
.widget_getintuch strong {
    display: block;
    text-transform: capitalize
}

.widget_getintuch strong {
    font-weight: 600;
    font-size: 16px
}

.widget_getintuch i {
    position: absolute;
    left: 0;
    top: 5px;
    text-align: center;
    font-size: 22px;
    width: 30px;
    height: 30px;
    line-height: 30px
}

.widget_gallery ul {
    padding-left: 0;
    display: table
}

.widget_gallery.gallery-grid-2 li {
    width: 50%
}

.widget_gallery.gallery-grid-4 li {
    width: 25%
}

.gallery-grid-8 li {
    width: 12.5%
}

@media only screen and (max-width:767px) {
    .gallery-grid-8 li {
        width: 25%
    }
}

.widget_gallery li {
    display: inline-block;
    width: 33.33%;
    float: left;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    padding: 2px
}

.widget_gallery li img {
    display: inline-block;
    width: 100%
}

.widget_gallery li:nth-child(3n-3) {
    margin-right: 0
}

.widget_gallery li:hover {
    opacity: .7;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.widget_gallery a {
    display: inline-block
}

.widget-about .dlab-title {
    margin: 15px 0 5px 0;
    color: #000
}

.widget-about p {
    color: #333;
    font-size: 13px;
    line-height: 20px
}

.widget-about .button-sm {
    font-weight: 700;
    padding: 5px 20px
}

.widget-testimonial .testimonial-pic {
    height: 70px;
    width: 70px;
    border: 3px solid #fff;
    margin-bottom: 10px
}

.widget-testimonial .testimonial-name {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #000
}

.widget-testimonial .testimonial-position {
    text-transform: uppercase;
    color: #888;
    font-size: 12px;
    font-style: inherit
}

.widget-testimonial .testimonial-position p {
    line-height: 26px
}

.widget-testimonial .testimonial-text {
    color: #505050;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
    padding: 0
}

.alert,
.btn,
.btn-group-divides>.btn:last-child:not(:first-child),
.btn-group-divides>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group>.dropdown-toggle:not(:first-child),
.dlab-booking-form .dlab-extra-services li .icon-bx-lg,
.dlab-booking-form .dlab-extra-services li.active .icon-bx-lg:after,
.dropdown-menu,
.form-control,
.img-thumbnail,
.input-group-addon,
.list-group-item:first-child,
.list-group-item:last-child,
.modal-content,
.nav-pills>li>a,
.nav-tabs>li>a,
.navbar-toggler,
.pager li>a,
.pager li>span,
.panel,
.panel-group .panel,
.panel-head ing,
.searchform input[type=submit],
.searchform input[type=text],
.well,
.widget_tag_cloud a,
ol.comment-list li.comment .comment-body {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.btn,
.dlab-box,
.dlab-info-has,
.dlab-we-find .dlab-box,
.extra-nav,
.form-control,
.header-nav .nav>li>a,
.how-dlab-work .dlab-box .shadow-bx img,
.how-dlab-work .dlab-box .step-no-bx,
.icon-bx-lg,
.icon-bx-md,
.icon-bx-sm,
.icon-bx-xl,
.icon-bx-xs,
.indicator,
.logo-header,
.navbar-toggler,
.overlay-bx,
.overlay-icon li a i,
.profile-menu ul li,
.share-social-bx li,
.skew-primary:hover,
.skew-primary:hover::after,
.skew-secondry:hover,
.skew-secondry:hover::after,
.staff-member .member-done,
a {
    -webkit-transition: all .8s linear;
    -moz-transition: all .8s linear;
    -ms-transition: all .8s linear;
    -o-transition: all .8s linear;
    transition: all .8s linear
}

.animate-slow {
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.animate-mid {
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    transition: all .5s ease-out
}

.animate-fast {
    -webkit-transition: all .8s ease-out;
    -moz-transition: all .8s ease-out;
    -o-transition: all .8s ease-out;
    -ms-transition: all .8s ease-out;
    transition: all .8s ease-out
}

.clearfix:after,
.clearfix:before {
    content: "";
    display: table
}

.clearfix:after {
    clear: both
}

.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:focus,
.has-error .bootstrap-select .dropdown-toggle:hover {
    border-color: #a94442 !important
}

.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:focus,
.has-success .bootstrap-select .dropdown-toggle:hover {
    border-color: #3c763d !important
}

.sort-title {
    margin-bottom: 40px
}

.sort-title h4:after {
    left: -160px
}

.sort-title.text-white h4:after,
.sort-title.text-white h4:before {
    background: #fff
}

.sort-title h4:after,
.sort-title h4:before {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 1px;
    position: absolute;
    right: -160px;
    top: 50%;
    width: 150px;
    opacity: .15
}

.sort-title h4 {
    display: inline-block;
    margin: 0;
    position: relative
}

@media only screen and (max-width:767px) {

    .sort-title h4:after,
    .sort-title h4:before {
        content: none
    }
}

.owl-next,
.owl-prev {
    background-color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .5);
    color: #fff;
    padding: 5px 8px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    border-width: 0;
    border-style: solid
}

.owl-next:active,
.owl-next:focus,
.owl-next:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-prev:hover {
    background-color: rgba(0, 0, 0, .7);
    border-color: #3e2e23;
    color: #fff
}

.button-example .btn,
.button-example .site-button,
.button-example .site-button-link {
    margin: 0 5px 10px 0
}

.btn {
    -moz-user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    padding: 8px 16px;
    text-align: center;
    transition: all .2s ease-in-out 0s;
    vertical-align: middle;
    white-space: nowrap
}

.btn-success {
    background-color: #5cb85c;
    border-color: #5cb85c;
    color: #fff
}

.btn-primary {
    background-color: #0275d8;
    border-color: #0275d8;
    color: #fff
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #025aa5;
    border-color: #01549b
}

.btn-info {
    background-color: #5bc0de;
    border-color: #5bc0de;
    color: #fff
}

.btn-danger {
    background-color: #d9534f;
    border-color: #d9534f;
    color: #fff
}

.btn-secondary {
    background-color: #fff;
    border-color: #ccc;
    color: #292b2c
}

.btn-outline-danger {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #d9534f;
    color: #d9534f
}

.btn-outline-primary {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #0275d8;
    color: #0275d8
}

.btn-outline-secondary {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #ccc;
    color: #ccc
}

.btn-outline-success {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #5cb85c;
    color: #5cb85c
}

.btn-outline-info {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #5bc0de;
    color: #5bc0de
}

.btn-outline-warning {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #f0ad4e;
    color: #f0ad4e
}

.btn-outline-primary:hover {
    background-color: #0274d9;
    border-color: #0274d9;
    color: #fff
}

.btn-outline-secondary:hover {
    background-color: #ccc;
    border-color: #ccc;
    color: #fff
}

.btn-outline-success:hover {
    background-color: #5bb75b;
    border-color: #5bb75b;
    color: #fff
}

.btn-outline-info:hover {
    background-color: #59bedd;
    border-color: #59bedd;
    color: #fff
}

.btn-outline-warning:hover {
    background-color: #efab4c;
    border-color: #efab4c;
    color: #fff
}

.btn-outline-danger:hover {
    background-color: #d8534f;
    border-color: #d8534f;
    color: #fff
}

.btn-group-lg>.btn,
.btn-lg {
    border-radius: 4px;
    font-size: 20px;
    padding: 12px 24px
}

.btn-group-sm>.btn,
.btn-sm {
    border-radius: 3px;
    font-size: 12px;
    padding: 4px 8px
}

.site-button,
.site-button-secondry {
    color: #fff;
    padding: 12px 30px;
    display: inline-block;
    font-size: 13px;
    outline: 0;
    cursor: pointer;
    outline: 0;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    line-height: 1.42857;
    border-radius: 3px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle
}

.site-button {
    background-color: #efbb20
}

.active>.site-button,
.site-button:active,
.site-button:focus,
.site-button:hover {
    background-color: #d8a409;
    color: #fff
}

.site-button-secondry {
    background-color: #191f23
}

.active>.site-button-secondry,
.site-button-secondry:active,
.site-button-secondry:focus,
.site-button-secondry:hover {
    background-color: #262a5c;
    color: #fff
}

.site-button.text-uppercase {
    text-transform: uppercase
}

.button-sm {
    padding: 5px 10px;
    font-size: 12px
}

.button-md {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 500
}

.button-lg {
    font-size: 18px;
    font-weight: 600;
    padding: 18px 35px
}

.button-xl {
    font-size: 22px;
    padding: 25px 35px
}

.radius-no {
    border-radius: 0;
    -webkit-border-radius: 0
}

.radius-sm {
    border-radius: 4px;
    -webkit-border-radius: 4px
}

.radius-xl {
    border-radius: 100px;
    -webkit-border-radius: 100px
}

.radius-md {
    border-radius: 8px;
    -webkit-border-radius: 8px
}

.site-button.white {
    background-color: #fff;
    color: #777
}

.site-button.white:active,
.site-button.white:focus,
.site-button.white:hover {
    background-color: #f4f4f4;
    color: #555
}

.site-button.black {
    background-color: #171717;
    color: #fff
}

.site-button.black:active,
.site-button.black:focus,
.site-button.black:hover {
    background-color: #000;
    color: #fff
}

.site-button.gray {
    background-color: #666;
    color: #fff
}

.site-button.gray.active,
.site-button.gray:active,
.site-button.gray:focus,
.site-button.gray:hover {
    background-color: #555;
    color: #fff
}

.site-button.pink {
    background-color: #ff3c85;
    color: #fff
}

.site-button.pink.active,
.site-button.pink:active,
.site-button.pink:focus,
.site-button.pink:hover {
    background-color: #ef2c75;
    color: #fff
}

.site-button.blue {
    background-color: #007fff;
    color: #fff
}

.site-button.blue.active,
.site-button.blue:active,
.site-button.blue:focus,
.site-button.blue:hover {
    background-color: #0072e5;
    color: #fff
}

.site-button.green {
    background-color: #34bfa3;
    color: #fff
}

.site-button.green.active,
.site-button.green:active,
.site-button.green:focus,
.site-button.green:hover {
    background-color: #24af93;
    color: #fff
}

.site-button.orange {
    background-color: #ff7901;
    color: #fff
}

.site-button.orange.active,
.site-button.orange:active,
.site-button.orange:focus,
.site-button.orange:hover {
    background-color: #e86e00;
    color: #fff
}

.site-button.red {
    background-color: #f0526d;
    color: #fff
}

.site-button.red.active,
.site-button.red:active,
.site-button.red:focus,
.site-button.red:hover {
    background-color: #f22d4e;
    color: #fff
}

.site-button.brown {
    background-color: #484a5c;
    color: #fff
}

.site-button.brown.active,
.site-button.brown:active,
.site-button.brown:focus,
.site-button.brown:hover {
    background-color: #383a4c;
    color: #fff
}

.site-button.yellow {
    background-color: #ffb822;
    color: #fff
}

.site-button.yellow.active,
.site-button.yellow:active,
.site-button.yellow:focus,
.site-button.yellow:hover {
    background-color: #efa812;
    color: #fff
}

.site-button.purple {
    background-color: #8862b9;
    color: #fff
}

.site-button.purple.active,
.site-button.purple:active,
.site-button.purple:focus,
.site-button.purple:hover {
    background-color: #7852a9;
    color: #fff
}

.site-button.white {
    background-color: #fff;
    color: #777
}

.site-button.white:active,
.site-button.white:focus,
.site-button.white:hover {
    background-color: #f4f4f4;
    color: #555
}

.site-button.black.gradient {
    background: #000;
    background: -moz-linear-gradient(45deg, #000 0, #727272 100%);
    background: -webkit-linear-gradient(45deg, #000 0, #727272 100%);
    background: linear-gradient(45deg, #000 0, #727272 100%);
    color: #fff
}

.site-button.black.gradient:active,
.site-button.black.gradient:focus,
.site-button.black.gradient:hover {
    background: #1e1e1e;
    background: -moz-linear-gradient(45deg, #1e1e1e 0, #727272 100%);
    background: -webkit-linear-gradient(45deg, #1e1e1e 0, #727272 100%);
    background: linear-gradient(45deg, #1e1e1e 0, #727272 100%);
    color: #fff
}

.site-button.gradient.gray {
    background: #666;
    background: -moz-linear-gradient(45deg, #666 0, #999 100%);
    background: -webkit-linear-gradient(45deg, #666 0, #999 100%);
    background: linear-gradient(45deg, #666 0, #999 100%);
    color: #fff
}

.site-button.gradient.gray:active,
.site-button.gradient.gray:focus,
.site-button.gradient.gray:hover {
    background: #4f4f4f;
    background: -moz-linear-gradient(45deg, #4f4f4f 0, #999 100%);
    background: -webkit-linear-gradient(45deg, #4f4f4f 0, #999 100%);
    background: linear-gradient(45deg, #4f4f4f 0, #999 100%);
    color: #fff
}

.site-button.gradient.pink {
    background: #ff3c85;
    background: -moz-linear-gradient(45deg, #ff3c85 0, #ff93bb 100%);
    background: -webkit-linear-gradient(45deg, #ff3c85 0, #ff93bb 100%);
    background: linear-gradient(45deg, #ff3c85 0, #ff93bb 100%);
    color: #fff
}

.site-button.gradient.pink:active,
.site-button.gradient.pink:focus,
.site-button.gradient.pink:hover {
    background: #ff3d81;
    background: -moz-linear-gradient(45deg, #ff3d81 0, #ff7fae 100%);
    background: -webkit-linear-gradient(45deg, #ff3d81 0, #ff7fae 100%);
    background: linear-gradient(45deg, #ff3d81 0, #ff7fae 100%);
    color: #fff
}

.site-button.gradient.blue {
    background: #00c5db;
    background: -moz-linear-gradient(45deg, #00c5db 0, #6dff9e 99%);
    background: -webkit-linear-gradient(45deg, #00c5db 0, #6dff9e 99%);
    background: linear-gradient(45deg, #00c5db 0, #6dff9e 99%);
    color: #fff
}

.site-button.gradient.blue:active,
.site-button.gradient.blue:focus,
.site-button.gradient.blue:hover {
    background: #00a9bc;
    background: -moz-linear-gradient(45deg, #00a9bc 0, #54ea86 99%);
    background: -webkit-linear-gradient(45deg, #00a9bc 0, #54ea86 99%);
    background: linear-gradient(45deg, #00a9bc 0, #54ea86 99%);
    color: #fff
}

.site-button.gradient.green {
    background: #84ba3f;
    background: -moz-linear-gradient(45deg, #84ba3f 0, #b7ff59 99%);
    background: -webkit-linear-gradient(45deg, #84ba3f 0, #b7ff59 99%);
    background: linear-gradient(45deg, #84ba3f 0, #b7ff59 99%);
    color: #fff
}

.site-button.gradient.green:active,
.site-button.gradient.green:focus,
.site-button.gradient.green:hover {
    background: #6dad1a;
    background: -moz-linear-gradient(45deg, #6dad1a 0, #90ff00 99%);
    background: -webkit-linear-gradient(45deg, #6dad1a 0, #90ff00 99%);
    background: linear-gradient(45deg, #6dad1a 0, #90ff00 99%);
    color: #fff
}

.site-button.gradient.orange {
    background: #ef940b;
    background: -moz-linear-gradient(45deg, #ef940b 0, #ffd754 100%);
    background: -webkit-linear-gradient(45deg, #ef940b 0, #ffd754 100%);
    background: linear-gradient(45deg, #ef940b 0, #ffd754 100%);
    color: #fff
}

.site-button.gradient.orange:active,
.site-button.gradient.orange:focus,
.site-button.gradient.orange:hover {
    background: #d68000;
    background: -moz-linear-gradient(45deg, #d68000 0, #ffbf00 100%);
    background: -webkit-linear-gradient(45deg, #d68000 0, #ffbf00 100%);
    background: linear-gradient(45deg, #d68000 0, #ffbf00 100%);
    color: #fff
}

.site-button.gradient.red {
    background: #ff1437;
    background: -moz-linear-gradient(45deg, #ff1437 0, #ff5982 100%);
    background: -webkit-linear-gradient(45deg, #ff1437 0, #ff5982 100%);
    background: linear-gradient(45deg, #ff1437 0, #ff5982 100%);
    color: #fff
}

.site-button.gradient.red:active,
.site-button.gradient.red:focus,
.site-button.gradient.red:hover {
    background: #f02;
    background: -moz-linear-gradient(45deg, #f02 0, #ff3866 100%);
    background: -webkit-linear-gradient(45deg, #f02 0, #ff3866 100%);
    background: linear-gradient(45deg, #f02 0, #ff3866 100%);
    color: #fff
}

.site-button.gd,
.site-button.gd:active,
.site-button.gd:focus,
.site-button.gd:hover {
    background: #1ebbf0;
    background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    background: -webkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%)
}

.site-button.gradient.brown {
    background: #303451;
    background: -moz-linear-gradient(45deg, #303451 0, #7e8196 100%);
    background: -webkit-linear-gradient(45deg, #303451 0, #7e8196 100%);
    background: linear-gradient(45deg, #303451 0, #7e8196 100%);
    color: #fff
}

.site-button.gradient.brown:active,
.site-button.gradient.brown:focus,
.site-button.gradient.brown:hover {
    background: #1d213f;
    background: -moz-linear-gradient(45deg, #1d213f 0, #777c9b 100%);
    background: -webkit-linear-gradient(45deg, #1d213f 0, #777c9b 100%);
    background: linear-gradient(45deg, #1d213f 0, #777c9b 100%);
    color: #fff
}

.site-button.gradient.yellow {
    background: #ffb52d;
    background: -moz-linear-gradient(45deg, #ffb52d 34%, #ffd684 100%);
    background: -webkit-linear-gradient(45deg, #ffb52d 34%, #ffd684 100%);
    background: linear-gradient(45deg, #ffb52d 34%, #ffd684 100%);
    color: #fff
}

.site-button.gradient.yellow:active,
.site-button.gradient.yellow:focus,
.site-button.gradient.yellow:hover {
    background: #e2a336;
    background: -moz-linear-gradient(45deg, #e2a336 34%, #ffc656 100%);
    background: -webkit-linear-gradient(45deg, #e2a336 34%, #ffc656 100%);
    background: linear-gradient(45deg, #e2a336 34%, #ffc656 100%);
    color: #fff
}

.site-button.gradient.purple {
    background: #62369b;
    background: -moz-linear-gradient(45deg, #62369b 0, #b37cff 87%);
    background: -webkit-linear-gradient(45deg, #62369b 0, #b37cff 87%);
    background: linear-gradient(45deg, #62369b 0, #b37cff 87%);
    color: #fff
}

.site-button.gradient.purple:active,
.site-button.gradient.purple:focus,
.site-button.gradient.purple:hover {
    background: #814ec4;
    background: -moz-linear-gradient(45deg, #814ec4 0, #9e4aba 87%);
    background: -webkit-linear-gradient(45deg, #814ec4 0, #9e4aba 87%);
    background: linear-gradient(45deg, #814ec4 0, #9e4aba 87%);
    color: #fff
}

.button-skew {
    position: relative;
    text-align: center;
    margin-right: 21px;
    -moz-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    transform: skewX(-20deg)
}

.button-skew span {
    display: inline-block;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg)
}

.button-skew i {
    background-color: inherit;
    position: absolute;
    height: 100%;
    margin-right: -32px;
    right: 0;
    top: 0;
    padding: 12px 12px;
    line-height: normal
}

.button-skew i:before {
    display: inline-block;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg)
}

.button-skew.button-sm i {
    padding: 7px 10px;
    margin-right: -27px
}

.button-skew.button-lg i {
    padding: 25px 15px;
    margin-right: -40px
}

.button-skew.button-xl i {
    padding: 35px 22px;
    margin-right: -55px
}

.site-button.graphical {
    color: #fff;
    border-radius: 3px;
    text-shadow: 0 -1px rgba(0, 0, 0, .4);
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, .3), 0 1px 3px -1px rgba(45, 60, 72, .5);
    border: 1px solid rgba(0, 0, 0, .15)
}

.site-button.graphical:active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .25) inset
}

.site-button.button-3d {
    color: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    border-bottom: 4px solid rgba(0, 0, 0, .2);
    border-left: none;
    border-right: none;
    border-top: none
}

.text-white .site-button.outline {
    color: #fff
}

.site-button.outline {
    color: #888;
    background: 0 0;
    border-width: 1px;
    border-style: solid;
    border-color: inherit
}

.site-button.outline-2 {
    border-width: 2px
}

.site-button.outline-2.radius-xl,
.site-button.outline.radius-xl {
    border-radius: 100px
}

.site-button.outline.white {
    color: #fff;
    border-color: #fff
}

.site-button.outline.white:hover {
    color: #666
}

.site-button.outline.black {
    color: #171717;
    border-color: #171717
}

.site-button.outline.gray {
    color: #666;
    border-color: #666
}

.site-button.outline.pink {
    color: #e63f75;
    border-color: #e63f75
}

.site-button.outline.blue {
    color: #007fff;
    border-color: #007fff
}

.site-button.outline.green {
    color: #35b494;
    border-color: #35b494
}

.site-button.outline.orange {
    color: #ff7901;
    border-color: #ff7901
}

.site-button.outline.red {
    color: #d93223;
    border-color: #d93223
}

.site-button.outline.brown {
    color: #69441f;
    border-color: #69441f
}

.site-button.outline.yellow {
    color: #efbb20;
    border-color: #efbb20
}

.site-button.outline.purple {
    color: #ae1af7;
    border-color: #ae1af7
}

.site-button.outline:hover {
    border-color: transparent;
    color: #fff
}

.site-button-link {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    display: inline-block;
    font-weight: 400
}

.site-button-link i {
    margin-left: 10px
}

.site-button-link.white {
    color: #fff
}

.site-button-link.white:active,
.site-button-link.white:focus,
.site-button-link.white:hover {
    color: #eee
}

.site-button-link.black {
    color: #171717
}

.site-button-link.black:active,
.site-button-link.black:focus,
.site-button-link.black:hover {
    color: #000
}

.site-button-link.gray {
    color: #666
}

.site-button-link.gray:active,
.site-button-link.gray:focus,
.site-button-link.gray:hover {
    color: #555
}

.site-button-link.pink {
    color: #e63f75
}

.site-button-link.pink:active,
.site-button-link.pink:focus,
.site-button-link.pink:hover {
    color: #ef2c75
}

.site-button-link.blue {
    color: #007fff
}

.site-button-link.blue:active,
.site-button-link.blue:focus,
.site-button-link.blue:hover {
    color: #0072e5
}

.site-button-link.green {
    color: #35b494
}

.site-button-link.green:active,
.site-button-link.green:focus,
.site-button-link.green:hover {
    color: #26a585
}

.site-button-link.orange {
    color: #e56713
}

.site-button-link.orange:active,
.site-button-link.orange:focus,
.site-button-link.orange:hover {
    color: #d55703
}

.site-button-link.red {
    color: #d93223
}

.site-button-link.red:active,
.site-button-link.red:focus,
.site-button-link.red:hover {
    color: #c51e0f
}

.site-button-link.brown {
    color: #69441f
}

.site-button-link.brown:active,
.site-button-link.brown:focus,
.site-button-link.brown:hover {
    color: #5f3a15
}

.site-button-link.yellow {
    color: #ecc731
}

.site-button-link.yellow:active,
.site-button-link.yellow:focus,
.site-button-link.yellow:hover {
    color: #d4af19
}

.site-button-link.purple {
    color: #ae1af7
}

.site-button-link.purple:active,
.site-button-link.purple:focus,
.site-button-link.purple:hover {
    color: #9804e1
}

.dropdown-menu>li>a {
    padding: 10px 20px
}

.site-button.box-shadow.black {
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, .19)
}

.site-button.box-shadow.gray {
    box-shadow: 0 5px 10px 2px rgba(85, 85, 85, .19)
}

.site-button.box-shadow.pink {
    box-shadow: 0 5px 10px 2px rgba(239, 44, 177, .19)
}

.site-button.box-shadow.blue {
    box-shadow: 0 5px 10px 2px rgba(0, 127, 255, .19)
}

.site-button.box-shadow.green {
    box-shadow: 0 5px 10px 2px rgba(36, 176, 147, .19)
}

.site-button.box-shadow.orange {
    box-shadow: 0 5px 10px 2px rgba(223, 136, 0, .19)
}

.site-button.box-shadow.red {
    box-shadow: 0 5px 10px 2px rgba(242, 45, 78, .19)
}

.site-button.box-shadow.brown {
    box-shadow: 0 5px 10px 2px rgba(56, 58, 76, .19)
}

.site-button.box-shadow.yellow {
    box-shadow: 0 5px 10px 2px rgba(239, 168, 18, .19)
}

.site-button.box-shadow.purple {
    box-shadow: 0 5px 10px 2px rgba(120, 82, 169, .19)
}

.site-button.circle-sm {
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 40px;
    font-size: 12px;
    line-height: 28px
}

.site-button.circle {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 40px;
    line-height: 39px
}

.site-button.circle-lg {
    border-radius: 40px;
    font-size: 20px;
    height: 55px;
    padding: 0;
    width: 55px;
    line-height: 54px
}

.site-button.sharp-sm {
    width: 28px;
    height: 28px;
    padding: 0;
    font-size: 12px;
    line-height: 28px
}

.site-button.sharp {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 39px
}

.site-button.sharp-lg {
    font-size: 20px;
    height: 55px;
    padding: 0;
    width: 55px;
    line-height: 54px
}

.site-button.outline.circle-sm,
.site-button.outline.sharp-sm {
    line-height: 27px
}

.site-button-link.facebook {
    color: #3b5998
}

.site-button-link.google-plus {
    color: #de4e43
}

.site-button-link.linkedin {
    color: #007bb6
}

.site-button-link.instagram {
    color: #8a5a4e
}

.site-button-link.twitter {
    color: #1ea1f3
}

.site-button-link.youtube {
    color: #ce201f
}

.site-button-link.whatsapp {
    color: #01c854
}

.site-button-link.facebook.hover,
.site-button-link.google-plus.hover,
.site-button-link.instagram.hover,
.site-button-link.linkedin.hover,
.site-button-link.twitter.hover,
.site-button-link.whatsapp.hover,
.site-button-link.youtube.hover {
    color: inherit
}

.site-button.facebook,
.site-button.google-plus,
.site-button.instagram,
.site-button.linkedin,
.site-button.twitter,
.site-button.whatsapp,
.site-button.youtube {
    color: #fff
}

.site-button-link.facebook.hover:active,
.site-button-link.facebook.hover:focus,
.site-button-link.facebook.hover:hover {
    color: #3b5998
}

.site-button-link.google-plus.hover:active,
.site-button-link.google-plus.hover:focus,
.site-button-link.google-plus.hover:hover {
    color: #de4e43
}

.site-button-link.linkedin.hover:active,
.site-button-link.linkedin.hover:focus,
.site-button-link.linkedin.hover:hover {
    color: #007bb6
}

.site-button-link.instagram.hover:active,
.site-button-link.instagram.hover:focus,
.site-button-link.instagram.hover:hover {
    color: #8a5a4e
}

.site-button-link.twitter.hover:active,
.site-button-link.twitter.hover:focus,
.site-button-link.twitter.hover:hover {
    color: #1ea1f3
}

.site-button-link.youtube.hover:active,
.site-button-link.youtube.hover:focus,
.site-button-link.youtube.hover:hover {
    color: #ce201f
}

.site-button-link.whatsapp.hover:active,
.site-button-link.whatsapp.hover:focus,
.site-button-link.whatsapp.hover:hover {
    color: #01c854
}

.site-button.facebook {
    background-color: #3b5998
}

.site-button.facebook:active,
.site-button.facebook:focus,
.site-button.facebook:hover {
    background-color: #4b69a8;
    color: #fff
}

.site-button.google-plus {
    background-color: #de4e43
}

.site-button.google-plus:active,
.site-button.google-plus:focus,
.site-button.google-plus:hover {
    background-color: #fe6e63;
    color: #fff
}

.site-button.linkedin {
    background-color: #007bb6
}

.site-button.linkedin:active,
.site-button.linkedin:focus,
.site-button.linkedin:hover {
    background-color: #209bd6;
    color: #fff
}

.site-button.instagram {
    background-color: #8a5a4e
}

.site-button.instagram:active,
.site-button.instagram:focus,
.site-button.instagram:hover {
    background-color: #aa7a6e;
    color: #fff
}

.site-button.twitter {
    background-color: #1ea1f3
}

.site-button.twitter:active,
.site-button.twitter:focus,
.site-button.twitter:hover {
    background-color: #0e91e3;
    color: #fff
}

.site-button.youtube {
    background-color: #ce201f
}

.site-button.youtube:active,
.site-button.youtube:focus,
.site-button.youtube:hover {
    background-color: #ee403f;
    color: #fff
}

.site-button.whatsapp {
    background-color: #01c854
}

.site-button.whatsapp:active,
.site-button.whatsapp:focus,
.site-button.whatsapp:hover {
    background-color: #00a834;
    color: #fff
}

.site-button.facebook.outline,
.site-button.google-plus.outline,
.site-button.instagram.outline,
.site-button.linkedin.outline,
.site-button.twitter.outline,
.site-button.whatsapp.outline,
.site-button.youtube.outline {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid
}

.site-button.facebook.outline {
    color: #3b5998
}

.site-button.google-plus.outline {
    color: #de4e43
}

.site-button.linkedin.outline {
    color: #007bb6
}

.site-button.instagram.outline {
    color: #8a5a4e
}

.site-button.twitter.outline {
    color: #1ea1f3
}

.site-button.youtube.outline {
    color: #ce201f
}

.site-button.whatsapp.outline {
    color: #01c854
}

.site-button.facebook.outline:active,
.site-button.facebook.outline:focus,
.site-button.facebook.outline:hover {
    background-color: #4b69a8;
    color: #fff
}

.site-button.google-plus.outline:active,
.site-button.google-plus.outline:focus,
.site-button.google-plus.outline:hover {
    background-color: #fe6e63;
    color: #fff
}

.site-button.linkedin.outline:active,
.site-button.linkedin.outline:focus,
.site-button.linkedin.outline:hover {
    background-color: #209bd6;
    color: #fff
}

.site-button.instagram.outline:active,
.site-button.instagram.outline:focus,
.site-button.instagram.outline:hover {
    background-color: #aa7a6e;
    color: #fff
}

.site-button.twitter.outline:active,
.site-button.twitter.outline:focus,
.site-button.twitter.outline:hover {
    background-color: #0e91e3;
    color: #fff
}

.site-button.youtube.outline:active,
.site-button.youtube.outline:focus,
.site-button.youtube.outline:hover {
    background-color: #ee403f;
    color: #fff
}

.site-button.whatsapp.outline:active,
.site-button.whatsapp.outline:focus,
.site-button.whatsapp.outline:hover {
    background-color: #00a834;
    color: #fff
}

@media only screen and (max-width:991px) {

    .button-layout .btn,
    .button-layout .site-button,
    .button-layout .site-button-link,
    .button-layout .site-secondry {
        margin-bottom: 10px
    }
}

.site-filters {
    margin-bottom: 30px
}

.site-filters ul {
    margin: 0;
    list-style: none
}

.site-filters li {
    display: inline-block;
    padding: 0;
    margin-bottom: 3px
}

.site-filters li.btn {
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: 0 !important
}

.site-filters li input {
    display: none
}

.site-filters a {
    margin: 0 5px 5px 0
}

.site-filters .active>[class*=site-button] {
    color: #fff;
    background-color: #efbb20
}

.site-filters.center {
    text-align: center
}

.site-filters.center ul {
    display: inline-block;
    margin: auto
}

.site-filters.center [class*=site-button] {
    display: inline-block;
    margin: 0 5px 10px;
    text-transform: uppercase
}

.mas-1 .card-container {
    margin-left: -1px
}

.site-filters.style1 li a {
    color: #000316;
    font-weight: 400;
    margin: 0;
    padding: 0 15px;
    position: relative
}

.site-filters.style1 li:last-child a:after {
    position: relative
}

.site-filters.style1 li a:after {
    background-color: #000316;
    content: "";
    height: 100%;
    opacity: .2;
    position: absolute;
    right: -2px;
    top: 0;
    transform: skew(-20deg, -20deg);
    -moz-transform: skew(-20deg, -20deg);
    -webkit-transform: skew(-20deg, -20deg);
    -o-transform: skew(-20deg, -20deg);
    width: 2px
}

.site-filters.style1 li.active a,
.site-filters.style1.white li.active a {
    color: var(--color-primary)
}

.site-filters.style1.white li a:after {
    background-color: #fff
}

.site-filters.style1.white li a {
    color: #bfbfbf
}

.site-filters .site-button.outline {
    border-color: var(--color-primary)
}

.site-filters.filters-sm li a {
    padding: 5px 20px;
    font-size: 13px;
    font-weight: 500
}

.site-filters.gray li a {
    background-color: #e0e3f6;
    color: #000
}

.site-filters .button-sm {
    font-size: 13px;
    padding: 5px 20px
}

@media only screen and (max-width:480px) {
    .site-filters.center [class*=site-button] {
        margin: 0 1px 5px;
        padding: 5px 10px;
        font-size: 12px
    }
}

.dlab-separator-outer {
    overflow: hidden
}

.dlab-separator.m-b0 {
    margin-bottom: 0
}

.dlab-separator {
    display: inline-block;
    height: 2px;
    width: 80px;
    margin-bottom: 10px;
    position: relative
}

.dlab-separator.style-liner {
    width: 20px
}

.dlab-separator.style-icon {
    width: 30px;
    height: auto;
    text-align: center;
    font-size: 20px
}

.dlab-separator[class*=style-]:after,
.dlab-separator[class*=style-]:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 40px;
    width: 70px;
    height: 2px;
    background: #eee;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.dlab-separator[class*=style-]:before {
    left: auto;
    right: 40px
}

.dlab-separator.style-skew {
    width: 15px;
    height: 10px;
    margin-left: 1px;
    margin-right: 1px;
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg)
}

.dlab-separator.style-skew[class*=style-]:after,
.dlab-separator.style-skew[class*=style-]:before {
    width: 80px;
    height: 4px;
    left: 20px;
    -moz-transform: translateY(-50%) skewX(-10deg);
    -webkit-transform: translateY(-50%) skewX(-10deg);
    -o-transform: translateY(-50%) skewX(-10deg);
    -ms-transform: translateY(-50%) skewX(-10deg);
    transform: translateY(-50%) skewX(-10deg)
}

.dlab-separator.style-skew[class*=style-]:before {
    right: 20px;
    left: auto
}

.dlab-separator.bnr-title {
    height: 1px;
    width: 155px;
    opacity: .5
}

.dlab-separator.bnr-title:before {
    height: inherit;
    right: -80px;
    width: 25px
}

.dlab-separator.bnr-title:after {
    height: inherit;
    right: -90px;
    top: 0;
    width: 6px
}

.dlab-separator.bnr-title:after,
.dlab-separator.bnr-title:before {
    position: absolute;
    content: "";
    background-color: inherit
}

.dlab-separator.bnr-title i {
    background-color: inherit;
    display: block;
    height: inherit;
    position: absolute;
    right: -50px;
    width: 45px
}

.dlab-divider {
    height: 1px;
    position: relative;
    margin: 30px 0
}

.dlab-divider.tb20 {
    margin: 20px 0
}

.dlab-divider.tb15 {
    margin: 15px 0
}

.dlab-divider.tb10 {
    margin: 10px 0
}

.dlab-divider.tb0 {
    margin: 0
}

.dlab-divider.divider-2px {
    height: 2px
}

.dlab-divider.divider-3px {
    height: 3px
}

.dlab-divider.divider-4px {
    height: 4px
}

.dlab-divider i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: block;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.dlab-divider.icon-left {
    margin-left: 40px
}

.dlab-divider.icon-left i {
    left: -40px
}

.dlab-divider.icon-right {
    margin-right: 40px
}

.dlab-divider.icon-right i {
    left: auto;
    right: -40px
}

.dlab-divider.icon-center i {
    left: 50%;
    margin-left: -5px
}

.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-chevron-circle,
.list-circle,
.list-cup,
.list-hand-point,
.list-heart,
.list-icon-box,
.list-pen,
.list-star {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none
}

.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-chevron-circle li,
.list-circle li,
.list-cup li,
.list-hand-point li,
.list-heart li,
.list-icon-box li,
.list-pen li,
.list-star li {
    padding: 5px 5px 5px 30px;
    position: relative
}

.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-chevron-circle li:before,
.list-circle li:before,
.list-cup li:before,
.list-hand-point li:before,
.list-heart li:before,
.list-icon-box li:before,
.list-pen li:before,
.list-star li:before {
    font-family: themify;
    position: absolute;
    left: 0;
    top: 5px;
    display: block;
    font-size: 15px;
    color: #777
}

ul[class*=list-] {
    font-size: 15px
}

.list-circle li:before {
    font-size: 16px
}

.list-circle li:before {
    content: "\e724"
}

.list-angle-right li:before {
    content: "\e628"
}

.list-arrow li:before {
    content: "\e661"
}

.list-check li:before {
    content: "\e64c"
}

.list-checked li:before {
    content: "\e64d";
    font-family: themify;
    font-size: 15px
}

.list-icon-box li:before {
    content: "\e6e8"
}

.list-chevron-circle li:before {
    content: "\e65d"
}

.list-hand-point li:before {
    content: "\e71b"
}

.list-star li:before {
    content: "\e60a"
}

.list-cup li:before {
    content: "\e641"
}

.list-pen li:before {
    content: "\e61c"
}

.list-heart li:before {
    content: "\e634"
}

.primary li:before {
    color: #efbb20
}

.secondry li:before {
    color: #77c04b
}

.black li:before {
    color: #000
}

.white li:before {
    color: #fff
}

.orange li:before {
    color: #f60
}

.green li:before {
    color: #0c0
}

.red li:before {
    color: #ff3737
}

.no-margin {
    margin: 0 0 0 !important;
    list-style: none
}

.list-num-count {
    counter-reset: li;
    padding-left: 0
}

.list-num-count>li {
    position: relative;
    margin: 0 0 6px 30px;
    padding: 4px 8px;
    list-style: none
}

.list-num-count>li:before {
    content: counter(li, decimal);
    counter-increment: li;
    position: absolute;
    top: 0;
    left: -28px;
    width: 28px;
    height: 28px;
    padding: 5px;
    color: #fff;
    background: #efbb20;
    font-weight: 700;
    text-align: center;
    font-size: 12px;
    -webkit-border-radius: 100px;
    border-radius: 100px
}

.list-num-count.lower-alpha>li:before {
    content: counter(li, lower-alpha)
}

.list-num-count.upper-alpha>li:before {
    content: counter(li, upper-alpha)
}

.list-num-count.upper-roman>li:before {
    content: counter(li, upper-roman)
}

.list-num-count.no-round>li:before {
    background-color: rgba(0, 0, 0, 0);
    color: #333;
    font-size: 15px;
    font-weight: 400;
    height: auto;
    left: -30px;
    margin: 0;
    width: auto
}

.rounded[class*=list-] li:before {
    background: #fff none repeat scroll 0 0;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .1)
}

.rounded[class*=list-] li:hover:before {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -o-transform: scale(1.5);
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.reverse.primary[class*=list-] li:hover:before {
    background-color: var(--color-primary);
    color: #fff
}

.rounded[class*=list-] li {
    padding: 10px 0 10px 45px
}

.rounded[class*=list-] li {
    padding: 10px 0 10px 45px
}

.rounded.border {
    border: 0 !important
}

.rounded.border[class*=list-] li:before {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
    line-height: 28px
}

[class*=list-].list-box>li {
    background: #fff none repeat scroll 0 0;
    border-radius: 4px;
    color: #000;
    margin-bottom: 6px;
    padding: 10px 20px 10px 50px
}

[class*=list-].list-box>li:before {
    background: var(--color-primary) none repeat scroll 0 0;
    border-radius: 4px 0 0 4px;
    color: #fff;
    display: table-cell;
    height: 100%;
    padding: 10px 0;
    text-align: center;
    top: 0;
    vertical-align: middle;
    width: 40px
}

[class*=list-].list-box>li {
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1)
}

[class*=list-].style1.list-box>li {
    margin-left: 42px;
    border-radius: 0 4px 4px 0;
    padding: 10px 20px 10px 20px
}

[class*=list-].style1.list-box>li:before {
    left: -42px;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s
}

[class*=list-].style2.list-box>li {
    padding: 10px 20px 10px 60px
}

[class*=list-].style2.list-box>li:after {
    font-family: FontAwesome;
    content: "\f0da";
    left: 39px;
    position: absolute;
    top: 50%;
    color: var(--color-primary);
    font-size: 18px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

[class*=list-].list-box>li:hover,
[class*=list-].style2.list-box>li:hover {
    transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05)
}

[class*=list-].style1.list-box>li:hover {
    transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1)
}

[class*=list-].style1.list-box>li:hover:before {
    transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2)
}

[class*=list-].list-box.reverse>li:before {
    color: var(--color-primary);
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, .1)
}

.list-check.circle li:before {
    content: "\f00c";
    font-family: FontAwesome;
    width: 32px;
    height: 32px;
    background: #fff;
    border-radius: 30px;
    line-height: 32px;
    text-align: center
}

.list-check.circle.primary li:before {
    background: var(--color-primary);
    color: #fff
}

.list-check.circle.white li:before {
    background-color: #fff;
    color: #000
}

.list-check.circle li {
    padding: 8px 0 8px 45px;
    font-size: 18px;
    font-weight: 400;
    transition: all .5s
}

.list-check.circle li:hover:before {
    content: "\f005";
    transition: all .5s;
    transform: rotate(180deg)
}

.dlab-tabs .tab-pane {
    padding: 20px 0
}

.dlab-tabs .nav-tabs>li>a {
    background: #f6f7f8;
    color: #3d474a;
    -webkit-border-radius: 0;
    border-radius: 0;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 0;
    display: inline-block;
    padding: 10px 20px
}

.dlab-tabs .nav-tabs>li>a.active,
.dlab-tabs .nav-tabs>li>a.active:focus,
.dlab-tabs .nav-tabs>li>a.active:hover {
    background-color: #fff;
    border-color: #ebedf2;
    border-bottom: 1px solid #fff
}

.dlab-tabs .nav>li>a:focus,
.dlab-tabs .nav>li>a:hover {
    background-color: transparent
}

.dlab-tabs .nav li a:hover {
    border: 1px solid transparent
}

.dlab-tabs .nav-tabs>li>a i {
    color: #efbb20;
    margin-right: 5px
}

.dlab-tabs.bg-tabs .nav-tabs>li>a {
    border: 1px solid #ebedf2;
    background-color: #efefef;
    margin-right: 2px
}

.dlab-tabs.bg-tabs .nav-tabs>li>a.active {
    border-bottom: 1px solid transparent;
    background-color: #fff
}

.dlab-tabs.vertical.bg-tabs .nav-tabs>li>a {
    border: 1px solid #ebedf2
}

.dlab-tabs.vertical.bg-tabs .nav-tabs>li>a.active {
    border-right: 1px solid transparent
}

.dlab-tabs.vertical.right.bg-tabs .nav-tabs>li>a {
    border: 1px solid #ebedf2
}

.dlab-tabs.vertical.right.bg-tabs .nav-tabs>li>a.active {
    border-left: 1px solid transparent
}

.dlab-tabs.border {
    border: 0
}

.dlab-tabs.border-top {
    border-top: 0
}

.dlab-tabs.border-top .nav-tabs>li>a.active {
    border-top: 2px solid var(--color-primary);
    padding: 9px 15px 10px
}

.dlab-tabs.border .tab-pane {
    padding: 20px;
    border: 1px solid #ebedf2;
    margin-top: -1px
}

.dlab-tabs.vertical .nav-tabs {
    float: left;
    width: 200px;
    border-bottom: none;
    border-right: 1px solid #ebedf2
}

.dlab-tabs.vertical .tab-pane {
    padding: 10px 0 10px 20px
}

.dlab-tabs.vertical .nav-tabs li {
    float: none;
    margin-right: -1px;
    display: block;
    width: 100%
}

.dlab-tabs.vertical .nav-tabs li a {
    margin-right: 0;
    border-right: none;
    display: block;
    width: 100%
}

.dlab-tabs.vertical .nav-tabs li a.active {
    border-bottom: 1px solid #ebedf2
}

.dlab-tabs.vertical .tab-content {
    border-left: 1px solid #ebedf2;
    margin-left: 199px
}

.dlab-tabs.vertical.border .tab-pane {
    padding: 20px;
    margin-left: -1px
}

.dlab-tabs.vertical.right .nav-tabs {
    border-left: 1px solid #ebedf2;
    border-right: none;
    float: right
}

.dlab-tabs.vertical.right .nav-tabs li {
    margin-right: 0;
    margin-left: -1px
}

.dlab-tabs.vertical.right .nav-tabs li a {
    border-right: 1px solid transparent;
    border-left: none
}

.dlab-tabs.vertical.right .nav-tabs li a.active {
    border-right: 1px solid #ebedf2;
    border-left: none
}

.dlab-tabs.vertical.right .tab-content {
    border-left: none;
    border-right: 1px solid #ebedf2;
    margin-right: 199px;
    margin-left: 0
}

.dlab-tabs.vertical.right .tab-pane {
    padding: 10px 20px 10px 0
}

.tabs-site-button .nav-tabs {
    border: 0
}

.tabs-site-button .nav-tabs li a {
    padding: 12px 20px
}

.tabs-site-button .nav-tabs li a,
.tabs-site-button .nav-tabs li a:focus,
.tabs-site-button .nav-tabs li a:hover {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0 none
}

.tabs-site-button .nav-tabs li a.active,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover {
    background-color: var(--color-primary);
    color: #fff;
    border: 0
}

.tabs-site-button .nav-tabs li a.active i {
    color: #fff
}

@media only screen and (max-width:767px) {
    .tabs-site-button .nav-tabs li a {
        padding: 13px 20px
    }
}

.dlab-tabs.vertical.right.border .tab-pane {
    padding: 20px;
    margin-right: -1px
}

.panel {
    box-shadow: none;
    -webkit-box-shadow: none
}

.dlab-accordion .panel {
    border: none;
    border-radius: 0;
    margin-bottom: -1px
}

.acod-head {
    position: relative
}

.acod-title {
    margin-top: 0;
    margin-bottom: 0
}

.acod-head .fa {
    margin-right: 5px
}

.acod-head a {
    display: block;
    padding: 17px 40px 17px 25px;
    border: 1px solid #ddd;
    border-radius: 4px
}

.acod-head a,
.acod-head a.collapsed:hover,
.acod-head a:hover {
    color: #efbb20
}

.acod-head a.collapsed,
.acod-head a.collapsed:after {
    color: #3d474a
}

.acod-head a:after {
    font-family: themify;
    content: "\e622";
    float: right;
    color: #efbb20;
    font-size: 16px;
    letter-spacing: -2px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

.acod-head a.collapsed:after {
    content: "\e61a";
    font-size: 16px;
    font-family: themify
}

.acod-body {
    border-left: 1px solid #ebedf2;
    border-right: 1px solid #ebedf2;
    border-bottom: 1px solid #ebedf2;
    border-top: 1px solid transparent;
    margin-top: -1px;
    border-radius: 0 0 4px 4px
}

.acod-content {
    margin: 20px
}

.accdown1 .acod-head a:after {
    content: "\e64b"
}

.accdown1 .acod-head a.collapsed:after {
    content: "\e649"
}

.dlab-accordion.rounded .panel:first-child .acod-head a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.dlab-accordion.rounded .panel:last-child .acod-head a.collapsed {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.dlab-accordion.rounded .panel:last-child .acod-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.dlab-accordion.rounded .panel:last-child .acod-body.in {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.dlab-accordion.space .panel {
    margin-bottom: 5px
}

.dlab-accordion.rounded.space .panel .acod-head a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.dlab-accordion.rounded.space .panel .acod-head a.collapsed {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.dlab-accordion.rounded.space .panel .acod-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
}

.dlab-accordion.bg-title .acod-head a {
    background-color: #f7f8fa
}

.dlab-accordion.no-cover .acod-body {
    border: none
}

.dlab-accordion.no-cover .acod-content {
    padding-left: 20px;
    margin-right: 10px;
    border-left: 2px solid #e8e8e8;
    position: relative
}

.dlab-accordion.no-cover .acod-content:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #e8e8e8
}

.dlab-accordion.border-bottom .acod-head a {
    border-left: none;
    border-right: none;
    border-top: none;
    padding-left: 0
}

.dlab-accordion.primary .acod-head a {
    background-color: #0046c6;
    color: #fff;
    border: 0
}

.dlab-accordion.primary .acod-head a:hover {
    color: #fff
}

.dlab-accordion.primary .acod-head a:after {
    color: #fff
}

.dlab-accordion.primary.active-bg .acod-head .collapsed {
    background-color: #fff
}

.dlab-accordion.primary.active-bg .acod-head .collapsed,
.dlab-accordion.primary.active-bg .acod-head a.collapsed:after {
    color: #000
}

.dlab-accordion.primary.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2
}

.dlab-accordion.gradient-1 .acod-head a {
    background: #0046c6;
    background: -moz-linear-gradient(45deg, #0046c6 0, #c927f1 100%);
    background: -webkit-linear-gradient(45deg, #0046c6 0, #c927f1 100%);
    background: linear-gradient(45deg, #0046c6 0, #c927f1 100%);
    color: #fff;
    border: 0
}

.dlab-accordion.gradient-2 .acod-head a {
    background: #ff90e1;
    background: -moz-linear-gradient(45deg, #ff90e1 0, #28b1ff 100%);
    background: -webkit-linear-gradient(45deg, #ff90e1 0, #28b1ff 100%);
    background: linear-gradient(45deg, #ff90e1 0, #28b1ff 100%);
    color: #fff;
    border: 0
}

.dlab-accordion.gradient-1 .acod-head a:hover,
.dlab-accordion.gradient-2 .acod-head a:hover {
    color: #fff
}

.dlab-accordion.gradient-1 .acod-head a:after,
.dlab-accordion.gradient-2 .acod-head a:after {
    color: #fff
}

.dlab-accordion.gradient-1.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-2.active-bg .acod-head .collapsed {
    background: #fff
}

.dlab-accordion.gradient-1.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-1.active-bg .acod-head a.collapsed:after,
.dlab-accordion.gradient-2.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-2.active-bg .acod-head a.collapsed:after {
    color: #000
}

.dlab-accordion.gradient-1.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-2.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2
}

.dlab-accordion.green .acod-head a {
    background-color: #24a88d;
    color: #fff;
    border: 0
}

.dlab-accordion.green .acod-head a:hover {
    color: #fff
}

.dlab-accordion.green .acod-head a:after {
    color: #fff
}

.dlab-accordion.green.active-bg .acod-head .collapsed {
    background-color: #fff
}

.dlab-accordion.green.active-bg .acod-head .collapsed,
.dlab-accordion.green.active-bg .acod-head a.collapsed:after {
    color: #000
}

.dlab-accordion.green.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2
}

.dlab-accordion.yellow .acod-head a {
    background-color: #efb822;
    color: #fff;
    border: 0
}

.dlab-accordion.yellow .acod-head a:hover {
    color: #fff
}

.dlab-accordion.yellow .acod-head a:after {
    color: #fff
}

.dlab-accordion.yellow.active-bg .acod-head .collapsed {
    background-color: #fff
}

.dlab-accordion.yellow.active-bg .acod-head .collapsed,
.dlab-accordion.yellow.active-bg .acod-head a.collapsed:after {
    color: #000
}

.dlab-accordion.yellow.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2
}

.dlab-accordion.red .acod-head a {
    background-color: #ef516c;
    color: #fff;
    border: 0
}

.dlab-accordion.red .acod-head a:hover {
    color: #fff
}

.dlab-accordion.red .acod-head a:after {
    color: #fff
}

.dlab-accordion.red.active-bg .acod-head .collapsed {
    background-color: #fff
}

.dlab-accordion.red.active-bg .acod-head .collapsed,
.dlab-accordion.red.active-bg .acod-head a.collapsed:after {
    color: #000
}

.dlab-accordion.red.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2
}

.dlab-accordion.blue .acod-head a {
    background-color: #36a3f7;
    color: #fff;
    border: 0
}

.dlab-accordion.blue .acod-head a:hover {
    color: #fff
}

.dlab-accordion.blue .acod-head a:after {
    color: #fff
}

.dlab-accordion.blue.active-bg .acod-head .collapsed {
    background-color: #fff
}

.dlab-accordion.blue.active-bg .acod-head .collapsed,
.dlab-accordion.blue.active-bg .acod-head a.collapsed:after {
    color: #000
}

.dlab-accordion.blue.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2
}

.dlab-accordion.border-bottom.no-border .acod-head a {
    border: none
}

.dlab-accordion.no-border .acod-content {
    margin-top: 10px;
    margin-bottom: 10px
}

.no-gap .acod-title a {
    border: 0 none;
    padding: 10px 0
}

.no-gap .collapse {
    border: 0 none
}

.no-gap .acod-content {
    margin: 10px 0 10px
}

.acco-sm .acod-head a {
    font-size: 12px;
    padding: 5px 30px 5px 15px
}

.acco-sm .acod-head a:after {
    font-size: 16px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

.acco-lg .acod-head a {
    padding: 18px 40px 18px 25px;
    font-size: 18px
}

.acco-lg .acod-head a:after {
    font-size: 34px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

.acco-xl .acod-head a {
    font-size: 22px;
    padding: 25px 50px 25px 30px
}

.acco-xl .acod-head a:after {
    font-size: 34px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

.ow-post-info {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ebebeb
}

.ow-post-title .post-title {
    margin: 0 0 10px
}

.ow-post-text {
    margin-bottom: 10px
}

.ow-post-text p:last-child {
    margin: 0
}

.ow-post-readmore {
    margin-bottom: 10px
}

.ow-post-meta {
    margin-bottom: 10px
}

.ow-post-meta ul {
    margin: 0 -4px;
    list-style: none
}

.ow-post-meta ul li {
    padding: 0;
    display: inline-block
}

.ow-post-meta li:after {
    content: "/";
    display: inline-block;
    font-weight: 400;
    margin-left: 5px;
    opacity: .5
}

.ow-post-meta li:last-child:after {
    display: none
}

.ow-post-meta a {
    color: #a9a9a9
}

.ow-post-meta li i {
    color: #7b7b7b;
    margin: 0 5px
}

.ow-post-tags {
    border-top: 1px solid #e9e9e9;
    padding-top: 10px
}

.ow-post-tags .post-comment {
    float: left;
    font-weight: 700;
    text-transform: uppercase
}

.ow-post-tags .post-comment a {
    color: #a9a9a9
}

.ow-post-tags .post-tags {
    margin: 0 -3px;
    list-style: none
}

.ow-post-tags .post-tags a {
    border: 1px solid #ebebeb;
    padding: 2px 8px 1px;
    color: #777;
    margin: 0 3px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 11px
}

.ow-post-tags .post-tags a:active,
.ow-post-tags .post-tags a:focus,
.ow-post-tags .post-tags a:hover {
    border: 1px solid #0098a6;
    color: #0098a6
}

.no-image-blog.date-style-2 .ow-post-info {
    padding-top: 70px
}

.ow-event-info {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ebebeb
}

.ow-event-title .event-title {
    margin: 0 0 10px
}

.ow-event-text {
    margin-bottom: 10px
}

.ow-event-text p:last-child {
    margin: 0
}

.ow-event-readmore {
    margin-bottom: 10px
}

.ow-event-meta ul {
    margin: 0
}

.ow-event-meta ul li {
    color: #a9a9a9;
    font-weight: 600;
    display: inline-block;
    padding-left: 20px
}

.ow-event-meta ul li i {
    color: #7b7b7b
}

.no-image-event.date-style-2 .ow-post-info {
    padding-top: 70px
}

.ow-client-logo {
    background-color: #fff;
    display: table;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
    transition: all .8s;
    -moz-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
    -webkit-transition: all .8s
}

.ow-client-logo:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2)
}

.client-logo-carousel .owl-stage-outer {
    padding: 10px 0
}

.client-logo {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 10px
}

.owl-carousel .ow-client-logo img {
    height: 100px;
    max-height: 100%;
    width: auto;
    display: inline-block
}

.blog-info {
    background-color: rgba(0, 0, 0, .9);
    left: 0;
    list-style: outside none none;
    margin: 0;
    padding: 7px 15px;
    position: absolute;
    top: -34px;
    width: 100%
}

.blog-info li {
    display: inline-block;
    padding: 0 5px 0 5px;
    line-height: 14px;
    border-right: 1px solid
}

.blog-info li a:hover,
.blog-info li:hover {
    color: #fff
}

.testimonial-pic {
    background: #fff;
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    border: 5px solid #fff
}

.testimonial-pic.radius {
    border-radius: 100%;
    -webkit-border-radius: 100%
}

.testimonial-pic.radius img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    -webkit-border-radius: 100%
}

.testimonial-pic.shadow {
    -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, .35);
    -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, .35);
    box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, .35)
}

.testimonial-pic.style1 {
    border: 0 none;
    height: 100px;
    width: 100px
}

.quote-left,
.quote-right {
    position: relative
}

.quote-left:before,
.quote-right:after {
    font-family: FontAwesome;
    position: absolute;
    z-index: 10;
    top: 15px
}

.quote-left:before {
    content: "\f10d";
    left: 0
}

.quote-right:after {
    content: "\f10e";
    left: auto;
    right: 0
}

.testimonial-text {
    padding: 15px;
    position: relative;
    font-size: 15px;
    font-weight: 400
}

.testimonial-detail {
    padding: 5px
}

.testimonial-name {
    font-size: 18px;
    font-weight: 500
}

.testimonial-position {
    font-size: 14px
}

.testimonial-name,
.testimonial-position {
    display: block
}

.testimonial-text p:last-child {
    margin: 0
}

.testimonial-bg {
    color: #fff
}

.testimonial-1 {
    text-align: center
}

.testimonial-1 .testimonial-position {
    color: #efbb20
}

.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position {
    display: block
}

.testimonial-1 .quote-left:before {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    background: #efbb20;
    color: #fff;
    text-align: center;
    left: 0;
    top: 65px;
    border-radius: 100%;
    -webkit-border-radius: 100%
}

.testimonial-2 .testimonial-text,
.testimonial-8 .testimonial-text {
    background-color: #f4f5f7;
    padding: 25px
}

.testimonial-2 .testimonial-text:after,
.testimonial-8 .testimonial-text:after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 25px solid transparent;
    border-top: 25px solid #f4f5f7
}

.testimonial-2 .testimonial-detail,
.testimonial-8 .testimonial-detail {
    padding: 25px 0 20px 20px
}

.testimonial-2 .testimonial-pic,
.testimonial-8 .testimonial-pic {
    float: left;
    margin-right: 15px;
    width: 80px;
    height: 80px
}

.testimonial-2 .quote-left:before {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    background: #efbb20;
    color: #fff;
    text-align: center;
    left: 0;
    top: 65px;
    border-radius: 100%;
    -webkit-border-radius: 100%
}

.testimonial-2 .testimonial-name,
.testimonial-2 .testimonial-position,
.testimonial-8 .testimonial-name,
.testimonial-8 .testimonial-position {
    padding: 2px 0
}

.testimonial-2 .testimonial-name,
.testimonial-8 .testimonial-name {
    padding-top: 15px
}

.testimonial-2 .testimonial-position {
    color: #efbb20
}

.testimonial-2 .quote-left:before {
    top: 50px
}

.testimonial-2.testimonial-bg .testimonial-text {
    color: #777;
    background-color: rgba(255, 255, 255, .9)
}

.testimonial-2.testimonial-bg .testimonial-text:after {
    border-top-color: rgba(255, 255, 255, .9)
}

.testimonial-3 {
    text-align: center
}

.testimonial-3 .quote-left:before {
    position: static;
    font-size: 40px;
    color: #efbb20
}

.testimonial-3 .testimonial-position {
    color: #efbb20
}

.testimonial-3 .testimonial-name,
.testimonial-3 .testimonial-position {
    display: inline-block
}

.testimonial-4 {
    background-color: #282a3c;
    position: relative;
    padding: 30px 40px 30px 10px;
    margin-left: 140px;
    min-height: 150px
}

.testimonial-4:after {
    background-color: #282a3c;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: #efbb20;
    content: "";
    position: absolute;
    left: -80px;
    top: 0;
    height: 100%;
    width: 80%;
    z-index: -1;
    -moz-transform: skewX(-25deg);
    -webkit-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg)
}

.testimonial-4 .testimonial-pic {
    position: absolute;
    top: 30px;
    left: 0;
    margin-left: -110px
}

.testimonial-4 [class*=quote-] {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px;
    border-color: #efbb20;
    border-top-color: transparent !important;
    border-left-color: transparent !important
}

.testimonial-4 [class*=quote-]:after,
.testimonial-4 [class*=quote-]:before {
    top: 0;
    right: -20px;
    font-size: 20px;
    color: #fff
}

.testimonial-4 .testimonial-detail,
.testimonial-4 .testimonial-text {
    padding: 0
}

.testimonial-4 .testimonial-text {
    margin-bottom: 10px
}

.testimonial-4 .testimonial-name,
.testimonial-4 .testimonial-position {
    display: inline-block
}

.testimonial-4 .testimonial-name:after {
    content: "/";
    color: #efbb20;
    margin: 0 2px 0 5px
}

.testimonial-4 .testimonial-name {
    text-transform: uppercase
}

.testimonial-4 .testimonial-position {
    font-style: italic;
    color: #bebebe
}

.testimonial-4 .testimonial-pic {
    border-color: #efbb20;
    border-width: 3px;
    width: 90px;
    height: 90px
}

@media only screen and (max-width:480px) {
    .testimonial-4 .testimonial-pic {
        height: 80px;
        width: 80px
    }

    .testimonial-4:after {
        transform: skewX(-10deg);
        -moz-transform: skewX(-10deg);
        -webkit-transform: skewX(-10deg);
        -o-transform: skewX(-10deg)
    }

    .testimonial-4 {
        padding: 25px 10px 25px 0;
        margin-left: 110px
    }
}

.testimonial-5 .testimonial-text {
    border: 2px solid #e6e6e6;
    padding: 30px
}

.testimonial-5 .testimonial-detail {
    padding: 0 0 0 20px;
    margin-top: -35px
}

.testimonial-5 .testimonial-pic {
    box-shadow: 0 0 0 2px #e6e6e6;
    width: 70px;
    height: 70px
}

.testimonial-5 .testimonial-text p {
    font-size: 16px;
    line-height: 30px
}

.testimonial-5 .info p {
    display: inline-block;
    position: relative;
    top: -10px;
    padding: 0 10px
}

.testimonial-6 .quote-left:before {
    left: 15px
}

.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
    color: #b5b5b5;
    font-size: 18px
}

.testimonial-6 .testimonial-text {
    padding: 25px 35px 70px 40px
}

.testimonial-6 .testimonial-detail {
    position: relative;
    padding: 10px 15px;
    top: -30px;
    width: 85%
}

.testimonial-6 .testimonial-pic {
    position: absolute;
    right: 20px;
    top: -50px;
    z-index: 2
}

.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
    color: #b5b5b5;
    font-size: 18px
}

.testimonial-6 .quote-left:before {
    left: 15px
}

.testimonial-6 .quote-right:after {
    right: 15px;
    bottom: 20px;
    top: auto
}

.testimonial-6 .testimonial-detail:after {
    background-color: inherit;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: -23px;
    top: 0;
    transform: skewX(35deg);
    -moz-transform: skewX(35deg);
    -webkit-transform: skewX(35deg);
    -o-transform: skewX(35deg);
    width: 50px;
    z-index: 1
}

.testimonial-section {
    position: relative;
    z-index: 1
}

.testimonial-section:after {
    background: #f2f2f2 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1
}

.testimonial-section .col-md-6 {
    padding-left: 30px;
    padding-right: 30px
}

.testimonial-section .row {
    margin-left: -30px;
    margin-right: -30px
}

.testimonial-7 .testimonial-text {
    border: 2px solid #e6e6e6;
    padding: 18px
}

.testimonial-7 .testimonial-detail {
    padding: 0 0 0 20px;
    margin-top: -35px
}

.testimonial-7 .testimonial-pic {
    box-shadow: 0 0 0 2px #e6e6e6;
    width: 70px;
    height: 70px
}

.testimonial-7 .testimonial-text p {
    font-size: 16px;
    line-height: 30px
}

.testimonial-7 .info p {
    display: inline-block;
    position: relative;
    top: -10px;
    padding: 0 10px
}

.testimonial-7 .owl-controls .owl-nav {
    bottom: 0;
    margin-top: 0;
    position: absolute;
    right: 0
}

.testimonial-7 .owl-next,
.testimonial-7 .owl-prev {
    background-color: #ccc;
    border: 0 none
}

@media only screen and (max-width:991px) {
    .testimonial-section:after {
        left: 100%
    }

    .testimonial-section {
        color: #fff
    }

    .testimonial-section .col-md-6 {
        padding-left: 15px;
        padding-right: 15px
    }

    .testimonial-section .row {
        margin-left: -15px;
        margin-right: -15px
    }
}

@media only screen and (max-width:767px) {
    .testimonial-section:after {
        content: none
    }
}

.testimonial-8 .testimonial-text {
    background: #fff none repeat scroll 0 0;
    border-radius: 6px 6px 6px 0;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, .1);
    transition: all .8s;
    -moz-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
    -webkit-transition: all .8s
}

.testimonial-8 .testimonial-text p {
    font-size: 16px;
    line-height: 30px
}

.testimonial-8 .testimonial-text:after {
    border-top: 25px solid #fff;
    border-left: 0 solid transparent
}

.testimonial-8 {
    padding: 15px
}

.testimonial-box.owl-theme .owl-dots {
    margin-top: 30px;
    position: unset;
    right: auto;
    text-align: center;
    top: auto;
    transform: translateY(-50%);
    width: auto
}

.owl-item.active.center .testimonial-text {
    background-color: #ff3b68;
    color: #fff
}

.owl-item.active.center .testimonial-8 .testimonial-text:after {
    border-top: 15px solid #ff3b68;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
    -moz-transition: all .8s;
    transition: all .8s
}

.testimonial-8 .testimonial-text:before {
    content: "\f10e";
    bottom: -10px;
    color: #000;
    font-family: FontAwesome;
    font-size: 80px;
    opacity: .05;
    position: absolute;
    right: 10px;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
    -moz-transition: all .8s;
    transition: all .8s
}

.testimonial-9 {
    background-color: #fff;
    padding: 50px 25px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    text-align: center
}

.testimonial-9 .testimonial-text {
    padding: 0
}

.testimonial-10 .testimonial-name,
.testimonial-9 .testimonial-name {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #000
}

.testimonial-10 .testimonial-position,
.testimonial-9 .testimonial-position {
    text-transform: uppercase;
    color: #888;
    font-size: 12px;
    font-style: inherit
}

.testimonial-10 .testimonial-position p,
.testimonial-9 .testimonial-position p {
    line-height: 26px
}

.testimonial-10 .testimonial-text,
.testimonial-9 .testimonial-text {
    color: #505050;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 15px
}

.testimonial-9 .testimonial-pic {
    margin-bottom: 15px
}

.testimonial-10 {
    margin: auto;
    max-width: 600px;
    padding-left: 150px;
    position: relative
}

.testimonial-10 .testimonial-text {
    padding: 0
}

.testimonial-10 .testimonial-pic {
    border: 0 none;
    height: 130px;
    left: 0;
    position: absolute;
    width: 130px
}

.testimonial-seven.owl-btn-3 .owl-next,
.testimonial-seven.owl-btn-3 .owl-prev {
    margin: 0 !important
}

.pricingtable-inner {
    text-align: center
}

.pricingtable-price {
    padding: 20px 20px;
    background-color: #fff;
    border: 1px solid #e9e9e9
}

.pricingtable-bx {
    color: #282a3c;
    font-size: 45px;
    font-weight: 500
}

.pricingtable-type {
    font-size: 20px;
    text-transform: uppercase;
    font-size: 16px
}

.pricingtable-type:before {
    content: "/";
    margin-right: 3px
}

.pricingtable-title {
    background-color: #fff;
    padding: 15px;
    text-transform: uppercase
}

.pricingtable-title * {
    margin: 0;
    color: #fff;
    font-weight: 800
}

.pricingtable-features {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #e9e9e9
}

.pricingtable-features li {
    padding: 12px;
    border-bottom: 1px solid #e9e9e9
}

.pricingtable-features li i {
    margin: 0 3px
}

.pricingtable-features li:nth-child(even) {
    background-color: #fff
}

.pricingtable-features li:last-child {
    border-bottom: none
}

.pricingtable-footer {
    margin-top: -1px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e9e9e9
}

.pricingtable-highlight {
    margin: -20px 0;
    -webkit-box-shadow: 0 0 30px 10px rgba(0, 0, 0, .05);
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, .05);
    position: relative;
    z-index: 99
}

.pricingtable-highlight .pricingtable-price {
    padding: 30px 20px
}

.pricingtable-highlight .pricingtable-footer {
    padding: 30px 20px
}

.no-col-gap .pricingtable-wrapper {
    margin-left: -1px
}

.pricingtable-wrapper.style1 {
    border: 1px solid #f0f0f0;
    padding: 10px 10px 40px;
    margin-left: -1px
}

.pricingtable-wrapper.style1 .pricingtable-icon i {
    color: #000;
    font-size: 48px;
    line-height: 60px
}

.pricingtable-wrapper.style1 .pricingtable-price {
    background-color: #fff;
    border: 0
}

.pricingtable-wrapper.style1 .pricingtable-bx {
    font-size: 35px;
    font-weight: 300
}

.pricingtable-wrapper.style1 .pricingtable-features {
    border: 0
}

.pricingtable-wrapper.style1 .pricingtable-features li {
    background: #fff none repeat scroll 0 0;
    border: 0 none;
    color: #909090;
    font-weight: 400;
    padding: 8px 0;
    text-align: left
}

.pricingtable-wrapper.style1 .pricingtable-features {
    width: 80%;
    margin: auto
}

.pricingtable-wrapper.style1 .pricingtable-features li i {
    border: 2px solid;
    border-radius: 20px;
    display: block;
    float: right;
    font-size: 11px;
    height: 20px;
    line-height: 16px;
    text-align: center;
    width: 20px
}

.pricingtable-wrapper.style1 .button-md {
    font-weight: 400;
    padding: 12px 35px
}

.pricingtable-wrapper.style1 .pricingtable-type {
    text-transform: capitalize
}

.pricingtable-wrapper.style2 {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .1);
    padding: 30px
}

.pricingtable-wrapper.style2 .pricingtable-price {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border: 0
}

.pricingtable-wrapper.style2 .pricingtable-bx {
    background: rgba(0, 0, 0, .1);
    border-radius: 10px;
    color: #333;
    display: inline-block;
    font-size: 13px;
    margin-top: 20px;
    padding: 15px 25px;
    margin-bottom: 10px
}

.pricingtable-wrapper.style2 .pricingtable-bx span {
    font-size: 28px;
    font-weight: 900
}

.pricingtable-wrapper.style2.active {
    margin: -30px -10px 0;
    padding: 60px 30px;
    position: relative;
    z-index: 1
}

.pricingtable-wrapper.style2.active .pricingtable-bx {
    color: #fff
}

.counter {
    position: relative
}

.counter-style-1 .counter,
.counter-style-2 .counter,
.counter-style-3 .counter {
    font-size: 60px;
    font-weight: 900;
    line-height: 1.2
}

.counter-style-1 .counter-text,
.counter-style-2 .counter-text {
    font-size: 16px;
    font-weight: 500
}

.counter-style-1 .icon {
    font-size: 45px;
    margin-right: 10px
}

.counter-style-3.rounded {
    border: 5px solid;
    border-radius: 200px;
    height: 200px;
    width: 200px
}

.counter-style-3 .counter-text {
    font-size: 18px;
    font-weight: 400;
    margin: 0 auto
}

.counter-style-4 {
    border: 5px solid rgba(0, 0, 0, .1);
    padding: 10px 10px 20px
}

.counter-style-4 .counter {
    font-size: 70px;
    font-weight: 500
}

.counter-style-4 .counter-text {
    font-size: 18px;
    font-weight: 500
}

.counter-style-4 .icon {
    font-size: 45px;
    margin-right: 10px
}

.alert {
    border-radius: 4px;
    padding: 15px 20px
}

.alert.alert-sm {
    padding: 5px 15px;
    font-size: 12px
}

.alert.alert-lg {
    padding: 25px 15px;
    font-size: 16px
}

.alert.alert-xl {
    padding: 35px 15px;
    font-size: 18px
}

.alert[class*=alert-] i {
    margin-right: 8px
}

.alert.no-radius {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0
}

.alert.no-bg {
    background-color: transparent;
    border-width: 2px
}

.alert[class*=alert-] ul {
    padding-left: 25px;
    margin-top: 10px
}

.alert[class*=alert-] ul li:before {
    color: #a94442
}

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724
}

.alert-info {
    background-color: #cce5ff;
    border-color: #b8daff;
    color: #004085
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24
}

.close {
    color: #000;
    float: right;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    opacity: .3;
    text-shadow: none;
    vertical-align: middle
}

.modal-header {
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0
}

.modal-footer {
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px
}

.modal-header {
    background: #efbb20;
    padding: 15px 25px
}

.modal-header .modal-title {
    color: #fff
}

.modal .modal-body {
    padding: 25px
}

.modal-xlg {
    width: 1000px
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a {
    font-weight: 600
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a span.childcat {
    font-weight: 400;
    color: #7c7c7c
}

@media only screen and (max-width:1024px) {
    .modal-xlg {
        width: auto;
        margin-left: 15px;
        margin-right: 15px
    }
}

.dlab-social-icon {
    display: inline-block;
    margin: 0 -3px;
    padding: 0;
    text-align: center
}

.dlab-social-icon li {
    display: inline-block;
    padding: 0;
    font-size: 12px
}

.dlab-social-icon li a {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 4px
}

.dlab-social-icon li .fa {
    vertical-align: middle
}

.dlab-social-icon.border {
    border: 0 !important
}

.dlab-social-icon.border li {
    padding: 0 3px
}

.dlab-social-icon.border li a {
    border: 1px solid rgba(255, 255, 255, .2)
}

.dlab-social-links.dark li {
    padding: 0 3px
}

.dlab-social-icon.dark li a {
    border: 1px solid #777;
    color: #777;
    border-radius: 2px;
    -webkit-border-radius: 2px
}

.dlab-share-icon li {
    border: none;
    width: 36px;
    display: block;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0
}

.dlab-share-icon li a {
    color: #fff
}

.dlab-share-icon li i {
    border-right: none;
    font-size: 14px;
    color: #fff;
    width: 36px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    text-align: center
}

.dlab-share-icon li.fb {
    background: #354d89
}

.dlab-share-icon li.fb i {
    background: #3a5799
}

.dlab-share-icon li.gp {
    background: #d34b2b
}

.dlab-share-icon li.gp i {
    background: #e35736
}

.dlab-share-icon li.tw {
    background: #029fdf
}

.dlab-share-icon li.tw i {
    background: #00abf0
}

.dlab-share-icon li.dig {
    background: #1d61aa
}

.dlab-share-icon li.dig i {
    background: #2b6fb8
}

.dlab-share-icon li.lin {
    background: #0176be
}

.dlab-share-icon li.lin i {
    background: #0082ca
}

.dlab-share-icon li.pin {
    background: #ac0104
}

.dlab-share-icon li.pin i {
    background: #bd0e15
}

.dlab-share-icon li:hover {
    width: 90px
}

.breadcrumb-row {
    background: #f9f9f9;
    padding: 20px 0
}

.breadcrumb-row ul {
    margin: 0
}

.breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    font-weight: 400
}

.breadcrumb-row ul li:after {
    content: "\f105";
    margin-left: 7px;
    font-family: fontawesome
}

.breadcrumb-row ul li:last-child {
    color: #676767
}

.breadcrumb-row ul li:last-child:after {
    display: none
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 10px
}

.table.borderless>tbody>tr>td,
.table.borderless>tbody>tr>th {
    border: none
}

.dataTable .sorting_asc .checkbox {
    margin-right: 0
}

.overlay-black-dark,
.overlay-black-light,
.overlay-black-middle,
.overlay-gradient-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-primary,
.overlay-primary-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-white-dark,
.overlay-white-light,
.overlay-white-middle {
    position: relative
}

.overlay-black-dark:after,
.overlay-black-light:after,
.overlay-black-middle:after,
.overlay-gradient-dark:after,
.overlay-gradient-light:after,
.overlay-gradient-middle:after,
.overlay-primary-dark:after,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary:after,
.overlay-white-dark:after,
.overlay-white-light:after,
.overlay-white-middle:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.overlay-black-dark:after,
.overlay-black-light:after,
.overlay-black-middle:after {
    background: #000
}

.overlay-gradient-dark:after,
.overlay-gradient-light:after,
.overlay-gradient-middle:after {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .65) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .65) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .65) 100%)
}

.overlay-white-dark:after,
.overlay-white-light:after,
.overlay-white-middle:after {
    background: #fff
}

.overlay-black-light:after {
    opacity: .3
}

.overlay-black-middle:after {
    opacity: .5
}

.overlay-black-dark:after {
    opacity: .8
}

.overlay-gradient-light:after {
    opacity: .3
}

.overlay-gradient-middle:after {
    opacity: .5
}

.overlay-gradient-dark:after {
    opacity: .8
}

.overlay-white-light:after {
    opacity: .5
}

.overlay-white-middle:after {
    opacity: .7
}

.overlay-white-dark:after {
    opacity: .8
}

.overlay-primary-light:after {
    opacity: .3
}

.overlay-primary-middle:after {
    opacity: .5
}

.overlay-primary-dark:after,
.overlay-primary:after {
    opacity: .8
}

.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid {
    position: relative;
    z-index: 1
}

.overlay-bx {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 10;
    opacity: 0;
    visibility: hidden
}

.overlay-bx .align-m,
.overlay-icon {
    height: auto;
    left: 50%;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 10px
}

.overlay-bx .align-b {
    height: auto;
    left: 0;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: auto;
    bottom: 0;
    width: 100%;
    transform: translate(0);
    -o-transform: translate(0);
    -moz-transform: translate(0);
    -webkit-transform: translate(0)
}

.overlay-icon a {
    display: inline-block;
    padding: 0;
    margin: 0 2px
}

.overlay-icon a i {
    background-color: #fff
}

.dlab-box-bx:hover .overlay-bx a>i,
.dlab-media:hover .overlay-bx a>i,
.overlay-bx:hover a>i {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

.dlab-box:hover .overlay-bx,
.dlab-media:hover .overlay-bx,
.overlay-bx:hover,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
    opacity: 1;
    visibility: visible
}

.overlay-text {
    width: 100%;
    position: absolute;
    padding: 20px;
    color: #fff;
    left: 0;
    bottom: 20px
}

.dlab-address-bx {
    display: table;
    text-align: left;
    padding: 20px 20px 20px 25px;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 14px;
    vertical-align: middle;
    font-weight: 600
}

.dlab-address-bx .fa {
    position: absolute;
    top: 22px;
    left: 0;
    font-size: 22px
}

.col-md-3 .overlay-text {
    padding: 5px
}

.col-md-4 .overlay-text {
    padding: 20px
}

.dlab-address2-bx {
    display: table;
    background: #eaeaea;
    padding: 5px 10px;
    margin-bottom: 10px
}

.icon-bx-ho [class*=icon-bx-] {
    z-index: 1
}

.icon-bx-ho [class*=icon-bx-]:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    background: var(--color-primary);
    z-index: -1;
    transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s
}

.icon-bx-ho:hover [class*=icon-bx-]:after {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg)
}

.icon-bx-lg,
.icon-bx-md,
.icon-bx-sm,
.icon-bx-xl,
.icon-bx-xs {
    display: inline-block;
    text-align: center;
    border-radius: 3px
}

.icon-bx-xl {
    width: 150px;
    height: 150px;
    line-height: 150px
}

.icon-bx-lg {
    width: 120px;
    height: 120px;
    line-height: 120px
}

.icon-bx-md {
    width: 100px;
    height: 100px;
    line-height: 100px
}

.icon-bx-sm {
    width: 80px;
    height: 80px;
    line-height: 80px
}

.icon-bx-xs {
    width: 40px;
    height: 40px;
    line-height: 40px
}

.icon-bx-lg.radius,
.icon-bx-md.radius,
.icon-bx-sm.radius,
.icon-bx-xl.radius,
.icon-bx-xs.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
    border-style: solid
}

.border-1 {
    border-width: 1px
}

.border-2 {
    border-width: 2px
}

.border-3 {
    border-width: 3px
}

.border-4 {
    border-width: 4px
}

.border-5 {
    border-width: 5px
}

.br-col-w1,
.dlab-info.br-col-w1[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-w1,
.icon-bx-wraper.bx-style-2.br-col-w1 {
    border-color: rgba(255, 255, 255, .15)
}

.br-col-w2,
.dlab-info.br-col-w2[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-w2,
.icon-bx-wraper.bx-style-2.br-col-w2 {
    border-color: rgba(255, 255, 255, .3)
}

.br-col-w3,
.dlab-info.br-col-w3[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-w3,
.icon-bx-wraper.bx-style-2.br-col-w3 {
    border-color: rgba(255, 255, 255, .5)
}

.br-col-w4,
.dlab-info.br-col-w4[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-w4,
.icon-bx-wraper.bx-style-2.br-col-w4 {
    border-color: rgba(255, 255, 255, .7)
}

.br-col-w5,
.dlab-info.br-col-w5[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-w5,
.icon-bx-wraper.bx-style-2.br-col-w5 {
    border-color: rgba(255, 255, 255, .9)
}

.br-col-b1,
.dlab-info.br-col-b1[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-b1,
.icon-bx-wraper.bx-style-2.br-col-b1 {
    border-color: rgba(0, 0, 0, .15)
}

.br-col-b2,
.dlab-info.br-col-b2[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-b2,
.icon-bx-wraper.bx-style-2.br-col-b2 {
    border-color: rgba(0, 0, 0, .3)
}

.br-col-b3,
.dlab-info.br-col-b3[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-b3,
.icon-bx-wraper.bx-style-2.br-col-b3 {
    border-color: rgba(0, 0, 0, .5)
}

.br-col-b4,
.dlab-info.br-col-b4[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-b4,
.icon-bx-wraper.bx-style-2.br-col-b4 {
    border-color: rgba(0, 0, 0, .7)
}

.br-col-b5,
.dlab-info.br-col-b5[class*=border-],
.icon-bx-wraper.bx-style-1.br-col-b5,
.icon-bx-wraper.bx-style-2.br-col-b5 {
    border-color: rgba(0, 0, 0, .9)
}

.op0 {
    opacity: 0
}

.op1 {
    opacity: .1
}

.op2 {
    opacity: .2
}

.op3 {
    opacity: .3
}

.op4 {
    opacity: .4
}

.op5 {
    opacity: .5
}

.op6 {
    opacity: .6
}

.op7 {
    opacity: .7
}

.op8 {
    opacity: .8
}

.op9 {
    opacity: .9
}

.icon-bx-lg i,
.icon-bx-md i,
.icon-bx-sm i,
.icon-bx-xl i,
.icon-bx-xs i {
    vertical-align: middle
}

.icon-bx-xl i {
    font-size: 80px
}

.icon-bx-lg i {
    font-size: 60px
}

.icon-bx-md i {
    font-size: 45px
}

.icon-bx-sm i {
    font-size: 30px
}

.icon-bx-xs i {
    font-size: 24px
}

.icon-bx-lg img,
.icon-bx-md img,
.icon-bx-sm img,
.icon-bx-xl img,
.icon-bx-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto
}

.icon-bx-xl img {
    height: 80px
}

.icon-bx-lg img {
    height: 55px
}

.icon-bx-md img {
    height: 40px
}

.icon-bx-sm img {
    height: 45px
}

.icon-bx-xs img {
    height: 20px
}

.icon-lg,
.icon-md,
.icon-sm,
.icon-xl,
.icon-xs {
    display: inline-block;
    text-align: center
}

.icon-xl {
    width: 100px
}

.icon-lg {
    width: 80px
}

.icon-md {
    width: 60px
}

.icon-sm {
    width: 40px
}

.icon-xs {
    width: 30px
}

.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xl i,
.icon-xs i {
    vertical-align: middle
}

.icon-xl i {
    font-size: 80px;
    line-height: 80px
}

.icon-lg i {
    font-size: 60px
}

.icon-md i {
    font-size: 45px
}

.icon-sm i {
    font-size: 30px
}

.icon-xs i {
    font-size: 20px
}

.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xl img,
.icon-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto
}

.icon-xl img {
    height: 80px
}

.icon-lg img {
    height: 70px
}

.icon-md img {
    height: 50px
}

.icon-sm img {
    height: 30px
}

.icon-xs img {
    height: 20px
}

.icon-bx-wraper {
    position: relative
}

.icon-bx-wraper .dlab-tilte {
    margin-top: 0
}

.icon-bx-wraper .after-titile-line {
    margin-bottom: 10px
}

.icon-bx-wraper p:last-child {
    margin: 0
}

.icon-content {
    overflow: hidden
}

.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-xs {
    float: left;
    margin-right: 20px
}

.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-xs {
    float: left;
    margin-right: 10px
}

.icon-bx-wraper.right {
    text-align: right
}

.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-xs {
    float: right;
    margin-left: 20px
}

.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-xs {
    float: right;
    margin-left: 10px
}

.icon-bx-wraper.center {
    text-align: center
}

[class*=icon-bx-][class*=bg-]:not(.bg-white) a {
    color: #fff
}

[class*=icon-bx-][class*=border-] {
    display: table;
    line-height: normal;
    margin-left: auto;
    margin-right: auto
}

[class*=icon-bx-][class*=border-] .icon-cell {
    display: table-cell;
    vertical-align: middle
}

.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
    border-width: 1px;
    border-style: solid;
    border-color: #ebedf2;
    font-size: 14px
}

.icon-bx-wraper.bx-style-2.center [class*=icon-bx-],
.icon-bx-wraper.bx-style-2.left [class*=icon-bx-],
.icon-bx-wraper.bx-style-2.right [class*=icon-bx-] {
    position: absolute
}

.icon-bx-wraper.bx-style-2.center [class*=icon-bx-] {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
    margin-left: -75px
}

.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
    margin-left: -60px
}

.icon-bx-wraper.bx-style-2.center .icon-bx-md {
    margin-left: -50px
}

.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
    margin-left: -40px
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
    margin-left: -20px
}

.icon-bx-wraper.bx-style-2.left [class*=icon-bx-] {
    position: absolute;
    top: auto;
    left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.icon-bx-wraper.bx-style-2.right [class*=icon-bx-] {
    position: absolute;
    top: auto;
    right: 0;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%)
}

.dlab-box,
.dlab-info,
.dlab-tilte,
.dlab-tilte-inner {
    position: relative
}

.dlab-tilte-inner {
    display: inline-block
}

.dlab-tilte-inner.skew-title:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: -1;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg)
}

.dlab-box[class*=border-],
.dlab-info[class*=border-] {
    border-color: #eee
}

.dlab-info.border-1,
.dlab-info.border-2,
.dlab-info.border-3,
.dlab-info.border-4,
.dlab-info.border-5 {
    border-top: none
}

.left-border,
.right-border {
    position: relative
}

.left-border:before,
.right-border:before {
    content: "";
    position: absolute;
    top: 5px;
    width: 1px;
    height: 90%;
    background: #ccc
}

.right-border:before {
    right: 0
}

.dlab-media {
    background-color: #fff;
    position: relative
}

.dlab-media img,
.dlab-post-media img {
    width: 100%;
    height: auto
}

.dlab-box-bg {
    overflow: hidden
}

.dlab-box-bg .site-button {
    overflow: unset
}

.dlab-box-bg .glyph-icon {
    font-size: 50px
}

.dlab-box-bg .icon-bx-wraper {
    background-color: #fff
}

.icon-box-btn .site-button {
    background-color: #eee;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 15px;
    color: #000 !important
}

.dlab-box-bg {
    background-size: cover;
    box-shadow: 20px 10px 40px 0 rgba(0, 0, 0, .05)
}

.dlab-box-bg.active .icon-bx-wraper {
    background-color: rgba(0, 0, 0, .7)
}

.dlab-box-bg .icon-box-btn .site-button,
.dlab-box-bg .icon-content .dlab-tilte,
.dlab-box-bg .icon-content p,
.dlab-box-bg .text-primary {
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.dlab-box-bg.active .icon-box-btn .site-button,
.dlab-box-bg.active .icon-content .dlab-tilte,
.dlab-box-bg.active .icon-content p,
.dlab-box-bg.active .text-primary {
    color: #fff;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.dlab-box-bg .icon-box-btn .site-button {
    border-radius: 0;
    color: var(--color-primary);
    font-weight: 600;
    margin-bottom: 0
}

.content-box-head {
    border-radius: 50px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    margin-top: -46px;
    padding: 12px 0;
    position: relative;
    z-index: 1
}

.cvf-universal-pagination .pagination,
.pagination-bx .pagination {
    margin: 0
}

.pagination {
    padding: 10px 0
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.pagination>li>a,
.pagination>li>span {
    background-color: #fff;
    border: 1px solid #efefef;
    color: #767676;
    padding: 8px 14px;
    font-weight: 400;
    font-size: 14px
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    border-color: transparent;
    color: #fff
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    border-color: transparent
}

.pagination>.next>a,
.pagination>.previous>a {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500
}

.pagination-sm .pagination>.next>a,
.pagination-sm .pagination>li>a,
.pagination-sm .pagination>li>span .pagination-sm .pagination>.previous>a {
    font-size: 12px;
    padding: 6px 12px
}

.pagination-lg .pagination>.next>a,
.pagination-lg .pagination>li>a,
.pagination-lg .pagination>li>span .pagination-lg .pagination>.previous>a {
    font-size: 18px;
    padding: 10px 20px
}

.pagination-bx.primary .pagination>li>a,
.pagination-bx.primary .pagination>li>span {
    background-color: var(--color-primary);
    color: #fff;
    margin: 0 1px;
    border: 0
}

.pagination-bx.primary .pagination>li.active>a,
.pagination-bx.primary .pagination>li.active>span,
.pagination-bx.primary .pagination>li>a:hover,
.pagination-bx.primary .pagination>li>span:hover {
    background-color: var(--color-hover)
}

.pagination-bx.rounded .pagination>li>a,
.pagination-bx.rounded .pagination>li>span {
    border-radius: 30px;
    margin: 0 1px
}

.pagination-bx.rounded-sm .pagination>li>a,
.pagination-bx.rounded-sm .pagination>li>span {
    border-radius: 4px;
    margin: 0 1px
}

.pagination-bx .pull-right {
    float: right
}

.pagination-bx .pagination {
    width: 100%
}

.pagination-bx.gray .pagination>li>a,
.pagination-bx.gray .pagination>li>span {
    background-color: #f6f7f8;
    margin: 0 1px;
    border: 0
}

.pagination-bx.gray .pagination>li.active>a,
.pagination-bx.gray .pagination>li.active>span,
.pagination-bx.gray .pagination>li>a:hover,
.pagination-bx.gray .pagination>li>span:hover {
    background-color: var(--color-primary);
    box-shadow: 2px 2px 8px 0 rgba(26, 188, 156, .5);
    color: #fff
}

@media only screen and (max-width:991px) {
    .pagination-bx {
        margin-bottom: 30px
    }
}

@media only screen and (max-width:576px) {

    .pagination-lg .pagination>.next>a,
    .pagination-lg .pagination>li>a,
    .pagination-lg .pagination>li>span .pagination-lg .pagination>.previous>a {
        font-size: 16px;
        padding: 8px 15px
    }
}

.dataTables_paginate ul.pagination {
    margin: 0
}

.dataTables_paginate .pagination>li {
    padding: 0
}

.bg-black-1,
.bg-black-1 .footer-bottom,
.bg-black-1 .footer-top {
    background-color: #191f23
}

.bg-black-2,
.bg-black-2 .footer-bottom,
.bg-black-2 .footer-top {
    background-color: #222324
}

.bg-black-3,
.bg-black-3 .footer-bottom,
.bg-black-3 .footer-top {
    background-color: #222324
}

.bgpt1,
.bgpt1 .footer-bottom,
.bgpt1 .footer-top {
    background-image: url(../images/pattern/pt1.png);
    background-size: auto
}

.bg-black-3 {
    background-color: #191f23
}

.bg-black-4 {
    background-color: #191f23
}

.footer-fixed .site-footer {
    position: fixed;
    width: 100%;
    bottom: 0
}

.footer-fixed .page-content {
    position: relative;
    z-index: 1;
    background-color: #fff
}

footer h1,
footer h1 a,
footer h2,
footer h2 a,
footer h3,
footer h3 a,
footer h4,
footer h4 a,
footer h5,
footer h5 a,
footer h6,
footer h6 a {
    color: rgba(255, 255, 255, .7)
}

footer,
footer b,
footer p,
footer strong {
    color: rgba(255, 255, 255, .7)
}

footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
    color: rgba(255, 255, 255, .7)
}

footer a {
    color: rgba(255, 255, 255, .7)
}

footer p a {
    color: rgba(255, 255, 255, .7)
}

footer a:active,
footer a:focus,
footer a:hover {
    color: rgba(255, 255, 255, .7)
}

footer .dlab-post-meta ul li,
footer .widget_archive ul li a,
footer .widget_categories ul li a,
footer .widget_meta ul li a,
footer .widget_nav_menu li a,
footer .widget_pages ul li a,
footer .widget_recent_comments ul li a,
footer .widget_recent_entries ul li a,
footer .widget_services ul li a {
    color: rgba(255, 255, 255, .7)
}

footer p {
    margin-bottom: 10px
}

footer li,
footer p {
    font-size: 15px;
    line-height: 1.5
}

footer#footer {
    background-position: center;
    background-size: cover
}

footer p {
    line-height: 24px;
    margin-bottom: 15px
}

footer .widget ul {
    list-style: none;
    margin-top: 5px
}

footer .widget_archive ul li,
footer .widget_categories ul li,
footer .widget_meta ul li,
footer .widget_nav_menu li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
    border-bottom: 1px dashed rgba(102, 102, 102, .3)
}

.footer-top {
    background: #191f23;
    background-size: unset;
    background-position: center;
    padding: 80px 0 20px
}

.footer-bottom {
    background-color: #191f23;
    padding: 25px 0;
    color: rgba(255, 255, 255, .7);
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, .05)
}

.footer-bottom ul {
    margin: 0
}

.footer-full .container-fluid {
    padding-left: 50px;
    padding-right: 50px
}

footer.text-white h1,
footer.text-white h1 a,
footer.text-white h2,
footer.text-white h2 a,
footer.text-white h3,
footer.text-white h3 a,
footer.text-white h4,
footer.text-white h4 a,
footer.text-white h5,
footer.text-white h5 a,
footer.text-white h6,
footer.text-white h6 a {
    color: #fff
}

footer.text-white,
footer.text-white b,
footer.text-white p,
footer.text-white strong {
    color: #fff
}

footer.text-white .footer-bottom,
footer.text-white .footer-bottom a,
footer.text-white .widget_services ul li a,
footer.text-white a,
footer.text-white a:visited,
footer.text-white h1 a,
footer.text-white h2 a,
footer.text-white h3 a,
footer.text-white h4 a,
footer.text-white h5 a,
footer.text-white h6 a,
footer.text-white p a {
    color: #fff
}

footer.text-white .input-group .form-control {
    color: #fff
}

footer.text-white .dlab-quik-search .form-control::-moz-placeholder {
    color: #fff
}

footer.text-white .dlab-quik-search .form-control:-moz-placeholder {
    color: #fff
}

footer.text-white .dlab-quik-search .form-control:-ms-input-placeholder {
    color: #fff
}

footer.text-white .dlab-quik-search .form-control::-webkit-input-placeholder {
    color: #fff
}

.footer-title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    color: #fff;
    line-height: 1.2
}

.footer-title:after {
    content: "";
    background: var(--color-primary);
    width: 40px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0
}

@media only screen and (max-width:991px) {
    .col-md-3.col-sm-6.footer-col-4:nth-child(3) {
        clear: both
    }

    .footer-full .text-center,
    .footer-full .text-left,
    .footer-full .text-right {
        text-align: center;
        margin-bottom: 30px
    }

    .footer-full .text-right {
        margin-bottom: 0
    }
}

@media only screen and (max-width:767px) {
    .footer-title {
        margin-bottom: 15px
    }

    .footer-clear {
        display: block !important;
        clear: both
    }

    #footer .footer-4-col {
        width: 100%
    }

    .footer-bottom [class*=clo-] {
        width: 100%
    }
}

.subscribe-form input {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgba(255, 255, 255, .5);
    border-radius: 4px !important;
    height: 50px;
    padding: 10px 20px;
    margin-right: 5px;
    color: #fff;
    font-size: 15px
}

.subscribe-form input.radius-no {
    border-radius: 0 !important
}

.subscribe-form input.radius-sm {
    border-radius: 4px !important
}

.subscribe-form .site-button {
    height: 50px
}

.subscribe-form .input-group-btn {
    padding-left: 10px
}

.dzSubscribe.style1 [class*=site-button] {
    border-radius: 0 4px 4px 0
}

.dzSubscribe.style1 .form-control {
    border: 0;
    border-radius: 4px 0 0 4px;
    height: 42px;
    padding: 0 20px;
    font-size: 15px
}

.dzSubscribe.style1 .input-group-addon {
    padding: 0;
    border: 0;
    background-color: transparent
}

.map-footer .footer-top {
    padding: 0
}

button.scroltop {
    background: var(--color-primary);
    border-color: #efbb20;
    border-radius: 4px;
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    color: #fff !important;
    cursor: pointer;
    display: none;
    height: 50px;
    line-height: 50px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 50px;
    z-index: 999;
    box-shadow: -4px 4px 24px -10px;
    transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s
}

button.scroltop.style1:after,
button.scroltop.style2:after,
button.scroltop.style3:after {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: '';
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    background: inherit;
    -webkit-transition: -webkit-transform .2s, opacity .3s;
    -moz-transition: -moz-transform .2s, opacity .3s;
    transition: transform .2s, opacity .3s
}

button.scroltop.style1:hover:after {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0
}

button.scroltop.radius {
    border-radius: 100%
}

button.scroltop.white {
    background: #fff;
    color: var(--color-primary) !important
}

button.scroltop.style2:after {
    border: 1px dashed var(--color-primary);
    background: 0 0;
    transform: scale(1.2);
    transition: all .5s
}

button.scroltop.style2:hover:after {
    -webkit-animation: spinAround 9s linear infinite;
    -moz-animation: spinAround 9s linear infinite;
    animation: spinAround 9s linear infinite
}

@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0) scale(1.2)
    }

    to {
        -webkit-transform: rotate(360deg) scale(1.2)
    }
}

@-moz-keyframes spinAround {
    from {
        -moz-transform: rotate(0) scale(1.2)
    }

    to {
        -moz-transform: rotate(360deg) scale(1.2)
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0) scale(1.2)
    }

    to {
        transform: rotate(360deg) scale(1.2)
    }
}

.icon-up {
    overflow: hidden
}

.icon-bx-wraper:hover .icon-up i,
.icon-bx-wraper:hover .icon-up img,
.icon-up:hover i,
.service-box.style2:hover i {
    -webkit-animation: toTopFromBottom .8s forwards;
    -moz-animation: toTopFromBottom .8s forwards;
    animation: toTopFromBottom .8s forwards
}

@-webkit-keyframes toTopFromBottom {
    49% {
        -webkit-transform: translateY(-100%)
    }

    50% {
        opacity: 0;
        -webkit-transform: translateY(100%)
    }

    51% {
        opacity: 1
    }
}

@-moz-keyframes toTopFromBottom {
    49% {
        -moz-transform: translateY(-100%)
    }

    50% {
        opacity: 0;
        -moz-transform: translateY(100%)
    }

    51% {
        opacity: 1
    }
}

@keyframes toTopFromBottom {
    49% {
        transform: translateY(-100%)
    }

    50% {
        opacity: 0;
        transform: translateY(100%)
    }

    51% {
        opacity: 1
    }
}

button.scroltop.style3:after {
    background: 0 0;
    border: 1px solid
}

button.scroltop.style3:hover:after {
    transform: scale(1.2)
}

button.scroltop.style4 {
    transition: all .5s
}

button.scroltop.style4:hover {
    transform: scale(1.1)
}

.footer-white {
    border-top: 0 solid #e0e0e0
}

.footer-white .form-control {
    border-color: rgba(0, 0, 0, .1)
}

.footer-white .footer-top {
    background-color: #fdfdfd
}

.footer-white .footer-bottom {
    background-color: #efefef
}

footer.footer-white,
footer.footer-white .footer-bottom,
footer.footer-white .footer-bottom a,
footer.footer-white b,
footer.footer-white h1,
footer.footer-white h1 a,
footer.footer-white h2,
footer.footer-white h2 a,
footer.footer-white h3,
footer.footer-white h3 a,
footer.footer-white h4,
footer.footer-white h4 a,
footer.footer-white h5,
footer.footer-white h5 a,
footer.footer-white h6,
footer.footer-white h6 a,
footer.footer-white p,
footer.footer-white strong,
footer.footer-white.site-footer .widget_services ul li a {
    color: #000
}

.footer-white .footer-bottom {
    border: 0
}

.footer-white .widget_getintuch i {
    background-color: rgba(0, 0, 0, 0);
    border: 0 solid transparent;
    font-size: 20px
}

.footer-contact-info {
    border-bottom: 1px solid rgba(255, 255, 255, .1)
}

.footer-gray .footer-bottom,
.footer-gray .footer-top {
    background-color: #292929
}

.site-footer .icon-bx-wraper {
    overflow: hidden
}

.site-footer .icon-content {
    overflow: unset
}

.site-footer .dlab-tilte {
    position: inherit
}

.site-footer .icon-sm {
    bottom: -10px;
    opacity: .15;
    position: absolute;
    right: 0;
    width: auto;
    z-index: 2
}

.site-footer .icon-sm i {
    font-size: 90px;
    transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s
}

.site-footer .icon-bx-wraper:hover .icon-sm i {
    transform: scale(1.5) rotate(20deg);
    -webkit-transform: scale(1.5) rotate(20deg);
    -moz-transform: scale(1.5) rotate(20deg);
    -o-transform: scale(1.5) rotate(20deg);
    -ms-transform: scale(1.5) rotate(20deg)
}

@media only screen and (max-width:575px) {

    .footer-bottom .text-center,
    .footer-bottom .text-left,
    .footer-bottom .text-right {
        text-align: center !important
    }
}

.page-wraper {
    background: #f8f8f8
}

.content-area {
    padding-top: 80px;
    padding-bottom: 50px
}

.section-full {
    position: relative
}

.section-head {
    margin-bottom: 50px
}

.section-head.no-margin {
    margin-bottom: 0
}

.section-head h1,
.section-head h2,
.section-head h3 {
    margin-top: 0
}

.title-small {
    display: block;
    color: #494949;
    margin-bottom: 15px
}

.section-head p {
    padding-top: 10px;
    color: #494949
}

.text-center.section-head p {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px
}

.text-white {
    color: #fff
}

.text-white .title-small,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p {
    color: #fff
}

@media only screen and (max-width:1024px) {
    .section-full {
        background-attachment: scroll !important
    }
}

@media only screen and (max-width:991px) {
    .page-content {
        margin-top: 0
    }
}

.page-notfound {
    padding: 50px 0
}

.page-notfound strong {
    font-size: 200px;
    font-weight: 900;
    line-height: 140px;
    display: block
}

.page-notfound strong i {
    font-size: 190px;
    margin: 0 10px
}

.page-notfound .searchform {
    position: relative;
    text-align: left;
    max-width: 420px;
    margin: 30px auto
}

@media only screen and (max-width:767px) {

    .page-notfound strong,
    .page-notfound strong i {
        font-size: 120px
    }
}

.sticky {
    clear: both
}

.gallery-caption {
    clear: both
}

.bypostauthor {
    clear: both
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
}

.dlab-gallery-listing {
    list-style: none;
    margin-bottom: 0
}

.dlab-gallery-box {
    margin-bottom: 30px
}

#bg {
    background-attachment: fixed;
    background-size: cover
}

.boxed .page-wraper {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1)
}

.boxed .rev_slider_wrapper,
.boxed .tp-banner-container {
    left: 0 !important;
    width: 100% !important
}

.boxed .tp-rightarrow {
    right: 0 !important
}

.boxed .tp-leftarrow {
    left: 0 !important
}

.boxed.footer-fixed .site-footer {
    left: 50%;
    width: 1200px;
    margin: 0 -600px
}

.boxed .is-fixed .main-bar {
    left: 50%;
    width: 1200px;
    margin: 0 -600px
}

.boxed .is-fixed.header-curve .logo-header:after {
    right: auto;
    left: -15px;
    width: 90%
}

.frame {
    padding: 30px
}

.frame .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1)
}

.frame .rev_slider_wrapper,
.frame .tp-banner-container {
    left: 0 !important;
    width: 100% !important
}

.frame .tp-rightarrow {
    right: 0 !important
}

.frame .tp-leftarrow {
    left: 0 !important
}

.frame .is-fixed .main-bar {
    left: 0;
    width: 100%;
    margin: 0
}

.frame .is-fixed.header-curve .logo-header:after {
    right: auto;
    left: -15px;
    width: 90%
}

.frame button.scroltop {
    right: 50px;
    bottom: 50px
}

@media only screen and (max-width:1024px) {
    .frame {
        padding: 0
    }

    .frame button.scroltop {
        right: 15px;
        bottom: 15px
    }
}

.ow-portfolio-img,
.owl-imgbx {
    position: relative
}

.ow-entry-content {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #eee;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 5px
}

.ow-entry-title {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0
}

.ow-entry-title,
.ow-entry-title a {
    color: #3d474a
}

.ow-entry-text p:last-child {
    margin: 0
}

.btn-quantity {
    width: 150px
}

.product-description.dex-tabs.border-top .nav-tabs>li.active>a {
    border-top: 2px solid #efbb20
}

.product-description.dex-tabs .nav-tabs>li>a i {
    color: #efbb20
}

.dex-tabs.bg-tabs .nav-tabs>li>a {
    background-color: #fcfcfc
}

.btn-quantity.style-1 {
    width: 130px
}

.btn-quantity.style-1 input {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0 none;
    font-size: 14px;
    padding: 0 45px;
    text-align: center
}

.btn-quantity.style-1 .input-group-btn-vertical button:first-child,
.btn-quantity.style-1 .input-group-btn-vertical button:last-child {
    background: #ebebeb none repeat scroll 0 0;
    border: 0 none;
    position: absolute;
    height: 40px;
    top: 0;
    width: 40px;
    padding: 0;
    z-index: 2;
    margin: 0
}

.btn-quantity.style-1 .input-group-btn-vertical button:hover {
    background-color: #d1d1d1
}

.btn-quantity.style-1 .input-group-btn-vertical button:first-child {
    right: 0
}

.btn-quantity.style-1 .input-group-btn-vertical button:last-child {
    left: 0
}

.btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical i {
    color: #909090;
    font-size: 16px;
    position: unset
}

.btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical i {
    top: 12px;
    left: 12px
}

.btn-quantity.style-1 .input-group {
    position: relative
}

.btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical {
    position: absolute;
    width: 100%;
    left: 0
}

ol.commentlist {
    list-style: none;
    margin: 0
}

ol.commentlist li {
    position: relative;
    padding: 0;
    margin-bottom: 20px;
    background: #f7f8fa;
    padding: 20px;
    border: 1px dashed #eee
}

ol.commentlist li img {
    float: left;
    border: 5px solid #fff;
    width: 80px;
    height: auto
}

ol.commentlist li .comment-text {
    padding-left: 100px
}

ol.commentlist li .meta {
    margin-bottom: 5px
}

ol.commentlist li .meta strong {
    font-size: 16px
}

ol.commentlist li .meta .time {
    color: #999;
    display: block;
    font-size: 14px
}

ol.commentlist li .description p {
    margin: 0
}

ol.commentlist li .star-rating {
    position: absolute;
    top: 20px;
    right: 20px
}

.comment-reply-title {
    margin-top: 0
}

.comment-form [class*=comment-form] {
    width: 49%
}

.comment-form label {
    display: block
}

.comment-form [class*=comment-form] input,
.comment-form [class*=comment-form] textarea {
    border: 1px solid #ccc;
    padding: 8px 10px;
    width: 100%
}

.comment-form .form-submit,
.comment-form [class*=comment-form] {
    margin-bottom: 20px
}

.dlab-quik-search {
    background-color: rgba(0, 0, 0, .9) !important;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
    overflow: hidden
}

.dlab-quik-search.On form {
    transition: all .5s ease .5s;
    -moz-transition: all .5s ease .5s;
    -webkit-transition: all .5s ease .5s;
    max-width: 1200px;
    opacity: 1
}

.dlab-quik-search form {
    width: 100%;
    max-width: 100px;
    margin: auto;
    position: relative;
    top: 50%;
    transition: all .5s;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    opacity: 0
}

.dlab-quik-search .form-control {
    padding: 15px 60px 15px 15px;
    width: 100%;
    height: 90px;
    border: none;
    background: 0 0;
    color: #fff;
    font-size: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, .2)
}

.dlab-quik-search .form-control::-moz-placeholder {
    color: #fff
}

.dlab-quik-search .form-control:-moz-placeholder {
    color: #fff
}

.dlab-quik-search .form-control:-ms-input-placeholder {
    color: #fff
}

.dlab-quik-search .form-control::-webkit-input-placeholder {
    color: #fff
}

.dlab-quik-search span {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px 0;
    height: 25px;
    font-size: 20px;
    cursor: pointer
}

.form-box1 .form-control {
    height: 50px;
    padding: 15px 20px;
    font-size: 16px;
    text-transform: capitalize;
    border: 2px solid rgba(0, 0, 0, .05)
}

.form-box1 textarea.form-control {
    height: 150px
}

.form-box1 .form-control:focus,
.form-box1 .form-control:hover {
    border: 2px solid rgba(0, 0, 0, .2)
}

#gmap_canvas {
    width: 100%;
    height: 400px
}

#gmap_canvas img {
    max-width: none !important;
    background: 0 0 !important
}

.fb-btn {
    background-color: #4867aa
}

.tw-btn {
    background-color: #1da1f2
}

.gplus-btn {
    background-color: #dc4a38
}

.pin-btn {
    background-color: #cc2127
}

.link-btn {
    background-color: #0274b3
}

.dlab-social-icon.dlab-social-icon-lg li a {
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px
}

.dlab-media.dlab-media-left .dlab-info-has,
.dlab-media.dlab-media-right .dlab-info-has,
.dlab-media.dlab-media-top .dlab-info-has {
    bottom: auto;
    margin-bottom: 0;
    top: 0
}

.dlab-media.dlab-media-top .dlab-info-has {
    margin-top: -100px
}

.dlab-box:hover .dlab-media.dlab-media-top .dlab-info-has,
.dlab-media.dlab-media-top:hover .dlab-info-has {
    margin-top: 0
}

.dlab-media.dlab-media-left .dlab-info-has {
    left: 0;
    padding: 15px;
    width: auto;
    margin-left: -60px
}

.dlab-media.dlab-media-left:hover .dlab-info-has {
    margin-left: 0
}

.dlab-media.dlab-media-left .dlab-social-icon li {
    display: block;
    margin: 3px 0
}

.dlab-media.dlab-media-right .dlab-info-has {
    right: 0;
    padding: 15px;
    width: auto;
    left: auto;
    margin-right: -60px
}

.dlab-media.dlab-media-right:hover .dlab-info-has {
    margin-right: 0
}

.dlab-media.dlab-media-right .dlab-social-icon li {
    display: block;
    margin: 3px 0
}

.rounded-team .round-box {
    transition: all .8s;
    -webkit-transition: all .8s;
    -moz-transition: all .8s;
    -o-transition: all .8s;
    left: 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 195px;
    height: 195px;
    border-radius: 100%;
    overflow: hidden;
    padding: 10px;
    display: block;
    border: 1px solid #fff
}

.rounded-team .team-mamber {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    border-radius: 100%;
    background-color: #fff
}

.rounded-team .team-mamber img {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5)
}

.rounded-team .sosial-icon-team {
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    border-radius: 100%;
    display: block;
    height: 195px;
    overflow: hidden;
    position: absolute;
    right: 50%;
    top: 0;
    width: 195px;
    margin-right: -97px
}

.rounded-team .sosial-icon-team ul li,
.rounded-team .sosial-icon-team ul.rounde-team-social-icon li a {
    width: 100%;
    display: block;
    color: #fff
}

.rounded-team .sosial-icon-team ul.rounde-team-social-icon {
    margin-right: -5px;
    margin-top: 28px;
    width: 80px;
    float: right
}

.rounded-team {
    position: relative
}

.rounded-team:hover .sosial-icon-team {
    margin-right: -117px;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.rounded-team:hover .round-box {
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    left: -20px
}

.rounded-team .team-info {
    border-color: #d3d3d3;
    margin-top: -27px
}

.rounded-team .team-social-btn ul li a {
    background-color: silver
}

.rounded-team .rounde-team-social-icon li a {
    color: #fff;
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px
}

.team-box-type {
    border: 1px solid rgba(0, 0, 0, .1)
}

.team-info-box,
.team-mamber-img {
    width: 50%
}

.team-info-box {
    padding: 20px
}

.team-info-box,
.team-mamber-img {
    display: table-cell;
    vertical-align: middle
}

.team-box-type,
.team-info-box {
    overflow: hidden
}

.team-p-info {
    list-style: none;
    padding: 0;
    margin: 0
}

.team-p-info li {
    display: block;
    width: 100%;
    font-size: 15px;
    padding: 5px 0;
    color: #404040
}

@media only screen and (max-width:767px) {

    .team-info-box,
    .team-mamber-img {
        width: 100%;
        display: block
    }
}

.sidenav.cs-sidenav {
    width: 800px;
    right: -820px;
    left: auto
}

.sidenav {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 9;
    top: 0;
    left: -320px;
    background-color: #fff;
    overflow-x: hidden;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
    border: 0 none;
    border-radius: 0
}

.sidenav>ul li a {
    padding: 10px 15px;
    display: block;
    border-color: #ebebeb;
    border-image: none;
    border-style: solid;
    border-width: 1px 0;
    display: block;
    margin-bottom: -1px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600
}

.sidenav>ul li a:hover {
    color: #fff
}

.sidenav .logo-header {
    height: auto;
    padding: 15px;
    width: 100%
}

.button-side-nav {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 999;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, .4);
    font-size: 17px;
    cursor: pointer
}

.sidenav .closebtn {
    color: #fff;
    font-size: 24px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 35px;
    z-index: 9
}

#main {
    transition: margin-left .5s;
    -moz-transition: margin-left .5s;
    -webkit-transition: margin-left .5s;
    -o-transition: margin-left .5s
}

@media only screen and (max-width:800px) {
    .sidenav.cs-sidenav {
        width: 100%
    }
}

#particles-js .container {
    z-index: 2
}

.particles-js-canvas-el {
    position: absolute;
    top: 0;
    z-index: 1
}

#loading-area {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 999999999;
    background-image: url(../images/loading.gif);
    background-repeat: no-repeat;
    background-size: 80px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center
}

#loading-area.loading4 {
    background-image: url(../images/loading2.gif);
    background-repeat: no-repeat;
    background-size: 150px;
    background-color: #f5f7f6
}

#loading-area.loading2,
#loading-area.loading3 {
    background-image: none
}

#loading-area.loading5 {
    background-image: url(../images/loading3.gif);
    background-repeat: no-repeat;
    background-size: 80px
}

@-webkit-keyframes square-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0)
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg)
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0)
    }
}

@keyframes square-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0)
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg)
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0)
    }
}

.square-spin>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    width: 50px;
    height: 50px;
    background: var(--color-primary);
    -webkit-animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite
}

@-webkit-keyframes rotate_pacman_half_up {
    0% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }

    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }
}

@keyframes rotate_pacman_half_up {
    0% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }

    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }
}

@-webkit-keyframes rotate_pacman_half_down {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    50% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }
}

@keyframes rotate_pacman_half_down {
    0% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    50% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }
}

@-webkit-keyframes pacman-balls {
    75% {
        opacity: .7
    }

    100% {
        -webkit-transform: translate(-100px, -6.25px);
        transform: translate(-100px, -6.25px)
    }
}

@keyframes pacman-balls {
    75% {
        opacity: .7
    }

    100% {
        -webkit-transform: translate(-100px, -6.25px);
        transform: translate(-100px, -6.25px)
    }
}

.pacman {
    position: relative
}

.pacman>div:nth-child(2) {
    -webkit-animation: pacman-balls 1s -.99s infinite linear;
    animation: pacman-balls 1s -.99s infinite linear
}

.pacman>div:nth-child(3) {
    -webkit-animation: pacman-balls 1s -.66s infinite linear;
    animation: pacman-balls 1s -.66s infinite linear
}

.pacman>div:nth-child(4) {
    -webkit-animation: pacman-balls 1s -.33s infinite linear;
    animation: pacman-balls 1s -.33s infinite linear
}

.pacman>div:nth-child(5) {
    -webkit-animation: pacman-balls 1s 0s infinite linear;
    animation: pacman-balls 1s 0s infinite linear
}

.pacman>div:first-of-type {
    width: 0;
    height: 0;
    border-right: 25px solid transparent;
    border-top: 25px solid var(--color-primary);
    border-left: 25px solid var(--color-primary);
    border-bottom: 25px solid var(--color-primary);
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_up .5s 0s infinite;
    animation: rotate_pacman_half_up .5s 0s infinite;
    position: relative;
    left: -30px
}

.pacman>div:nth-child(2) {
    width: 0;
    height: 0;
    border-right: 25px solid transparent;
    border-top: 25px solid var(--color-primary);
    border-left: 25px solid var(--color-primary);
    border-bottom: 25px solid var(--color-primary);
    border-radius: 25px;
    -webkit-animation: rotate_pacman_half_down .5s 0s infinite;
    animation: rotate_pacman_half_down .5s 0s infinite;
    margin-top: -50px;
    position: relative;
    left: -30px
}

.pacman>div:nth-child(3),
.pacman>div:nth-child(4),
.pacman>div:nth-child(5),
.pacman>div:nth-child(6) {
    background-color: var(--color-primary);
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    width: 10px;
    height: 10px;
    position: absolute;
    -webkit-transform: translate(0, -6.25px);
    transform: translate(0, -6.25px);
    top: 25px;
    left: 70px
}

.dlab-social-icon.dlab-social-icon-lg li a {
    color: #fff;
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px
}

.dlab-social-icon-lg.dlab-social-icon li {
    display: inline-block;
    font-size: 12px;
    padding: 0
}

.dlab-social-icon-lg.dlab-social-icon.border li a {
    border: 1px solid rgba(255, 255, 255, .05)
}

.contact-style-1 input {
    height: 50px
}

.contact-style-1 .site-button {
    height: 50px;
    width: 100%
}

.contact-style-1 .form-control {
    padding: 10px 20px
}

.contact-style-1 .form-control:focus,
.contact-style-1 .form-control:hover {
    border-color: silver
}

.contact-style-1 h2 {
    margin-top: 0
}

.contact-style-1 .border-1 {
    border: 1px solid #eee
}

.contact-style-1 .dlab-social-icon-lg {
    border-top: 1px solid #eee;
    padding-top: 20px;
    text-align: left;
    width: 100%;
    margin: 0
}

.contact-style-1 .dlab-social-icon-lg a {
    text-align: center
}

.contact-style-1 .dlab-social-icon-lg li a:hover {
    background: #fff;
    color: #000;
    border: 1px solid #eee
}

.contact-style-1 .form-group {
    margin-bottom: 25px
}

.shop-form .bootstrap-select .dropdown-toggle,
.shop-form .form-control {
    border-width: 2px;
    border-radius: 0
}

.shop-form .bootstrap-select .dropdown-toggle {
    border-width: 2px !important
}

.shop-form p {
    font-weight: 500;
    color: #a3a3a3
}

.shop-form textarea {
    height: 150px
}

.shop-form .bootstrap-select:focus .dropdown-toggle,
.shop-form .bootstrap-select:hover .dropdown-toggle,
.shop-form .form-control:focus,
.shop-form .form-control:hover {
    border-color: #636363 !important
}

.check-tbl img {
    width: 80px
}

.check-tbl th {
    font-weight: 700;
    font-size: 15px;
    padding: 10px
}

.check-tbl td {
    font-weight: 600;
    padding: 10px
}

.check-tbl.table>tbody>tr>td,
.check-tbl.table>tbody>tr>th,
.check-tbl.table>tfoot>tr>td,
.check-tbl.table>tfoot>tr>th,
.check-tbl.table>thead>tr>td,
.check-tbl.table>thead>tr>th {
    vertical-align: middle
}

.check-tbl .product-item-price,
.check-tbl .product-price,
.check-tbl .product-price-total,
.product-price,
.product-price-total {
    font-weight: 700
}

.product-item-close a {
    border: 1px solid #f0526d;
    color: #f0526d;
    padding: 8px
}

.product-item-close a:hover {
    opacity: .5
}

.item-img {
    overflow: hidden;
    position: relative
}

.item-title {
    font-size: 18px;
    font-weight: 600
}

.item-info {
    padding: 15px 0 15px
}

.item-info.p-a20 {
    padding: 20px
}

.item-img img {
    width: 100%
}

.item-info-in {
    bottom: -20%;
    padding: 15px;
    position: absolute;
    text-align: center;
    width: 100%;
    background-color: #353866;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .2s;
    opacity: 0
}

.item-box:hover .item-info-in {
    opacity: 1;
    bottom: 0
}

.item-info-in ul li {
    display: inline-block
}

.item-info-in ul li a {
    color: #fff;
    display: inline-block;
    font-size: 18px;
    padding: 5px 10px
}

.item-info-in ul,
.item-review {
    margin: auto;
    padding: 0;
    list-style: none
}

.item-review li {
    display: inline-block;
    font-size: 16px;
    color: #ffa800
}

.item-price del {
    font-size: 16px;
    font-weight: 400;
    opacity: .4
}

.item-price {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3
}

.item-info-in.center {
    bottom: auto;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%)
}

.item-box:hover .item-info-in.center {
    top: 50%;
    bottom: auto
}

.item-info-in.no-bg {
    background-color: rgba(0, 0, 0, 0)
}

.item-info-in.no-bg.tb a {
    color: #000
}

.item-info-in.no-bg.tb a:hover {
    color: #000;
    opacity: .8
}

.item-info-in.left {
    width: auto;
    padding: 10px
}

.item-info-in.left ul li {
    display: block
}

.item-info-in.right {
    width: auto;
    padding: 10px;
    right: 0;
    left: auto
}

.item-info-in.right ul li {
    display: block
}

.item-info-in.top {
    width: auto;
    padding: 10px;
    top: -20%;
    left: 0;
    bottom: auto;
    height: auto
}

.item-box:hover .item-info-in.top,
.item-box:hover .item-info-in.top.right,
.item-img:hover .item-info-in.top,
.item-img:hover .item-info-in.top.right {
    top: 0
}

.item-box:hover .item-info-in.top,
.item-img:hover .item-info-in.top {
    bottom: auto
}

.item-info-in.top.right ul li {
    display: block
}

.item-info-in.top.right {
    width: auto;
    padding: 10px;
    top: -20%;
    right: 0;
    bottom: auto;
    left: auto
}

.item-info-in.full {
    height: 100%
}

.item-info-in.full ul li {
    display: block;
    width: 100%
}

.item-info-in.full .center,
.item-info-in.full ul {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%)
}

.item-add {
    outline: 8px solid rgba(255, 255, 255, .5);
    outline-offset: -20px;
    position: relative;
    overflow: hidden
}

.add-box {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%
}

.add-box h2 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0, 0, 0, .2)
}

.add-box h5 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0, 0, 0, .2)
}

.item-add.add-style-2 {
    outline: 8px solid rgba(0, 0, 0, .1)
}

.item-add.add-style-2 img {
    opacity: .05
}

.item-add.add-style-2 .add-box h2,
.item-add.add-style-2 .add-box h5 {
    text-shadow: none
}

.item-add.add-style-3 {
    outline: 0
}

.item-add.add-style-3 h2,
.item-add.add-style-3 h5 {
    text-shadow: none
}

.item-add.add-style-3 h2 {
    font-size: 30px;
    margin: 0
}

.item-add.add-style-3 .add-box .add-box-in {
    background-color: rgba(255, 255, 255, .9);
    padding: 20px 30px;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.item-add.add-style-3 .add-box .add-box-in.black {
    background-color: rgba(0, 0, 0, .8)
}

.item-add.add-style-3:hover .add-box-in {
    padding: 30px 40px
}

.shop-item-rating {
    position: absolute;
    top: 0;
    right: 0
}

.shop-item-rating span {
    display: block;
    text-align: right;
    margin-bottom: 10px
}

.shop-item-tage span {
    font-size: 15px;
    font-weight: 600
}

.shop-item-tage a {
    font-weight: 600;
    margin-left: 7px
}

.outline-hv {
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    outline: 10px solid rgba(255, 255, 255, .5)
}

.item-box:hover .outline-hv,
.outline-hv:hover {
    outline: 10px solid rgba(255, 255, 255, .5);
    outline-offset: -30px
}

.product-item .dlab-info {
    border-top: 1px solid #e9e9e9
}

.product-item .dlab-box {
    border: 1px solid #e9e9e9;
    position: relative
}

.product-item .sale:after {
    border-right: 5px solid transparent;
    border-top: 4px solid #8f0000;
    bottom: -4px;
    content: "";
    position: absolute;
    right: 0
}

.product-item .sale {
    position: absolute;
    right: -5px;
    top: 10px;
    z-index: 2
}

.product-item .overlay-icon {
    bottom: 0;
    top: auto;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.product-item:hover .overlay-icon {
    bottom: 40px;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s
}

.product-item:hover img {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1)
}

.product-item .overlay-icon a i {
    background-color: #efbb20;
    color: #fff
}

.product-item:hover .dlab-box,
.product-item:hover .dlab-info {
    border-color: #d0d0d0
}

.add-product .dlab-info-has.bg-black {
    background-color: rgba(0, 0, 0, .4)
}

.add-product .dlab-info-has {
    top: 0
}

.add-product del {
    opacity: .6
}

.product-service .icon-bx-wraper {
    border: 0
}

.product-item del {
    color: #b0b0b0;
    font-size: 18px;
    margin-top: 0;
    position: relative;
    top: -1px
}

.item-widgets-body,
.item-widgets-box {
    overflow: hidden
}

.item-widgets-box {
    margin-top: 15px
}

.item-widgets-box:first-child {
    margin-top: 0
}

.item-widgets-left {
    padding-right: 15px
}

.item-widgets-left img {
    width: 80px
}

.item-widgets-body,
.item-widgets-left,
.item-widgets-right {
    display: table-cell;
    vertical-align: middle
}

.item-widgets-body .item-price del,
.item-widgets-body .item-title {
    font-size: 15px
}

.product-item-size .btn {
    background-color: #f3f3f3;
    border: 0 solid #e0e0e0;
    border-radius: 0;
    font-size: 14px;
    height: 40px;
    line-height: 38px;
    padding: 0;
    text-align: center;
    width: 40px;
    margin-right: 4px
}

.product-item-size .btn.active {
    background-color: var(--color-primary);
    box-shadow: none;
    color: #fff
}

.product-item-size.size-sm .btn {
    font-size: 11px;
    height: 25px;
    line-height: 23px;
    width: 25px
}

.product-item-size.size-lg .btn {
    font-size: 18px;
    height: 50px;
    line-height: 48px;
    width: 50px
}

.product-item-color .btn {
    border: 0 none;
    border-radius: 4px !important;
    height: 30px;
    opacity: .5;
    padding: 0;
    width: 30px;
    border: 2px solid rgba(0, 0, 0, .1);
    margin-right: 5px
}

.product-item-color .btn.active {
    border: 2px solid rgba(0, 0, 0, .3);
    box-shadow: none;
    opacity: 1
}

.product-item-color.size-sm .btn {
    height: 20px;
    width: 20px
}

.product-gallery.on-show-slider {
    position: relative
}

.sticky-md-top,
.sticky-sm-top,
.sticky-top,
.sticky-top>.side-bar {
    position: sticky;
    position: -webkit-sticky;
    top: 100px
}

@media only screen and (max-width:991px) {
    .sticky-top {
        position: unset
    }
}

@media only screen and (max-width:767px) {
    .sticky-md-top {
        position: unset
    }

    .check-tbl .btn-quantity {
        width: 50px
    }
}

@media only screen and (max-width:575px) {
    .sticky-sm-top {
        position: unset
    }

    .product-item-time {
        display: none
    }

    .shop-cart-2-head .dropdown .form-control {
        width: 66px
    }
}

.download-file ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.download-file ul li {
    display: block;
    margin-bottom: 10px
}

.download-file ul li a {
    background: #e0e0e0 none repeat scroll 0 0;
    color: #000;
    display: block;
    padding: 15px 20px
}

.download-file ul li a span {
    margin-right: 10px
}

.site-footer .widget_getintuch {
    padding-top: 0
}

.content-inner {
    padding-top: 80px;
    padding-bottom: 50px
}

.content-inner-1 {
    padding-top: 80px;
    padding-bottom: 0
}

.content-inner-2 {
    padding-top: 80px;
    padding-bottom: 80px
}

.content-inner-3 {
    padding-top: 100px;
    padding-bottom: 70px
}

.testimonial-five .owl-controls {
    margin-top: 0
}

.dis-tbl {
    display: table
}

.dis-tbl-cell {
    display: table-cell;
    vertical-align: middle
}

.dis-tbl-cell-t {
    display: table-cell;
    vertical-align: top
}

.dis-tbl-cell-b {
    display: table-cell;
    vertical-align: bottom
}

.img-cover {
    object-fit: cover;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%
}

.row.sp80,
.sp80 {
    margin-left: -40px;
    margin-right: -40px
}

.row.sp80 [class*=col-],
.sp80 [class*=col-] {
    padding-left: 40px;
    padding-right: 40px
}

.row.sp60,
.sp60 {
    margin-left: -30px;
    margin-right: -30px
}

.row.sp60 [class*=col-],
.sp60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px
}

.row.sp40,
.sp40 {
    margin-left: -20px;
    margin-right: -20px
}

.row.sp40 [class*=col-],
.sp40 [class*=col-] {
    padding-left: 20px;
    padding-right: 20px
}

.row.sp20,
.sp20 {
    margin-left: -9px;
    margin-right: -9px
}

.row.sp20 [class*=col-],
.sp20 [class*=col-] {
    padding-left: 9px;
    padding-right: 9px
}

.row.sp15,
.sp15 {
    margin-left: -7.5px;
    margin-right: -7.5px
}

.row.sp15 [class*=col-],
.sp15 [class*=col-] {
    padding-left: 7.5px;
    padding-right: 7.5px
}

.row.sp10,
.sp10 {
    margin-left: -5px;
    margin-right: -5px
}

.row.sp10 [class*=col-],
.sp10 [class*=col-] {
    padding-left: 5px;
    padding-right: 5px
}

.row.spno,
.spno {
    margin-left: 0;
    margin-right: 0
}

.row.spno [class*=col-],
.spno [class*=col-] {
    padding-left: 0;
    padding-right: 0
}

@media only screen and (max-width:767px) {
    .content-area {
        padding-top: 30px
    }

    .page-content {
        padding-bottom: 30px
    }

    .footer-top {
        padding-top: 40px
    }

    .site-footer .widget {
        margin-bottom: 25px
    }

    .section-full:last-child {
        margin-bottom: -30px
    }

    .content-inner {
        padding-bottom: 20px;
        padding-top: 30px
    }

    .content-inner-1 {
        padding-top: 30px;
        padding-bottom: 0
    }

    .content-inner-2 {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .section-head {
        margin-bottom: 25px
    }

    .dlab-project-left {
        padding-top: 30px
    }

    .testimonial-section {
        padding-top: 30px
    }

    .testimonial-section .owl-controls {
        margin-top: 20px
    }

    .section-head p {
        padding-top: 0
    }

    .h3 {
        font-size: 28px;
        margin-top: 10px
    }

    .about-service .icon-bx-wraper:nth-child(2) {
        margin-top: 0
    }

    .side-bar {
        margin-bottom: 30px
    }

    .dlab-post-title .post-title {
        margin-top: 10px
    }

    .footer-info-box .newsletter {
        margin-bottom: 10px
    }

    ol.commentlist li .star-rating {
        position: unset
    }

    .comment-form [class*=comment-form] {
        width: 100%
    }

    .pricingtable-wrapper {
        margin-bottom: 30px
    }

    .pricingtable-highlight {
        margin: 0
    }

    .disnone-md {
        display: none
    }
}

.slide-item {
    position: relative
}

.slide-item .slide-content {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.slide-item-img img {
    object-fit: cover
}

.slide-content-box {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transition: all 1s ease .5s;
    -webkit-transition: all 1s ease .5s;
    -o-transition: all 1s ease .5s;
    -moz-transition: all 1s ease .5s
}

.owl-slider-banner .owl-theme .owl-dots {
    margin-top: 0;
    position: absolute;
    right: 50px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 25px;
    color: rgba(255, 255, 255, .5)
}

.owl-slider-banner .owl-theme .owl-dots .owl-dot span {
    height: 9px;
    width: 9px;
    margin: 0;
    background-color: rgba(0, 0, 0, .2)
}

.owl-slider-banner .owl-theme .owl-dots .owl-dot {
    border: 1px solid;
    border-radius: 20px;
    display: inline-block;
    margin: 4px;
    padding: 4px
}

.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover {
    border: 1px solid #ff3b68
}

.owl-slider-banner .owl-item .slide-content-box {
    opacity: 0
}

.owl-slider-banner .owl-item.active .slide-content-box {
    opacity: 1;
    transition: all .5s ease .5s;
    -moz-transition: all .5s ease .5s;
    -webkit-transition: all .5s ease .5s;
    -o-transition: all .5s ease .5s;
    top: 50%
}

.shop-categories .acod-head a {
    border-width: 0 0 2px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 0;
    text-transform: uppercase;
    border-radius: 0
}

.shop-categories .acod-body {
    border-width: 0
}

.shop-categories .acod-content {
    margin: 15px 0 15px
}

.shop-categories .acod-head a.collapsed:after,
.shop-categories .acod-head a:after {
    font-family: open sans;
    font-weight: 700;
    color: #000;
    font-size: 18px;
    right: 5px
}

.shop-categories .acod-head a:after {
    content: "--"
}

.shop-categories .acod-head a.collapsed:after {
    content: "+"
}

.product-brand .search-content {
    display: block;
    margin-bottom: 0;
    padding: 10px 0
}

.product-brand .search-content .search-content-area {
    margin-bottom: 0
}

.price .amount {
    border: 0 none;
    color: #404040;
    font-weight: 700;
    margin-bottom: 15px;
    padding: 0;
    text-align: left;
    width: 100%
}

.price .ui-widget-content {
    margin: 0 8px
}

.container-fluid.wide {
    padding-left: 30px;
    padding-right: 30px
}

.shop-account .form-control {
    background: #efefef none repeat scroll 0 0;
    border: 0 none;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .05);
    font-size: 14px;
    height: 50px;
    padding: 0 20px
}

.shop-account .site-button.button-lg {
    font-size: 14px;
    font-weight: 700;
    padding: 14px 30px;
    text-transform: uppercase
}

.shop-account .border-1 {
    border-color: #dfdfdf
}

.shop-account .form-control:focus {
    box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, .05);
    background-color: #fff
}

.errow-style-1 .tparrows:before {
    font-size: 60px;
    opacity: .7
}

.errow-style-1 .tparrows:before:hover {
    opacity: 1
}

.errow-style-1 .tparrows,
.errow-style-1 .tparrows:hover {
    background: rgba(0, 0, 0, 0)
}

@media only screen and (max-width:1200px) {
    .owl-slider.style-1 .slide-content-box h2 {
        font-size: 28px;
        line-height: 32px
    }

    .owl-slider.style-1 .slide-content-box p {
        display: none
    }

    .owl-slider.style-1 .slide-content-box .button-lg {
        padding: 10px 20px
    }
}

@media only screen and (max-width:767px) {
    .owl-slider.style-1 .slide-content-box h2 {
        font-size: 18px;
        line-height: 22px
    }

    .owl-slider.style-1 .slide-content-box .button-lg {
        display: none
    }

    .owl-slider-banner .owl-theme .owl-dots {
        right: 15px
    }
}

.btn-play:after,
.btn-play:before {
    content: '';
    border: 1px solid;
    border-color: inherit;
    width: 150%;
    height: 150%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    left: -25%;
    top: -25%;
    opacity: 1;
    -webkit-animation: 1s videomodule-anim linear infinite;
    animation: 1s videomodule-anim linear infinite
}

.btn-play:before {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.btn-play .icon {
    color: #505cfd !important
}

.btn-play {
    position: relative
}

@media screen and (max-width:1200px) {
    .bg-md-hd {
        background-image: none !important
    }
}

@media screen and (max-width:768px) {
    .btn-play {
        margin-top: 0
    }

    .bg-xs-hd {
        background-image: none !important
    }
}

@-webkit-keyframes videomodule-anim {
    0% {
        -webkit-transform: scale(.68);
        transform: scale(.68)
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0
    }
}

@keyframes videomodule-anim {
    0% {
        -webkit-transform: scale(.68);
        transform: scale(.68)
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0
    }
}

.short-edit-box {
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, .08) inset;
    padding: 8px;
    margin-bottom: 15px
}

.short-edit-box h5 a {
    color: #fff;
    font-weight: 700
}

.animation-effects .wow-effects {
    display: table;
    margin: 0 auto 30px;
    padding: 0 15px;
    float: left;
    width: 50%
}

.animation-effects .wow-effects .wow {
    background: #f0f0f0 none repeat scroll 0 0;
    border-radius: 4px;
    color: #505050;
    display: table-cell;
    font-size: 18px;
    font-weight: 600;
    height: 200px;
    text-align: center;
    text-transform: capitalize;
    vertical-align: middle
}

@media screen and (max-width:767px) {
    .buy-now a {
        padding: 10px 10px;
        font-size: 12px
    }
}

.portfolio .icon-bx-xs {
    border-radius: 50%
}

.portfolio .icon-bx-xs {
    width: 46px;
    height: 46px;
    line-height: 46px
}

.portfolio-ic a,
.portfolio-mas a {
    color: #000
}

.portfolio-ic .dez-info a:hover,
.portfolio-mas .dez-info a:hover {
    color: #24c4dd
}

.portfolio-ic a:hover,
.portfolio-mas a:hover {
    color: #fff
}

.portfolio-ic a:hover i,
.portfolio-mas a:hover i {
    background: 0 0;
    border: 2px solid #fff
}

.portfolio-ic .overlay-icon a,
.portfolio-mas .overlay-icon a {
    margin: 0 7px
}

.portfolio .portfolio-metro .dlab-img-overlay1::before {
    width: 90%;
    height: 90%;
    opacity: 0;
    top: 5%;
    left: 5%
}

.portfolio .owl-next,
.portfolio .owl-next:hover,
.portfolio .owl-prev,
.portfolio .owl-prev:hover {
    background-color: #fff;
    color: #000;
    padding: 17px 17px
}

.port-style1 .overlay-icon {
    padding: 30px
}

.port-style1 .port-box {
    padding-left: 10px;
    border-left: 3px solid var(--color-primary)
}

.port-style1 .align-m {
    text-align: center
}

#rc-imageselect,
.g-recaptcha {
    transform: scale(.9);
    -webkit-transform: scale(.9);
    -moz-transform: scale(.9);
    -o-transform: scale(.9);
    transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0
}

@media screen and (max-width:1280px) {

    #rc-imageselect,
    .g-recaptcha {
        transform: scale(.7);
        -moz-transform: scale(.7);
        -o-transform: scale(.7);
        -webkit-transform: scale(.7);
        transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0
    }
}

@media screen and (max-width:575px) {

    #rc-imageselect,
    .g-recaptcha {
        transform: scale(.7);
        -moz-transform: scale(.7);
        -o-transform: scale(.7);
        -webkit-transform: scale(.7);
        transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0
    }
}

.recaptcha-box {
    height: 60px
}

@media screen and (max-width:991px) {
    .mo-left .header-nav.nav-dark {
        background-color: #202020
    }

    .mo-left .header-nav {
        overflow-y: scroll;
        position: fixed;
        width: 60px;
        left: -280px;
        height: 100vh !important;
        transition: all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -o-transition: all .5s;
        top: 0;
        background-color: #fff;
        margin: 0;
        z-index: 99
    }

    .mo-left .header-nav li.open a {
        position: relative
    }

    .header-nav {
        overflow-x: scroll;
        height: 100%
    }

    .mo-left .header-nav.show {
        left: -1px;
        transition: all .8s;
        -webkit-transition: all .8s;
        -moz-transition: all .8s;
        -o-transition: all .8s;
        margin: 0;
        width: 280px
    }

    .mo-left .navbar-toggler.open:after {
        background-color: rgba(0, 0, 0, .6);
        content: "";
        height: 100%;
        left: 0;
        position: fixed;
        right: -20px;
        top: -63px;
        transform: scale(100);
        -o-transform: scale(100);
        -moz-transform: scale(100);
        -webkit-transform: scale(100);
        width: 100%;
        z-index: -1;
        transition: all .5s;
        transform-origin: top right;
        margin: 0 0 0 20px;
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, .6);
        box-shadow: 0 0 0 1000px rgba(0, 0, 0, .6)
    }

    .mo-left .header-nav .logo-header {
        display: block;
        float: none;
        height: auto;
        max-width: 100%;
        padding: 30px 15px;
        width: 100%
    }

    .mo-left .header-nav .logo-header img {
        max-width: unset;
        width: 160px;
        vertical-align: middle
    }

    .mo-left .navbar-toggler.open span {
        background: #fff
    }
}

@media screen and (max-width:767px) {
    .mo-left .navbar-toggler.open:after {
        margin: 0 0 0 15px
    }
}

.fly-box,
.fly-box-ho,
.fly-box-ho:hover,
.fly-box:hover,
.hwp {
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s
}

.fly-box {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1)
}

.fly-box-ho:hover,
.fly-box:hover,
.icon-bx-wraper:hover .fly-box,
.icon-bx-wraper:hover .fly-box-ho {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
    -o-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
}

.hwp {
    background-color: #fff;
    color: var(--color-primary)
}

.hwp a {
    color: var(--color-primary)
}

.hwp:hover,
.icon-bx-wraper:hover .hwp {
    background-color: var(--color-primary);
    color: #fff
}

.hwp:hover a,
.icon-bx-wraper:hover .hwp a {
    color: #fff
}

.faa-horizontal.animated {
    -webkit-animation: horizontal 5s ease infinite;
    animation: horizontal 5s ease infinite
}

.inner-text .title {
    text-decoration: underline;
    font-weight: 500;
    margin-bottom: 10px
}

.inner-text p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 20px
}

.inner-text ul li {
    font-size: 18px;
    line-height: 32px
}

@keyframes horizontal {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    6% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0)
    }

    12% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    18% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0)
    }

    24% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    30% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0)
    }

    100%,
    36% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@media screen and (max-width:991px) {
    .header-nav .nav>li .mega-menu>li.special-nav {
        display: none
    }
}

.theme-btn {
    background-color: #fff;
    border-radius: 40px;
    bottom: 10px;
    color: #fff;
    display: table;
    height: 50px;
    left: 10px;
    min-width: 50px;
    position: fixed;
    text-align: center;
    z-index: 9999
}

.theme-btn i {
    font-size: 22px;
    line-height: 50px
}

.theme-btn.bt-support-now {
    background: #1ebbf0;
    background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    background: -webkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    bottom: 70px
}

.theme-btn.bt-buy-now {
    background: #1fdf61;
    background: -moz-linear-gradient(top, #a3d179 0, #88ba46 100%);
    background: -webkit-linear-gradient(top, #a3d179 0, #88ba46 100%);
    background: linear-gradient(to bottom, #a3d179 0, #88ba46 100%)
}

.theme-btn:hover {
    color: #fff;
    padding: 0 20px
}

.theme-btn span {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    letter-spacing: -15px;
    opacity: 0;
    line-height: 50px;
    transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    text-transform: uppercase
}

.theme-btn:hover span {
    opacity: 1;
    letter-spacing: 1px;
    padding-left: 10px
}

.at-expanding-share-button[data-position=bottom-left] {
    bottom: 130px !important
}

.service-box-2 {
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .05)
}

.service-box-2 .title {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 18px
}

.service-box-2 .dlab-info {
    padding: 20px
}

.service-box-2 .dlab-info p {
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #999
}

.our-story>span {
    color: var(--color-primary);
    font-size: 22px;
    display: block;
    margin-bottom: 10px
}

.our-story p {
    font-size: 16px;
    line-height: 32px;
    color: #000
}

.our-story h2.title {
    font-size: 50px;
    line-height: 70px;
    font-weight: 400;
    margin-bottom: 20px
}

.our-story h2.title span {
    font-style: italic
}

.our-story h4.title {
    font-size: 26px;
    line-height: 40px;
    font-weight: 500;
    color: #999
}

.section-head .title {
    margin-bottom: 5px;
    font-size: 35px;
    line-height: 45px;
    font-weight: 600
}

.project-bx {
    position: relative
}

.project-bx .dlab-info {
    position: absolute;
    bottom: 15px;
    background: #fff;
    width: calc(100% - 30px);
    left: 15px;
    padding: 15px;
    border-radius: 4px;
    z-index: 1
}

.project-bx .dlab-info .dlab-title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600
}

.about-carousel-ser {
    overflow: hidden
}

@media only screen and (max-width:1200px) {
    .counter-staus-box .section-head .title {
        font-size: 35px;
        line-height: 50px;
        font-weight: 500
    }

    .counter-staus-box .play-btn {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .contact-no-area .title br {
        display: none
    }

    .contact-no-area .title {
        line-height: 65px;
        margin-bottom: 20px;
        font-size: 50px
    }

    .dlab-team1 .dlab-title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 5px
    }

    .dlab-team1 .dlab-position {
        font-size: 12px
    }

    .counter-style-5 .counter {
        font-size: 45px;
        line-height: 55px
    }

    .counter-style-5 {
        padding: 30px 15px
    }

    .dlab-team1 .dlab-social-icon li a {
        width: 28px;
        height: 28px;
        font-size: 12px;
        line-height: 26px
    }

    .testimonial-1 .testimonial-text p {
        font-size: 20px;
        line-height: 35px
    }

    .blog-post.post-style-1 .post-title {
        line-height: 30px;
        font-size: 18px
    }

    .ft-contact .ft-contact-bx p {
        line-height: 24px;
        font-size: 13px
    }

    .ft-contact .ft-contact-bx .title {
        margin: 0 0 5px 0;
        font-size: 18px
    }
}

@media only screen and (max-width:991px) {
    .ft-contact .ft-contact-bx img {
        left: 20px;
        top: 25px;
        width: 40px;
        height: 40px
    }

    .ft-contact .ft-contact-bx p {
        line-height: 24px;
        font-size: 12px
    }

    .ft-contact .ft-contact-bx {
        padding: 15px 15px 15px 70px
    }

    .ft-contact .ft-contact-bx .title {
        margin: 0;
        font-size: 16px
    }

    .ft-contact .ft-contact-bx:after {
        right: -5px
    }

    .contact-img-bx img {
        position: unset;
        display: none
    }

    .service-box.style3 .dlab-tilte {
        margin-bottom: 10px
    }
}

@media only screen and (max-width:767px) {
    .ft-contact {
        flex-wrap: wrap
    }

    .ft-contact .ft-contact-bx {
        flex: 0 0 100%;
        max-width: 100%
    }

    .ft-contact .ft-contact-bx img {
        width: 40px;
        height: 40px;
        left: 20px;
        top: 30px
    }

    .ft-contact .ft-contact-bx:after {
        content: "";
        height: 30px;
        width: 100%;
        right: auto;
        top: auto;
        left: 0;
        background-size: 100% 100%;
        background-position: center;
        opacity: .5;
        bottom: -20px
    }

    .ft-contact .ft-contact-bx {
        padding: 25px 15px 25px 75px
    }

    .blog-post.blog-md.border-1 .dlab-post-info {
        padding: 0 20px 0
    }
}

@media only screen and (max-width:576px) {
    .service-media-bx .dlab-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 34px
    }

    .service-media-bx {
        padding: 15px
    }

    .contact-no-area .title {
        line-height: 45px;
        margin-bottom: 20px;
        font-size: 35px
    }

    .contact-no {
        flex-wrap: wrap
    }

    .contact-no .contact-left {
        border-right: 0
    }

    .contact-no .contact-left {
        padding-right: 0
    }

    .contact-no .no {
        font-size: 30px;
        margin-bottom: 20px
    }

    .contact-no .contact-right {
        padding-left: 0
    }

    .ft-contact {
        margin: 0 -15px
    }

    .counter-style-1 .counter,
    .counter-style-2 .counter,
    .counter-style-3 .counter,
    .counter-style-4 .counter {
        font-size: 36px
    }

    .counter-style-1 .counter-text,
    .counter-style-2 .counter-text,
    .counter-style-4 .counter-text {
        font-size: 14px
    }

    .counter-style-1 .icon {
        font-size: 36px
    }

    .counter-style-3.rounded {
        height: 150px
    }
}

.site-footer.style1 .footer-bottom-in .dlab-social-icon li a:hover {
    color: #fff
}

.dlab-team10 {
    position: relative;
    transition: all .5s;
    z-index: 1
}

.dlab-team10 .dlab-info {
    bottom: 0;
    position: absolute;
    background: #fff;
    width: 100%;
    display: block;
    padding: 20px 20px;
    transition: all .5s;
    opacity: 0
}

.dlab-team10 .dlab-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 5px
}

.dlab-team10 .dlab-social-icon li a {
    width: 34px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    font-size: 14px
}

.dlab-team10 span {
    display: block;
    color: #000;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    opacity: .5;
    margin-bottom: 10px
}

.dlab-team10 .dlab-media {
    transition: all .5s;
    top: 0
}

.dlab-team10:hover .dlab-media {
    top: -40px;
    transition: all .5s
}

.dlab-team10:hover {
    z-index: 2
}

.dlab-team10:hover .dlab-info {
    bottom: -40px;
    transition: all .5s;
    opacity: 1
}

.dlab-team10-area:hover .dlab-team10 {
    opacity: .2
}

.dlab-team10-area .dlab-team10:hover {
    opacity: 1
}

.dlab-team10:after {
    content: "";
    transition: all .5s;
    position: absolute;
    background: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.dlab-team10:hover:after {
    height: calc(100% + 80px);
    bottom: -40px;
    box-shadow: 0 0 40px 0 rgba(7, 41, 77, .2)
}

.content-bx1 {
    padding-left: 30px
}

footer ul.thsn-timelist-list {
    margin-top: -5px
}

footer ul.thsn-timelist-list li {
    border-bottom: none;
    padding: 8px 0;
    display: flex;
    justify-content: space-between
}

ul.thsn-timelist-list li {
    list-style: none;
    border-bottom: 1px solid #e2e4e8;
    padding: 15px 0
}

.thsn-timelist-li-value {
    color: var(--color-primary)
}

.widget_about .contact-info-bx li {
    padding-bottom: 10px;
    position: relative;
    padding-left: 35px;
    padding-top: 3px
}

.widget_about .contact-info-bx li i {
    width: auto;
    height: 22px;
    color: var(--color-primary);
    line-height: 26px;
    text-align: center;
    border-radius: 30px;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 22px
}

@media only screen and (max-width:991px) {
    .content-bx1 {
        padding-left: 0
    }
}

@media only screen and (min-width:991px) {
    .navstyle1 .header-nav .nav>li>a:after {
        content: "";
        right: 0;
        left: auto;
        height: 100%;
        z-index: -1
    }

    .navstyle2 .header-nav .nav>li>a:after {
        border-radius: 40px;
        background: 0 0;
        width: 100%;
        left: 0;
        z-index: -1;
        height: 100%
    }

    .navstyle1 .header-nav .nav>li,
    .navstyle2 .header-nav .nav>li {
        padding: 10px 0 0 0
    }

    .navstyle1 .header-nav .nav>li>a,
    .navstyle2 .header-nav .nav>li>a {
        padding: 12px 15px 12px 15px;
        border: 0;
        position: relative;
        z-index: 1;
        margin-bottom: 8px
    }

    .navstyle2 .header-nav .nav>li.active>a:after,
    .navstyle2 .header-nav .nav>li:hover>a:after {
        box-shadow: inset 0 0 0 2px var(--color-primary);
        left: 0;
        top: 0
    }

    .navstyle1 .header-nav .nav>li:hover>a {
        color: #fff
    }

    .navstyle1 .header-nav .nav>li:hover>a:after {
        right: auto;
        left: 0
    }

    .navstyle1 .header-nav .nav>li.active>a {
        background: var(--color-primary);
        color: #fff
    }

    .navstyle3 .header-nav .nav>li>a {
        z-index: 1;
        border: 0
    }

    .navstyle3 .header-nav .nav>li>a:after {
        z-index: -1
    }

    .navstyle3 .header-nav .nav>li.active>a:after,
    .navstyle3 .header-nav .nav>li:hover>a:after,
    .navstyle3 .header-nav .nav>li>a:after {
        height: 100%;
        color: #fff
    }

    .navstyle3 .header-nav .nav>li.active>a,
    .navstyle3 .header-nav .nav>li:hover>a {
        color: #fff
    }

    .navstyle3 .header-nav .nav>li .sub-menu,
    .navstyle3 .header-nav .nav>li>.mega-menu {
        background-color: var(--color-primary);
        border: 1px solid var(--color-primary)
    }

    .navstyle3 .header-nav .nav>li .mega-menu li:hover>a,
    .navstyle3 .header-nav .nav>li .mega-menu li>a,
    .navstyle3 .header-nav .nav>li .sub-menu li:hover>a,
    .navstyle3 .header-nav .nav>li .sub-menu li>a {
        color: #fff
    }

    .navstyle3 .header-nav .nav>li .sub-menu>li:hover>a,
    .navstyle3 .header-nav .nav>li .sub-menu>li>a:hover {
        background-color: rgba(0, 0, 0, .05)
    }

    .navstyle3 .header-nav .nav>li .mega-menu>li ul a:hover {
        background-color: rgba(0, 0, 0, .05)
    }

    .navstyle4 .header-nav .nav>li>a:after {
        width: 0;
        left: auto;
        bottom: 20px;
        z-index: -1;
        height: 16px;
        opacity: .2;
        right: 15px
    }

    .navstyle4 .header-nav .nav>li:hover>a:after {
        width: calc(100% - 30px);
        left: 15px;
        right: auto
    }

    .navstyle4 .header-nav .nav>li.active>a:after {
        width: calc(100% - 30px);
        left: 15px;
        bottom: 20px;
        z-index: -1;
        height: 16px;
        opacity: .2
    }

    .navstyle4 .header-nav .nav>li>a {
        border: 0;
        z-index: 1;
        padding: 24px 15px 24px 15px
    }

    .navstyle5 .header-nav .nav>li>a {
        border: 0;
        padding: 24px 8px 24px 8px
    }

    .navstyle5 .header-nav .nav>li.active>a:after,
    .navstyle5 .header-nav .nav>li.active>a:before,
    .navstyle5 .header-nav .nav>li>a:after,
    .navstyle5 .header-nav .nav>li>a:before {
        display: inline-block;
        opacity: 0;
        -webkit-transition: -webkit-transform .8s, opacity .2s;
        -moz-transition: -moz-transform .8s, opacity .2s;
        transition: transform .8s, opacity .2s;
        width: auto;
        height: auto
    }

    .navstyle5 .header-nav .nav>li.active>a:before,
    .navstyle5 .header-nav .nav>li>a:before {
        content: '[';
        -webkit-transform: translateX(20px);
        -moz-transform: translateX(20px);
        transform: translateX(20px)
    }

    .navstyle5 .header-nav .nav>li.active>a:after,
    .navstyle5 .header-nav .nav>li>a:after {
        content: ']';
        -webkit-transform: translateX(-20px);
        -moz-transform: translateX(-20px);
        transform: translateX(-20px);
        width: auto;
        height: auto;
        background: 0 0
    }

    .navstyle5 .header-nav .nav>li>a:after {
        position: relative
    }

    .navstyle5 .header-nav .nav>li.active>a:after,
    .navstyle5 .header-nav .nav>li:hover>a:after {
        opacity: 1;
        -webkit-transform: translateX(10px);
        -moz-transform: translateX(10px);
        transform: translateX(10px);
        width: auto;
        height: auto;
        position: relative
    }

    .navstyle5 .header-nav .nav>li.active>a:before,
    .navstyle5 .header-nav .nav>li:hover>a:before {
        opacity: 1;
        -webkit-transform: translateX(-10px);
        -moz-transform: translateX(-10px);
        transform: translateX(-10px)
    }

    .navstyle1.header.dark .header-nav .nav>li>a,
    .navstyle1.header.dark .main-bar .header-nav .nav>li>a,
    .navstyle1.header.header-transparent .header-nav .nav>li>a,
    .navstyle1.header.header-transparent .main-bar .header-nav .nav>li>a,
    .navstyle2.header.dark .header-nav .nav>li>a,
    .navstyle2.header.dark .main-bar .header-nav .nav>li>a,
    .navstyle2.header.header-transparent .header-nav .nav>li>a,
    .navstyle2.header.header-transparent .main-bar .header-nav .nav>li>a,
    .navstyle5.header.dark .header-nav .nav>li>a,
    .navstyle5.header.dark .main-bar .header-nav .nav>li>a,
    .navstyle5.header.header-transparent .header-nav .nav>li>a,
    .navstyle5.header.header-transparent .main-bar .header-nav .nav>li>a {
        border: 0
    }

    .navstyle1 .main-bar.bg-primary .header-nav .nav>li.active>a:after,
    .navstyle1 .main-bar.bg-primary .header-nav .nav>li>a:after {
        background: #fff
    }

    .navstyle1 .main-bar.bg-primary .header-nav .nav>li.active>a,
    .navstyle1 .main-bar.bg-primary .header-nav .nav>li:hover>a {
        color: var(--color-primary)
    }

    .navstyle2 .main-bar.bg-primary .header-nav .nav>li.active>a:after {
        background: 0 0;
        box-shadow: inset 0 0 0 2px #fff
    }

    .navstyle2 .main-bar.bg-primary .header-nav .nav>li>a:after,
    .navstyle5 .main-bar.bg-primary .header-nav .nav>li.active>a:after,
    .navstyle5 .main-bar.bg-primary .header-nav .nav>li>a:after {
        background: 0 0
    }

    .navstyle2 .main-bar.bg-primary .header-nav .nav>li:hover>a:after {
        box-shadow: inset 0 0 0 2px #fff
    }

    .navstyle3 .main-bar.bg-primary .header-nav .nav>li.active>a:after,
    .navstyle3 .main-bar.bg-primary .header-nav .nav>li>a:after {
        background: #000;
        opacity: .1
    }
}

.blog-post.blog-single,
.blog-post.blog-single+#comment-list,
.dlab-page-text,
.dlab-page-text+#comment-list,
.dlab-page-text+#comment-list+.paginate-links,
.min-container {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px
}

.blog-post.blog-single.sidebar+#comment-list {
    padding: 0;
    max-width: 100%
}

.max-container {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 55px;
    padding-right: 55px
}

.dlab-page-text,
.dlab-page-text+#comment-list,
.dlab-page-text+#comment-list+.paginate-links {
    padding: 0
}

.dlab-page-text.sidebar {
    width: 100%;
    padding: 0
}

.blog-post.blog-single .dlab-info {
    padding: 0
}

.blog-post.blog-single .dlab-post-text>.wp-block-gallery,
.blog-post.blog-single .dlab-post-text>ol,
.blog-post.blog-single .dlab-post-text>ul,
.dlab-page-text>.wp-block-gallery,
.dlab-page-text>ol,
.dlab-page-text>ul {
    list-style: none;
    margin-bottom: 30px
}

.blog-post.blog-single .dlab-post-text.text>ol,
.blog-post.blog-single .dlab-post-text.text>ul,
.dlab-page-text>ol,
.dlab-page-text>ul {
    padding-left: 20px
}

.blog-post.blog-single .dlab-post-text.text>ol li,
.blog-post.blog-single .dlab-post-text.text>ul li,
.blog-post.blog-single .dlab-post-text>ol li,
.blog-post.blog-single .dlab-post-text>ul li,
.dlab-page-text>ol li,
.dlab-page-text>ul li {
    padding: 8px 8px;
    position: relative
}

.blog-post.blog-single .dlab-post-text.text>.wp-block-gallery,
.dlab-page-text>.wp-block-gallery {
    padding: 0;
    list-style: none
}

.blog-post.blog-single .dlab-post-text.text>.wp-block-gallery li,
.dlab-page-text>.wp-block-gallery li {
    padding: 0;
    list-style: none;
    margin: 4px
}

.blog-post.blog-single .dlab-post-text.text>.wp-block-gallery li:before,
.dlab-page-text>.wp-block-gallery li:before {
    content: none
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    margin: 0 8px 8px 0
}

.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item,
.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 8px
}

.content-spacious-full .wp-block-gallery {
    margin-left: calc(-200px - (5px/2));
    margin-right: calc(-200px - (5px/2));
    width: calc(100% + 400px + 5px);
    max-width: initial
}

.alignfull,
.alignwide {
    margin-left: calc(-100px - (5px/2));
    margin-right: calc(-100px - (5px/2));
    width: calc(100% + 200px + 5px);
    max-width: initial
}

.alignfull,
.alignfullwide {
    margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px)/ 2));
    width: 100vw;
    max-width: 100vw;
    box-sizing: border-box
}

.dlab-page-text h1,
.dlab-post-text h1,
.thm-unit-test .dlab-post-text h1 {
    margin-bottom: 25px;
    font-weight: 700
}

.dlab-page-text h2,
.dlab-post-text h2,
.thm-unit-test .dlab-post-text h2 {
    margin-bottom: 25px;
    font-weight: 700
}

.dlab-page-text h3,
.dlab-post-text h3,
.thm-unit-test .dlab-post-text h3 {
    margin-bottom: 20px;
    font-weight: 700
}

.dlab-page-text h4,
.dlab-post-text h4,
.thm-unit-test .dlab-post-text h4 {
    margin-bottom: 15px;
    font-weight: 600
}

.dlab-page-text h5,
.dlab-post-text h5,
.thm-unit-test .dlab-post-text h5 {
    margin-bottom: 15px;
    font-weight: 600
}

.dlab-page-text h6,
.dlab-post-text h6,
.thm-unit-test .dlab-post-text h6 {
    margin-bottom: 10px;
    font-weight: 600
}

.wp-block-code,
pre,
pre.wp-block-code {
    margin: 0 0 25px;
    padding: 20px;
    color: #fff;
    background-color: #212326;
    white-space: pre;
    font-size: 15px;
    border-radius: 3px
}

.blog-post.blog-single .blog-post.blog-single .dlab-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right),
.blog-post.blog-single .dlab-post-text,
.blog-post.blog-single .dlab-post-text ol li,
.blog-post.blog-single .dlab-post-text ul li,
.dlab-page-text,
.dlab-page-text ol li,
.dlab-page-text ul li {
    color: #6f6f6f;
    font-size: 18px;
    line-height: 1.7
}

.wp-block-cover,
.wp-block-cover-image {
    color: #fff
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
    margin-bottom: 0
}

.wp-block-quote.has-text-align-right {
    border-right: 0
}

.blog-post.blog-single .dlab-post-text ol li,
.blog-post.blog-single .dlab-post-text ul li,
.dlab-page-text ol li,
.dlab-page-text ul li {
    position: relative
}

.blog-post.blog-single .dlab-post-text ol>li:before,
.blog-post.blog-single .dlab-post-text ul>li:before,
.dlab-page-text ol>li:before,
.dlab-page-text ul>li:before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background: var(--color-primary);
    left: -15px;
    position: absolute;
    top: 20px
}

.blog-post.blog-single .dlab-post-text ol>li li:before,
.blog-post.blog-single .dlab-post-text ul>li li:before,
.dlab-page-text ol>li li:before,
.dlab-page-text ul>li li:before {
    content: none
}

.blog-post.blog-single .dlab-post-text a,
.dlab-page-text a {
    box-shadow: inset 0 -1px 0 0 var(--bg-color)
}

.blog-post.blog-single .dlab-post-text p,
.dlab-page-text p {
    margin-bottom: 1.65em
}

.blog-post.blog-single .dlab-post-text .wp-block-gallery,
.dlab-page-text .wp-block-gallery {
    margin-left: calc(-190px - (10px/2));
    margin-right: calc(-190px - (10px/2));
    width: calc(100% + 380px + 10px);
    max-width: initial
}

.paginate-links {
    display: flex;
    align-items: center;
    margin: 20px 0
}

.paginate-links a,
.paginate-links>span {
    margin: 0 0 0 10px;
    position: relative;
    border: 0 solid #6cc000;
    color: #000;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    text-transform: capitalize;
    transition: all .5s ease 0s;
    background: #e0e0e0;
    border-radius: 3px
}

.wp-block-columns {
    margin-bottom: 0
}

.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 6px 6px 0;
    position: relative
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    height: 100%;
    margin: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 60%, transparent);
    bottom: 0;
    color: #fff;
    font-size: 13px;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 5px;
    position: absolute;
    text-align: center;
    width: 100%
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
    border-radius: 10px
}

@supports ((position:-webkit-sticky) or (position:sticky)) {

    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        -o-object-fit: cover;
        flex: 1;
        height: 100%;
        object-fit: cover
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 8px)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    margin-right: 0;
    width: 100%
}

@media only screen and (max-width:1024px) {
    .alignwide {
        margin-left: 0;
        margin-right: 0;
        width: 100% !important;
        max-width: 100% !important
    }

    .alignwide .alignleft img {
        width: 100%
    }

    .alignwide .alignleft {
        margin: 5px 0 25px 0;
        float: none
    }
}

@media (min-width:600px) {

    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        margin-right: 6px;
        width: calc(33.33333% - 10.66667px)
    }

    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        margin-right: 6px;
        width: calc(25% - 12px)
    }

    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        margin-right: 6px;
        width: calc(20% - 12.8px)
    }

    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        margin-right: 6px;
        width: calc(16.66667% - 13.33333px)
    }

    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        margin-right: 6px;
        width: calc(14.28571% - 13.71429px)
    }

    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        margin-right: 6px;
        width: calc(12.5% - 14px)
    }

    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%
}

.wp-block-image.alignfullwide img {
    border-radius: 0
}

.wp-block-image img {
    border-radius: 10px
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
    margin-bottom: 30px
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex
}

.aligncenter {
    text-align: center
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center
}

.blog-single.blog-post.sidebar .alignfullwide,
.blog-single.blog-post.sidebar .alignwide,
.blog-single.blog-post.sidebar .dlab-post-text .wp-block-gallery,
.dlab-page-text.sidebar .alignfull,
.dlab-page-text.sidebar .alignfullwide,
.dlab-page-text.sidebar .alignwide,
.dlab-page-text.sidebar .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: initial
}

.blog-overlap {
    background: #fff;
    margin-top: -140px;
    padding: 20px 20px 0;
    border-radius: 4px 4px 0 0
}

.blog-single.blog-post.sidebar {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
}

.container .min-container {
    padding-left: 0;
    padding-right: 0
}

.dlab-page-text table,
.dlab-post-text table,
.wp-block-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    font-size: 16px;
    margin-bottom: 30px
}

.dlab-page-text figcaption,
.dlab-post-text figcaption,
.wp-block-image figcaption {
    font-size: 14px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    color: #777;
    margin-top: 10px
}

.dlab-page-text table tbody tr:nth-of-type(odd),
.dlab-post-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
    background-color: #fafafa
}

.dlab-page-text td,
.dlab-page-text th,
.dlab-post-text td,
.dlab-post-text th,
.wp-block-table td,
.wp-block-table th {
    padding: 10px 15px;
    border: 1px solid #e4e4e4;
    border-right: 0;
    border-left: 0
}

.wp-block-media-text .wp-block-media-text__content {
    padding: 0 30px
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
    content: none !important
}

.wp-block-pullquote {
    padding: 1em 0;
    border-top: 0;
    border-bottom: 0
}

.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
    padding: 0;
    border-top: 0;
    border-bottom: 0
}

.wp-block-pullquote.alignleft blockquote,
.wp-block-pullquote.alignright blockquote {
    margin: 0
}

.dlab-page-text .wp-block-cover-image-text,
.dlab-page-text .wp-block-cover-text,
.dlab-page-text section.wp-block-cover-image h2,
.dlab-post-text .wp-block-cover-image-text,
.dlab-post-text .wp-block-cover-text,
.dlab-post-text section.wp-block-cover-image h2 {
    color: #fff
}

.admin-bar .is-fixed .main-bar {
    top: 30px
}

.wp-block-quote.is-style-large cite:before {
    display: none
}

.post-password-form label {
    font-size: 16px
}

.post-password-form input[type=submit] {
    padding: 10px 25px;
    background-color: var(--bg-color);
    border: none;
    height: 45px;
    font-weight: 600;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    outline: 0
}

.post-password-form input[type=submit]:hover {
    background-color: var(--bg-color-ho)
}

.post-password-form input[type=password] {
    height: 45px;
    border: 1px solid #ced4da
}

.dlab-page-text .bootstrap-select .dropdown-menu li a,
.dlab-post-text .bootstrap-select .dropdown-menu li a {
    box-shadow: none;
    font-size: 14px;
    font-family: Poppins, sans-serif
}

.dlab-post-text .wp-block-calendar a {
    box-shadow: none !important;
    text-decoration: unset
}

.wp-block-archives,
.wp-block-calendar,
.wp-block-categories,
.wp-block-latest-comments,
.wp-block-latest-posts,
.wp-block-search {
    margin-bottom: 40px
}

.wp-block-search .wp-block-search__button {
    background: var(--bg-color);
    border: 0;
    color: #fff;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 25px;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s
}

.wp-block-search .wp-block-search__button:hover {
    background: var(--bg-color-ho)
}

.wp-block-search .wp-block-search__input {
    border: 1px solid #e1e6eb;
    height: 45px;
    padding: 10px 20px;
    font-size: 14px;
    outline: 0
}

.wp-block-tag-cloud a {
    padding: 8px 10px;
    background-color: #5608e0;
    font-size: 12px;
    display: inline-block;
    margin: 0 0 5px;
    color: #fff;
    font-weight: 600;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    font-family: Poppins, sans-serif
}

.wp-block-tag-cloud a:hover {
    background: var(--bg-color-ho)
}

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
    width: 55px;
    height: 55px;
    border-radius: 55px
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt,
.wp-block-latest-comments img+article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 70px
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 10px
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
    color: #000;
    box-shadow: none
}

.wp-block-latest-comments {
    padding: 0 !important
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
    display: block;
    width: 100%;
    color: #9fa1a4;
    font-size: 13px;
    font-weight: 400
}

.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
    font-family: Poppins, sans-serif !important;
    font-size: 16px !important;
    color: #646566 !important;
    line-height: 1.5 !important;
    margin-bottom: 10px !important
}

.wp-block-latest-comments li:before {
    content: none !important
}

.wp-block-latest-comments li {
    padding: 0 !important;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px !important
}

.wp-block-rss {
    padding: 0 !important
}

.wp-block-rss .wp-block-rss__item {
    padding: 5px 0 !important;
    border-bottom: 1px solid #eee
}

.wp-block-rss .wp-block-rss__item:before {
    content: none !important
}

.wp-block-rss .wp-block-rss__item a {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    box-shadow: unset !important;
    font-weight: 600;
    color: #060429
}

.post-footer .dlab-meta .tag-list {
    padding-bottom: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: capitalize;
    display: inline
}

.post-footer .dlab-meta ul li {
    margin-right: 5px;
    padding: 0;
    display: inline-block;
    color: #333;
    font-weight: 500;
    font-size: 15px;
    font-style: italic
}

.post-footer .post-tag a {
    text-transform: capitalize;
    font-size: 15px;
    color: #999;
    background: 0 0;
    padding: 0;
    border: 0;
    line-height: 14px;
    font-weight: 500
}

.courses-bx {
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
    margin-bottom: 30px
}

.courses-bx .dlab-info {
    padding: 20px 15px
}

.courses-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px -15px -20px !important;
    border-top: 1px solid #eee;
    padding: 15px 15px
}

.courses-bx p {
    font-size: 15px;
    margin-bottom: 10px
}

.courses-info .price {
    background: #ffb606;
    font-size: 13px;
    font-weight: 700;
    color: #000;
    padding: 2px 5px;
    border-radius: 4px
}

.courses-info ul {
    margin: 0;
    list-style: none;
    padding: 0
}

.courses-info ul li {
    font-size: 14px
}

.courses-info ul li i {
    color: var(--color-primary);
    margin-right: 2px
}

.user-info {
    padding: 10px 10px 10px 65px;
    position: absolute;
    bottom: -100px;
    background: rgba(0, 0, 0, .7);
    width: 100%;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s
}

.courses-bx:hover .user-info {
    bottom: 0
}

.user-info img {
    width: 40px;
    border-radius: 40px;
    position: absolute;
    left: 15px;
    top: 10px
}

.user-info .title {
    color: #fff;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 1.3
}

.user-info .review {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #fff;
    font-weight: 500
}

.user-info .item-review {
    margin-right: 10px;
    margin-left: 0
}

.user-info .item-review li {
    font-size: 12px
}

.subscribe-box {
    padding: 30px 20px;
    background: #fff;
    margin: 0 !important;
    border-radius: 4px
}

.subscribe-box .form-control {
    border: 0;
    background: #eee;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 20px;
    height: 45px
}

.subscribe-box .site-button {
    height: 45px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500
}

.post-style-1 {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .05)
}

.counter-style-5 {
    border-right: 1px solid rgba(255, 255, 255, .3)
}

.counter-style-5:last-child {
    border: 0
}

.counter-style-5 .dlab-tilte {
    font-size: 60px;
    line-height: 1.1;
    font-weight: 900;
    margin-bottom: 10px;
    color: #fff
}

.counter-style-5 .icon-xl {
    margin-top: 10px
}

.counter-style-5 p {
    font-size: 16px
}

.sprite-nav .owl-next,
.sprite-nav .owl-prev {
    width: 50px;
    height: 50px;
    background-image: url(../images/icon/arrow.png);
    background-size: cover;
    transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    -o-transition: all .5s;
    background-color: transparent
}

.sprite-nav .owl-next {
    background-position: -105px 0
}

.sprite-nav .owl-prev {
    background-position: -53px 0
}

.sprite-nav .owl-prev:hover {
    background-position: 0 0
}

.sprite-nav .owl-next:hover {
    background-position: right
}

.blog-carousel.sprite-nav .owl-next,
.blog-carousel.sprite-nav .owl-prev {
    margin: 0 -80px !important
}

.sprite-nav .owl-next i,
.sprite-nav .owl-prev i {
    display: none
}

@media only screen and (max-width:1200px) {

    .blog-carousel.sprite-nav .owl-next,
    .blog-carousel.sprite-nav .owl-prev {
        display: none
    }

    .blog-post.blog-single .dlab-post-text .wp-block-gallery,
    .dlab-page-text .wp-block-gallery {
        margin-left: calc(-100px - (10px/2));
        margin-right: calc(-100px - (10px/2));
        width: calc(100% + 200px + 10px)
    }
}

@media only screen and (max-width:991px) {
    .admin-bar .mo-left .header-nav {
        top: 30px;
        height: calc(100vh - 30px) !important
    }

    .blog-post.blog-single .dlab-post-text .wp-block-gallery,
    .dlab-page-text .wp-block-gallery {
        margin-left: 0;
        margin-right: 0;
        width: 100%
    }

    .blog-post.blog-single .dlab-post-text,
    .blog-post.blog-single .dlab-post-text ol li,
    .blog-post.blog-single .dlab-post-text p,
    .blog-post.blog-single .dlab-post-text ul li,
    .dlab-page-text,
    .dlab-page-text ol li,
    .dlab-page-text p,
    .dlab-page-text ul li {
        font-size: 16px
    }
}

@media only screen and (max-width:782px) {
    .admin-bar .is-fixed .main-bar {
        top: 45px
    }

    .admin-bar .mo-left .header-nav {
        top: 45px;
        height: calc(100vh - 45px) !important
    }

    .wp-block-media-text {
        display: block
    }

    .wp-block-media-text .wp-block-media-text__media {
        margin-bottom: 15px
    }

    .wp-block-media-text .wp-block-media-text__content {
        padding: 0
    }
}

@media only screen and (max-width:600px) {
    .admin-bar .is-fixed .main-bar {
        top: 0
    }

    .admin-bar .mo-left .header-nav {
        top: 45px;
        height: calc(100vh - 45px) !important
    }

    .admin-bar .mo-left .is-fixed .header-nav {
        top: 0;
        height: 100vh !important
    }
}